import React, { useState, useEffect, useCallback, useRef } from "react";
import { Row, Col, Card, Button, Form, Input, Radio, RadioChangeEvent, Divider, Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useAuthState } from "../../provider/AuthProvider";
import { useAsyncAxios, utilAxiosWithAuth } from "../../utils/customAxios";
import AddressMaker, { AddressType } from "../../component/AddressMaker";
import { BrowserView, MobileView } from "react-device-detect";

import * as TypeDTO from "../../commons/typeDTO";
import * as Request from "../../commons/request";
import * as String from "../../commons/string";
import * as Common from "../../commons/common";

import PhoneCertification from "../../component/PhoneCertification";
import DriverLicense from "../rent/DriverLicense";
import InputEmail from "../../component/InputEmail";

type Mode = "view" | "update" | "register";
interface ChildProps {
    mode?: Mode;
    driverLicenseId?: number;
    onSuccess?: () => void;
    onFailed?: () => void;
    onRequest?: () => void;
}

function UpdateMember() {
    const location = useLocation();
    const navigate = useNavigate();
    const userDetails = useAuthState();
    const alert = useAlert();
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const driverLicenseRef = useRef<ChildProps>(null);

    const [userInfo, setUserInfo] = useState<TypeDTO.UserDto>();
    const [member, setMember] = useState("INDIVIDUAL");
    const [userName, setUserName] = useState("");
    // const [certificationResult, setCertificationResult] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [userAddress, setUserAddress] = useState<AddressType>();
    const [disableInputName, setDisableInputName] = useState(false);
    const [values, setValues] = useState<any>();
    const [email, setEmail] = useState("");

    const [promotionCode, setPromotionCode] = useState<string>();
    const [promotionCodes, setPromotionCodes] = useState<Array<TypeDTO.B2bDto>>([]);
    const [duplicationCode, setDuplicationCode] = useState<boolean>(false);
    const [inValidCode, setInvalidCode] = useState<boolean>(false);

    useEffect(() => {
        const state = location.state as TypeDTO.UserDto;
        state ? setUserInfo(state) : requestGetMember();
    }, [location.state]);

    // useEffect(() => {
    //     form.getFieldValue("phone") && form.validateFields(["phone"]);
    // }, [certificationResult]);

    useEffect(() => {
        if (email.includes(" ")) {
            const newEmail = email.replace(/\s/g, "");
            form.setFieldsValue({
                email: newEmail,
            });
            setEmail(newEmail);
        }
    }, [email]);

    const validateAddress = useCallback((_: any, value: { address: string; addressDetails: string }) => {
        if (!value || !value.address || !value.addressDetails) {
            return Promise.reject(new Error("주소를 입력해 주세요."));
        }
        return Promise.resolve();
    }, []);

    const validateCompanyRegistrationNo = useCallback((_: any, value: string) => {
        const valueMap = value
            .replace(/-/gi, "")
            .split("")
            .map((item) => {
                return parseInt(item, 10);
            });

        if (valueMap.length !== 10) return Promise.reject(new Error(String.msg_company_registration_no_rule));

        const multiply = new Array<number>(1, 3, 7, 1, 3, 7, 1, 3, 5);
        let checksum = 0;

        for (let i = 0; i < multiply.length; ++i) {
            checksum += multiply[i] * valueMap[i];
        }
        checksum += Math.floor((multiply[8] * valueMap[8]) / 10);

        if (Math.floor(valueMap[9]) === (10 - (checksum % 10)) % 10) return Promise.resolve();
        else return Promise.reject(new Error(String.msg_company_registration_no_rule));
    }, []);

    //get member
    const requestAxiosGetMember = async () => {
        if (!userDetails) return null;
        if (!userDetails?.token) return null;

        const response = await utilAxiosWithAuth().get(Request.MEMBER_INFO_URL);
        return response.data;
    };

    const {
        loading: loadingMembereInfo,
        error: errorGetMember,
        data: resultGetMember,
        execute: requestGetMember,
    } = useAsyncAxios(requestAxiosGetMember);

    useEffect(() => {
        if (!resultGetMember) return;

        console.log("resultGetMember", resultGetMember);
        setUserInfo(resultGetMember.user);
    }, [resultGetMember]);

    useEffect(() => {
        if (!errorGetMember) return;

        console.log("errorGetMember", errorGetMember);
    }, [errorGetMember]);

    useEffect(() => {
        if (!userInfo) return;

        userInfo?.userType === "INDIVIDUAL" ? setMember("INDIVIDUAL") : setMember("CORPORATE");

        setUserName(userInfo.name);
        setPhoneNumber(userInfo.phone);
        setUserAddress({
            address: userInfo.address,
            addressDetails: userInfo.addressDetails,
        });

        form.setFieldsValue({
            fullAddress: {
                address: userInfo.address,
                addressDetails: userInfo.addressDetails,
            },
        });

        form.setFieldsValue({ userType: "CORPORATE" });
        form.setFieldsValue(userInfo);
        form2.setFieldValue(
            "promotionCodes",
            userInfo.b2bs.map((code) => {
                return code.b2bName + "(" + code.promotionCode + ")";
            })
        );
        setPromotionCodes(userInfo.b2bs ? userInfo.b2bs : []);
    }, [userInfo]);

    //update member
    const requestAxiosUpdateMember = async (data: TypeDTO.SignupDto) => {
        const response = await utilAxiosWithAuth().put(Request.MEMBER_INFO_URL, data);
        return response.data;
    };

    const {
        loading: loadingUpdateMember,
        error: errorUpdateMember,
        data: resultUpdateMember,
        execute: requestUpdateMember,
    } = useAsyncAxios(requestAxiosUpdateMember);

    useEffect(() => {
        if (!resultUpdateMember) return;
        navigate(Common.PAGE_MYPAGE, {
            replace: true,
            state: { updated: true },
        });

        alert.setAlert(AlertType.SUCCESS, "회원 정보 수정 성공", "회원 정보 수정을 완료하였습니다.");
    }, [resultUpdateMember]);

    useEffect(() => {
        if (!errorUpdateMember) return;

        console.log("errorUser", errorUpdateMember);
        alert.setAlert(errorUpdateMember.response?.data?.code, "회원정보 수정 실패", errorUpdateMember.response?.data?.message);
    }, [errorUpdateMember]);

    const onChangedMemberType = (e: RadioChangeEvent) => {
        setMember(e.target.value);
    };

    const checkIndividualType = () => {
        return member === "INDIVIDUAL";
    };

    // const handleCertificationResult = (success: boolean, phoneNumber: string) => {
    //     setPhoneNumber(phoneNumber);
    //     setCertificationResult(success);

    //     setDisableInputName(success);
    // };

    const onFinish = (values: any) => {
        setValues({ ...values, promotionCodes: promotionCodes.map((code) => code.promotionCode) });
        driverLicenseRef.current?.onRequest?.();
    };

    const onSuccess = () => {
        if (values) {
            delete values["confirm"];
            delete values["fullAddress"];
            const data: TypeDTO.SignupDto = {
                companyName: "",
                ...values,
                phone: phoneNumber,
                address: userAddress?.address,
                addressDetails: userAddress?.addressDetails,
            };
            requestUpdateMember(data);
        }
    };

    const onInputName = (event: React.FormEvent<HTMLInputElement>) => {
        if ((event.nativeEvent as InputEvent).inputType === "deleteContentBackward") return;
        (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value.replace(/\s/g, "");
    };

    const onInputCompanyRegistrationNo = (event: React.FormEvent<HTMLInputElement>) => {
        if ((event.nativeEvent as InputEvent).inputType === "deleteContentBackward") return;
        (event.target as HTMLInputElement).value = (event.target as HTMLInputElement).value
            .replace(/[^0-9]/g, "")
            .replace(/^(\d{3})(\d{2})(\d{5})$/, "$1-$2-$3")
            .replace(/^(\d{3})(\d{2})(\d+)$/, "$1-$2-$3")
            .replace(/^(\d{3})(\d{2})$/, "$1-$2-")
            .replace(/^(\d{3})(\d+)$/, "$1-$2")
            .replace(/^(\d{3})$/, "$1-");
    };

    //check promotion code
    const requestAxiosCheckPromotionCode = async (code: string) => {
        const response = await utilAxiosWithAuth().post(Request.RENT_B2B_URL + code + "/check");
        return response.data;
    };

    const {
        loading: loadingCheckPromotionCode,
        error: errorCheckPromotionCode,
        data: resultCheckPromotionCode,
        execute: requestCheckPromotionCode,
    } = useAsyncAxios(requestAxiosCheckPromotionCode, undefined, true, undefined);

    useEffect(() => {
        if (!resultCheckPromotionCode) return;
        setPromotionCodes([...promotionCodes, resultCheckPromotionCode.b2b]);
        setPromotionCode(undefined);
    }, [resultCheckPromotionCode]);

    useEffect(() => {
        if (!errorCheckPromotionCode) return;
        console.log("errorCheckPromotionCode", errorCheckPromotionCode);
        setInvalidCode(true);
    }, [errorCheckPromotionCode]);

    const handleAddCode = () => {
        if (!promotionCode) return;
        if (promotionCodes.find((code) => code.promotionCode === promotionCode)) {
            return setDuplicationCode(true);
        }
        requestCheckPromotionCode(promotionCode);
    };

    return (
        <>
            <MobileView>
                <Row className="login py-4" align="middle" justify="center">
                    <div className="loginCard">
                        <Card bordered={false}>
                            <div className="text-center mb-4">
                                <h3>회원 정보 수정</h3>
                            </div>
                            <Form form={form} onFinish={onFinish} layout="vertical" scrollToFirstError={{ block: "center", scrollMode: "always" }}>
                                <Form.Item label="회원 종류" name="userType" initialValue={member}>
                                    <Radio.Group disabled={true} onChange={onChangedMemberType}>
                                        <Radio value="INDIVIDUAL">개인 회원</Radio>
                                        <Radio value="CORPORATE">법인 회원</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    name="name"
                                    label={checkIndividualType() ? "이름" : "이름(대표자)"}
                                    rules={[
                                        {
                                            required: true,
                                            message: "이름을 입력해 주세요.",
                                        },
                                    ]}
                                >
                                    <Input
                                        className="login-input"
                                        placeholder="휴대전화 명의자 이름을 입력해 주세요."
                                        disabled={disableInputName}
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                        onInput={onInputName}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="phone"
                                    label="휴대전화 번호"
                                    required={true}
                                    // rules={[
                                    //     () => ({
                                    //         validator(_, value) {
                                    //             if (certificationResult) {
                                    //                 return Promise.resolve();
                                    //             }

                                    //             return Promise.reject(new Error("휴대전화 본인 인증을 해 주세요."));
                                    //         },
                                    //     }),
                                    // ]}
                                >
                                    <PhoneCertification value={phoneNumber} userName={userName} />
                                </Form.Item>
                                {checkIndividualType() === false ? (
                                    <Form.Item name="companyName" label="회사명">
                                        <Input className="login-input" />
                                    </Form.Item>
                                ) : null}
                                {checkIndividualType() === false ? (
                                    <Form.Item
                                        name="companyRegistrationNo"
                                        label="사업자 등록번호"
                                        rules={[
                                            {
                                                required: true,
                                                message: String.msg_company_registration_no_must,
                                            },

                                            { max: 10, message: "최대 10자까지만 입력할 수 있습니다." },
                                            {
                                                validator: validateCompanyRegistrationNo,
                                            },
                                        ]}
                                    >
                                        <Input
                                            className="login-input"
                                            placeholder="사업자 등록번호(- 미포함)"
                                            onInput={onInputCompanyRegistrationNo}
                                        />
                                    </Form.Item>
                                ) : null}
                                <Form.Item
                                    name="email"
                                    label="이메일"
                                    rules={[
                                        {
                                            type: "email",
                                            message: "이메일이 유효하지 않습니다.",
                                        },
                                        {
                                            required: true,
                                            message: "이메일을 입력해 주세요.",
                                        },
                                    ]}
                                >
                                    <InputEmail value={email} onChange={(value) => setEmail(value)} />
                                </Form.Item>
                                <Form.Item
                                    className="login-group"
                                    name="fullAddress"
                                    label="주소"
                                    required={true}
                                    rules={[{ validator: validateAddress }]}
                                >
                                    <AddressMaker value={userAddress} onChange={(address) => setUserAddress(address)} />
                                </Form.Item>
                            </Form>
                            <Divider
                                style={{ position: "relative", border: "5px solid #e8ebef", margin: "1.5rem 0", width: "100vw", left: "-1.5rem" }}
                            />
                            <h6>추가 정보</h6>
                            {userInfo?.driverLicense ? (
                                <DriverLicense
                                    ref={driverLicenseRef}
                                    mode="update"
                                    driverLicenseId={userInfo?.driverLicense?.driverLicenseId}
                                    onSuccess={onSuccess}
                                />
                            ) : (
                                <DriverLicense ref={driverLicenseRef} mode="register" onSuccess={onSuccess} />
                            )}

                            <Form form={form2} layout="vertical">
                                <Form.Item
                                    className="login-group"
                                    style={{ marginBottom: promotionCodes.length > 0 ? 0 : "1rem" }}
                                    name="promotionCodes"
                                    label="단체 회원 코드"
                                >
                                    <Input.Group className={duplicationCode || inValidCode ? "mb-0" : "mb-2"} compact>
                                        <Input
                                            className="login-input mb-0"
                                            placeholder="단체 회원 코드"
                                            value={promotionCode}
                                            onChange={(e) => {
                                                setDuplicationCode(false);
                                                setInvalidCode(false);
                                                setPromotionCode(e.target.value);
                                            }}
                                        />
                                        <Button onClick={handleAddCode}>추가</Button>
                                        {duplicationCode && <span style={{ color: "#ff4d4f" }}>이미 추가된 코드입니다.</span>}
                                        {inValidCode && <span style={{ color: "#ff4d4f" }}>유효하지 않은 코드입니다.</span>}
                                    </Input.Group>
                                </Form.Item>
                                {promotionCodes.map((code, index) => {
                                    return (
                                        <Input.Group key={index} compact>
                                            <Input
                                                className={`login-input ${index !== promotionCodes.length - 1 ? "mb-2" : "mb-3"}`}
                                                value={code.b2bName + "(" + code.promotionCode + ")"}
                                            />
                                            <Button
                                                onClick={() => {
                                                    Modal.confirm({
                                                        title: "단체 회원 코드 삭제",
                                                        content: "단체 회원 코드를 삭제하시겠습니까?",
                                                        cancelText: String.cancel,
                                                        okText: "삭제",
                                                        onOk() {
                                                            setPromotionCodes(promotionCodes.filter((value) => value !== code));
                                                        },
                                                        centered: true,
                                                    });
                                                }}
                                            >
                                                삭제
                                            </Button>
                                        </Input.Group>
                                    );
                                })}
                            </Form>

                            <Row>
                                <Col className="pe-1" span={12}>
                                    <Button
                                        block
                                        type="default"
                                        size="large"
                                        onClick={() =>
                                            navigate(Common.PAGE_MYPAGE, {
                                                replace: true,
                                            })
                                        }
                                    >
                                        {String.cancel}
                                    </Button>
                                </Col>
                                <Col className="ps-1" span={12}>
                                    <Button block type="primary" size="large" onClick={() => form.submit()}>
                                        {String.save}
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>
            </MobileView>
            <BrowserView>
                <Row className="login py-4" align="middle" justify="center">
                    <div className="loginCard">
                        <Card bordered={false}>
                            <div className="text-center mb-4">
                                <h3>회원 정보 수정</h3>
                            </div>
                            <Form form={form} onFinish={onFinish} layout="vertical" scrollToFirstError={{ block: "center", scrollMode: "always" }}>
                                <Form.Item label="회원 종류" name="userType" initialValue={member}>
                                    <Radio.Group disabled={true} onChange={onChangedMemberType}>
                                        <Radio value="INDIVIDUAL">개인 회원</Radio>
                                        <Radio value="CORPORATE">법인 회원</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    name="name"
                                    label={checkIndividualType() ? "이름" : "이름(대표자)"}
                                    rules={[
                                        {
                                            required: true,
                                            message: "이름을 입력해 주세요.",
                                        },
                                    ]}
                                >
                                    <Input
                                        className="login-input"
                                        placeholder="휴대전화 명의자 이름을 입력해 주세요."
                                        disabled={true}
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                        onInput={onInputName}
                                    />
                                </Form.Item>
                                {checkIndividualType() === false ? (
                                    <Form.Item name="companyName" label="회사명">
                                        <Input className="login-input" disabled={true} />
                                    </Form.Item>
                                ) : null}
                                {checkIndividualType() === false ? (
                                    <Form.Item
                                        name="companyRegistrationNo"
                                        label="사업자 등록번호"
                                        rules={[
                                            {
                                                required: true,
                                                message: String.msg_company_registration_no_must,
                                            },
                                            {
                                                pattern: new RegExp(/^([-]?[1-9][0-9]*|0)$/),
                                                message: String.msg_company_registration_no_only_number,
                                            },
                                            {
                                                validator: validateCompanyRegistrationNo,
                                            },
                                        ]}
                                    >
                                        <Input className="login-input" placeholder="사업자 등록번호(- 미포함)" disabled={true} />
                                    </Form.Item>
                                ) : null}
                                <Form.Item
                                    name="phone"
                                    label="휴대전화 번호"
                                    required={true}
                                    // rules={[
                                    //     () => ({
                                    //         validator(_, value) {
                                    //             if (certificationResult) {
                                    //                 return Promise.resolve();
                                    //             }

                                    //             return Promise.reject(new Error("휴대전화 본인 인증을 해 주세요."));
                                    //         },
                                    //     }),
                                    // ]}
                                >
                                    <PhoneCertification disabled={true} value={phoneNumber} userName={userName} />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    label="이메일"
                                    rules={[
                                        {
                                            type: "email",
                                            message: "이메일이 유효하지 않습니다.",
                                        },
                                        {
                                            required: true,
                                            message: "이메일을 입력해 주세요.",
                                        },
                                        { max: 64, message: String.msg_email_max },
                                    ]}
                                >
                                    <InputEmail value={email} onChange={(value) => setEmail(value)} />
                                </Form.Item>
                                <Form.Item
                                    className="login-group"
                                    name="fullAddress"
                                    label="주소"
                                    required={true}
                                    rules={[
                                        () => ({
                                            validator(_, value) {
                                                if (value.address && value.address !== "" && value.addressDetails && value.addressDetails !== "") {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(new Error("주소를 입력해 주세요."));
                                            },
                                        }),
                                    ]}
                                >
                                    <AddressMaker value={userAddress} onChange={(address) => setUserAddress(address)} />
                                </Form.Item>
                            </Form>
                            <Divider />
                            <h6>추가 정보</h6>
                            {userInfo?.driverLicense ? (
                                <DriverLicense
                                    ref={driverLicenseRef}
                                    mode="update"
                                    driverLicenseId={userInfo?.driverLicense?.driverLicenseId}
                                    onSuccess={onSuccess}
                                />
                            ) : (
                                <DriverLicense ref={driverLicenseRef} mode="register" onSuccess={onSuccess} />
                            )}

                            <Form form={form2} layout="vertical">
                                <Form.Item
                                    className="login-group"
                                    style={{ marginBottom: promotionCodes.length > 0 ? 0 : "1rem" }}
                                    name="promotionCodes"
                                    label="단체 회원 코드"
                                >
                                    <Input.Group className={duplicationCode || inValidCode ? "mb-0" : "mb-2"} compact>
                                        <Input
                                            className="login-input mb-0"
                                            placeholder="단체 회원 코드"
                                            value={promotionCode}
                                            onChange={(e) => {
                                                setDuplicationCode(false);
                                                setInvalidCode(false);
                                                setPromotionCode(e.target.value);
                                            }}
                                        />
                                        <Button onClick={handleAddCode}>추가</Button>
                                        {duplicationCode && <span style={{ color: "#ff4d4f" }}>이미 추가된 코드입니다.</span>}
                                        {inValidCode && <span style={{ color: "#ff4d4f" }}>유효하지 않은 코드입니다.</span>}
                                    </Input.Group>
                                </Form.Item>
                                {promotionCodes.map((code, index) => {
                                    return (
                                        <Input.Group key={index} compact>
                                            <Input
                                                className={`login-input ${index !== promotionCodes.length - 1 ? "mb-2" : "mb-3"}`}
                                                value={code.b2bName + "(" + code.promotionCode + ")"}
                                            />
                                            <Button
                                                onClick={() => {
                                                    setPromotionCodes(promotionCodes.filter((value) => value !== code));
                                                }}
                                            >
                                                삭제
                                            </Button>
                                        </Input.Group>
                                    );
                                })}
                            </Form>

                            <Row>
                                <Col className="pe-1" span={12}>
                                    <Button
                                        block
                                        type="default"
                                        size="large"
                                        onClick={() =>
                                            navigate(Common.PAGE_MYPAGE, {
                                                replace: true,
                                            })
                                        }
                                    >
                                        {String.cancel}
                                    </Button>
                                </Col>
                                <Col className="ps-1" span={12}>
                                    <Button block type="primary" size="large" onClick={() => form.submit()}>
                                        {String.save}
                                    </Button>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </Row>
            </BrowserView>
        </>
    );
}

export default UpdateMember;
