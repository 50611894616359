import * as React from "react";

const WarrantyInfo = ({ maker }: { maker: string }) => {
    return (
        <div className="mb-5">
            <div className="text-center mb-4">
                <h3 className="mb-4">보증 정보</h3>
                <h6 style={{ wordBreak: "keep-all" }}>
                    비바모빌리티㈜ 에서 제조판매한 자동차는 자동차관련 제반 법 규정에 적합하도록 설계 제작되었으며, 품질관리 기준에 의거 검사과정을
                    거친 제품입니다.
                </h6>
            </div>
            <div className="col-lg-12 text-center optionBox">
                <div className="col-md-12 emphasis">
                    <table className="table design-table ">
                        <thead></thead>
                        <tbody>
                            <tr>
                                <th scope="col">차량 보증 기간</th>
                                <td></td>
                            </tr>
                            <tr>
                                <th>자체 및 일반부품 냉난방장치</th>
                                <td>
                                    3년 또는 6만Km 중 먼저 선도래 한 것을
                                    <br /> 보증기간 만료로 간주 합니다.
                                </td>
                            </tr>
                            <tr>
                                <th>배터리 및 동력 전달 장치</th>
                                <td>
                                    5년 또는 40만Km 중 먼저 선도래 한 것을
                                    <br /> 보증기간 만료로 간주 합니다.
                                </td>
                            </tr>
                            <tr>
                                <th>특장</th>
                                <td>구입일로부터 1년</td>
                            </tr>
                            <tr>
                                <th colSpan={2} style={{ fontWeight: "400" }}>
                                    ※ 상기 보증기간 내에 차량의 보증책임은 원칙적으로 비바모빌리티(주)에 있으며, 캠핑카 특장 부분에 대한 보증책임은
                                    {" " + maker}에 있습니다. <br />※ 단, 주식회사 루타스는 위 보증기간 내에 발생한 하자에 대해 고객이 적정한 조치를
                                    받을 수 있도록 최대한 협조하며, 보증수리 기간이 지났거나, 기타 일반 수리 항목이 발생한 경우에도 고객이 조치를 받을
                                    수 있도록 최대한 협조합니다.
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default WarrantyInfo;
