import React, { useEffect, useState } from "react";
import { Button, Col, List, Row, Modal, Space, Divider } from "antd";
import { NumericFormat } from "react-number-format";
import { useAuthState } from "../../provider/AuthProvider";
import { useAsyncAxios, utilAxiosWithAuth } from "../../utils/customAxios";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as Request from "../../commons/request";
import * as Strings from "../../commons/string";
import * as Utils from "../../utils/utils";

import styles from "./Order.module.css";
import Gallery from "../../component/Gallery";
import IconBack from "../../assets/images/home/ruta40/icon_back.svg";
import IconArrow from "../../assets/images/home/ruta40/icon_arrow.svg";
import LayoutFooter from "../../component/LayoutFooter";

import bayrunTypeExterior1 from "../../assets/images/model/bayrun/BayrunType-exterior-1.jpg";
import bayrunTypeExterior2 from "../../assets/images/model/bayrun/BayrunType-exterior-2.jpg";
import bayrunTypeExterior3 from "../../assets/images/model/bayrun/BayrunType-exterior-3.jpg";

import bayrun3TypeInterior1 from "../../assets/images/model/bayrun/BayrunType-interior-1.jpg";
import bayrun3TypeInterior2 from "../../assets/images/model/bayrun/BayrunType-interior-3.jpg";

import bayrun4TypeInterior1 from "../../assets/images/model/bayrun/BayrunType-interior-9.jpg";
import bayrun4TypeInterior2 from "../../assets/images/model/bayrun/BayrunType-interior-10.jpg";

import redmaxTypeExterior1 from "../../assets/images/model/redmax/Redmax-exterior-1.jpg";
import redmaxTypeExterior2 from "../../assets/images/model/redmax/Redmax-exterior-2.jpg";
import redmaxTypeExterior3 from "../../assets/images/model/redmax/Redmax-exterior-3.jpg";
import redmaxTypeInterior1 from "../../assets/images/model/redmax/Redmax-interior-1.jpg";
import redmaxTypeInterior2 from "../../assets/images/model/redmax/Redmax-interior-2.jpg";

declare const window: typeof globalThis & {
    IMP: any;
};

function Payment() {
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useMediaQuery({ maxWidth: "765px" });

    const [paymentType, setPaymentType] = useState<string>("danal");
    const [orderData, setOrderData] = useState<TypeDTO.OrderDto>();
    const [resultOrderData, setResultOrderData] = useState<TypeDTO.OrderDto>();
    const [requestPaymentData, setRequestPaymentData] = useState<TypeDTO.PaymentDto>();

    useEffect(() => {
        const data = JSON.parse(String(sessionStorage.getItem(Common.SESSION_ORDER)));
        data ? setOrderData(data) : navigate(Common.PAGE_NOTFOUND, { replace: true });
    }, []);

    const requestAxiosPostOrder = async () => {
        const response = await utilAxiosWithAuth().post(Request.ORDER_URL, orderData);
        return response.data;
    };

    const {
        loading: loadingPostOrder,
        error: errorPostOrder,
        data: resultPostOrder,
        execute: requestPostOrder,
    } = useAsyncAxios(requestAxiosPostOrder);

    useEffect(() => {
        if (!resultPostOrder) return;
        console.log("resultPostOrder", resultPostOrder);

        setResultOrderData(resultPostOrder.order);
    }, [resultPostOrder]);

    useEffect(() => {
        if (!errorPostOrder) return;

        console.log("errorPostOrder", errorPostOrder);
    }, [errorPostOrder]);

    useEffect(() => {
        if (!resultOrderData) return;
        // TODO: show Order Complete - 결제 프로세스를 따로 진행할 경우

        if (resultOrderData && resultOrderData.payments && resultOrderData.payments.length > 0) {
            const depositPayment = resultOrderData.payments.filter(
                (payment) => payment.paymentType === Common.PaymentType.DEPOSIT && payment.paymentMethod === Common.PaymentMethod.ONLINE
            );
            if (depositPayment.length > 0) setRequestPaymentData(depositPayment[0]);
        }
    }, [resultOrderData]);

    const requestAxiosCancelOrder = async () => {
        const response = await utilAxiosWithAuth().post(Request.ORDER_CANCEL_URL + "/" + resultOrderData?.orderId);
        return response.data;
    };

    const {
        loading: loadingCancelOrder,
        error: errorCancelOrder,
        data: resultCancelOrder,
        execute: requestCancelOrder,
    } = useAsyncAxios(requestAxiosCancelOrder);

    useEffect(() => {
        if (!resultCancelOrder) return;

        console.log("resulCancelOrder", resultCancelOrder);
    }, [resultCancelOrder]);

    useEffect(() => {
        if (!errorCancelOrder) return;

        console.log("errorCancelOrder", errorCancelOrder);
    }, [errorCancelOrder]);

    useEffect(() => {
        if (!requestPaymentData) return;
        console.log("requestPaymentData", requestPaymentData);
        requestPaymentData.order = { orderId: resultOrderData?.orderId };

        requestPaymentPrepare();
    }, [requestPaymentData]);

    const handleAddOrder = () => {
        requestPostOrder();
    };

    const makeNameNPriceItem = (name: string, price: number) => {
        return (
            <Row>
                <Col span={15} style={{ textAlign: "left" }}>
                    <p className="b-md">{name}</p>
                </Col>
                <Col span={9} style={{ textAlign: "right" }}>
                    <p className="b-md mb-0">
                        {price === 0 ? "포함" : <NumericFormat value={price} thousandSeparator={true} prefix={"₩"} displayType="text" />}
                    </p>
                </Col>
            </Row>
        );
    };

    const orderSelectList = () => {
        if (!orderData) return null;
        const { carPrice, carPriceVAT, subsidyPrice, subsidySiGunPrice } = Utils.getPriceFromOrderData(orderData);

        return (
            <>
                <Button onClick={() => navigate(-1)} className={styles.btnEdit}>
                    <Space size={0}>
                        <img src={IconBack} />
                        <p className="b-lg b-bd" style={{ marginLeft: "5px" }}>
                            주문 수정
                        </p>
                    </Space>
                </Button>
                <h5 className="mt-3">주문 내역</h5>

                <p style={{ fontSize: 16 }} className="mb-0 b-bd">
                    차량가
                </p>
                <List className="list-border-none">
                    {orderData.carType && (
                        <>
                            <List.Item>
                                <Row align="bottom">
                                    <Col span={14} style={{ textAlign: "left" }}>
                                        <p className="b-md">{orderData.carType.model.modelName + " " + orderData.carType.typeName}</p>
                                    </Col>
                                    <Col span={10} style={{ textAlign: "right" }}>
                                        <p className="b-md" style={{ /*color: "#E21D12",*/ textAlign: "right" }}>
                                            &nbsp;
                                            <NumericFormat value={orderData.carType.price} thousandSeparator={true} prefix={"₩"} displayType="text" />
                                        </p>
                                    </Col>
                                </Row>
                            </List.Item>
                        </>
                    )}
                    {orderData.color && <List.Item>{makeNameNPriceItem(orderData.color.colorName, 0)}</List.Item>}
                </List>
                {orderData.carType && orderData.carType.additionalPrice > 0 && (
                    <>
                        <p style={{ fontSize: 16 }} className="mt-3 mb-0 b-bd">
                            캠핑카 특장
                        </p>
                        <List className="list-border-none">
                            <List.Item>
                                <Row>
                                    <Col span={14} style={{ textAlign: "left" }}>
                                        <p className="b-md">{orderData.carType.additionalCompany}</p>
                                    </Col>
                                    <Col span={10} style={{ textAlign: "right" }}>
                                        <p className="b-md" style={{ /*color: "#E21D12",*/ textAlign: "right" }}>
                                            &nbsp;
                                            <NumericFormat
                                                value={orderData.carType.additionalPrice}
                                                thousandSeparator={true}
                                                prefix={"₩"}
                                                displayType="text"
                                            />
                                        </p>
                                    </Col>
                                </Row>
                            </List.Item>
                        </List>
                    </>
                )}
                {orderData.options && orderData.options.length > 0 && (
                    <>
                        <p style={{ fontSize: 16 }} className="mt-3 mb-0 b-bd">
                            선택 옵션
                        </p>
                        <List className="list-border-none">
                            {orderData.options.map((option) => {
                                if (option.price === 0 && option.optionName === "선택 안함") return null;
                                return <List.Item key={option.optionId}>{makeNameNPriceItem(option.optionName, option.price)}</List.Item>;
                            })}
                        </List>
                    </>
                )}
                <Divider className="my-2" />
                <Row>
                    <Col span={12} style={{ textAlign: "left" }}>
                        <p className="b-md">주문 금액</p>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <p className="b-md">
                            <NumericFormat value={carPrice} thousandSeparator={true} prefix={"₩"} displayType="text" />
                        </p>
                    </Col>
                </Row>
                <Row style={{ width: "100%" }} justify="space-between">
                    <Col span={12}>
                        <img src={IconArrow} style={{ verticalAlign: "baseline", marginRight: 4 }} />
                        <span className="b-md">부가세(10%)</span>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <p className="b-md">
                            <NumericFormat value={carPriceVAT} thousandSeparator={true} prefix={"₩"} displayType="text" />
                        </p>
                    </Col>
                </Row>

                <Row>
                    <Col span={12} style={{ textAlign: "left" }}>
                        <p className="b-md">전기차 보조금</p>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <p className="b-md">
                            <NumericFormat value={(subsidyPrice + subsidySiGunPrice) * -1} thousandSeparator={true} prefix={"₩"} displayType="text" />
                        </p>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col span={12} style={{ textAlign: "left" }}>
                        <p style={{ fontSize: 16 }} className="mb-0 b-bd">
                            총 주문 금액
                        </p>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <h6 className="mb-0">
                            <NumericFormat
                                value={carPrice + carPriceVAT + (subsidyPrice + subsidySiGunPrice) * -1}
                                thousandSeparator={true}
                                prefix={"₩"}
                                displayType="text"
                            />
                        </h6>
                    </Col>
                </Row>
            </>
        );
    };

    const orderPrice = () => {
        return (
            <>
                <Row className="mb-2">
                    <Col span={12} style={{ textAlign: "left", paddingTop: 1 }}>
                        <p style={{ fontSize: 16 }} className="mb-0">
                            예약금
                        </p>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <h5 className={`${styles.textPrice} mb-0`}>
                            <NumericFormat
                                value={Utils.getPaidAmountByPaymentType(Common.PaymentType.DEPOSIT, orderData?.payments)}
                                thousandSeparator={true}
                                displayType="text"
                            />
                            원
                        </h5>
                    </Col>
                </Row>
                <p className="mb-2">※ 입금하신 예약금은 본계약 체결시 계약금에 합산됩니다.</p>
                <p className="pb-2">
                    ※ 신용카드 결제 시<span className="text-danger"> 하나카드</span>는 카드사 자체 미지원으로
                    <span className="text-danger"> 결제</span>가 <span className="text-danger">불가</span>합니다.
                </p>
                <span style={{ fontSize: 14 }} className="text-danger">
                    ※ 취소/환불 안내
                </span>
                <p style={{ marginLeft: "10px" }}>• 가계약 상태의 예약금은 취소/환불이 가능합니다.</p>
                <p className="mb-3" style={{ marginLeft: "10px" }}>
                    • 본계약을 체결한 이후에는 차량 제작이 진행되므로 취소/환불이 불가합니다.
                </p>
            </>
        );
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////
    // Payment
    const [resultPaymentData, setResultPaymentData] = useState<TypeDTO.PaymentDto>();

    const requestAxiosPaymentPrepare = async () => {
        const response = await utilAxiosWithAuth().post(Request.PAYMENT_PREPARE_URL + "/" + requestPaymentData?.paymentId);
        return response.data;
    };

    const {
        loading: loadingPaymentPrepare,
        error: errorPaymentPrepare,
        data: resultPaymentPrepare,
        execute: requestPaymentPrepare,
    } = useAsyncAxios(requestAxiosPaymentPrepare);

    useEffect(() => {
        if (!resultPaymentPrepare) return;
        console.log("resultPaymentPrepare", resultPaymentPrepare);

        setResultPaymentData(resultPaymentPrepare.payment);
    }, [resultPaymentPrepare]);

    useEffect(() => {
        if (!errorPaymentPrepare) return;

        console.log("errorPaymentPrepare", errorPaymentPrepare);
    }, [errorPaymentPrepare]);

    useEffect(() => {
        if (!resultPaymentData) return;

        showPaymentWindow();
    }, [resultPaymentData]);

    const showPaymentWindow = () => {
        if (!resultPaymentData) return;
        const { IMP } = window;

        IMP.request_pay(
            {
                pg: Utils.getDanalCPID(paymentType),
                pay_method: "card",
                merchant_uid: resultPaymentData.paymentImp?.merchantUid,
                name: resultPaymentData.order?.carType?.model.modelName + " " + resultPaymentData.order?.carType?.typeName,
                amount: resultPaymentData.amount,
                buyer_email: resultPaymentData.order?.user?.email ? resultPaymentData.order?.user?.email : "",
                buyer_name: resultPaymentData.order?.user?.name,
                buyer_tel: resultPaymentData.order?.user?.phone,
                buyer_addr: resultPaymentData.order?.user?.address
                    ? resultPaymentData.order?.user?.address + " " + resultPaymentData.order?.user?.addressDetails
                    : "",
                m_redirect_url: window.location.origin + location.pathname,
            },
            (response: any) => {
                if (!response.imp_uid) {
                    failedPopupPayment(response.error_msg + "\n고객센터에 문의해 주세요.");
                } else {
                    const paymentCompleteData: TypeDTO.ImpPaymentCompleteDto = {
                        impUid: response.imp_uid,
                        merchantUid: response.merchant_uid,
                        success: response.success,
                        errorMsg: response.error_msg,
                    };

                    handlePaymentComplete(paymentCompleteData);
                }
            }
        );
    };

    const handlePaymentComplete = (paymentCompleteData: TypeDTO.ImpPaymentCompleteDto) => {
        requestPaymentComplete(paymentCompleteData);
    };

    const requestAxiosPaymentComplete = async (paymentCompleteData: TypeDTO.ImpPaymentCompleteDto) => {
        const response = await utilAxiosWithAuth().post(Request.PAYMENT_COMPLETE_URL, paymentCompleteData);
        return response.data;
    };

    const {
        loading: loadingPaymentComplete,
        error: errorPaymentComplete,
        data: resultPaymentComplete,
        execute: requestPaymentComplete,
    } = useAsyncAxios(requestAxiosPaymentComplete);

    useEffect(() => {
        if (!resultPaymentComplete) return;

        if (resultPaymentComplete.payment.paidDate) {
            popupPaymentSuccess();
        } else if (resultPaymentComplete.payment.failedDate) {
            requestCancelOrder();
            popupPaymentFailed();
        }
    }, [resultPaymentComplete]);

    useEffect(() => {
        if (!errorPaymentComplete) return;

        console.log("errorPaymentComplete", errorPaymentComplete);
    }, [errorPaymentComplete]);

    ///////////////////////////////////////////////////////////////////////////////////////////////

    const failedPopupPayment = (message: string) => {
        Modal.error({
            title: "결제 실패",
            content: message,
            okText: Strings.confirm,
            onOk() {},
            centered: true,
        });
    };

    const popupPaymentSuccess = () => {
        Modal.confirm({
            title: "주문 완료",
            content: "주문 신청이 완료되었습니다.\n자세한 사항은 [계정-주문내역] 메뉴에서 확인하실 수 있습니다.",
            cancelText: Strings.close,
            onCancel() {
                navigate(Common.PAGE_HOME);
            },
            okText: "내 계약정보 보기",
            onOk() {
                navigate(Common.PAGE_MY_ORDER);
            },
            centered: true,
        });
    };

    const popupPaymentFailed = () => {
        Modal.info({
            title: "결제 취소",
            content: "결제가 취소되었습니다.",
            okText: Strings.confirm,
            onOk() {},
            centered: true,
        });
    };

    return (
        <div className={styles.body}>
            <div className={styles.payment}>
                {isMobile ? (
                    <div className={styles.mobileScreen}>
                        <Row>
                            <Col sm={{ span: 24 }} md={{ span: 12 }}>
                                <Gallery
                                    images={
                                        orderData?.carType?.typeName.includes("배이런(3인승)")
                                            ? [
                                                  bayrunTypeExterior1,
                                                  bayrunTypeExterior2,
                                                  bayrunTypeExterior3,
                                                  bayrun3TypeInterior1,
                                                  bayrun3TypeInterior2,
                                              ]
                                            : orderData?.carType?.typeName.includes("배이런(4인승)")
                                            ? [
                                                  bayrunTypeExterior1,
                                                  bayrunTypeExterior2,
                                                  bayrunTypeExterior3,
                                                  bayrun4TypeInterior1,
                                                  bayrun4TypeInterior2,
                                              ]
                                            : orderData?.carType?.typeName.includes("레드맥스")
                                            ? [
                                                  redmaxTypeExterior1,
                                                  redmaxTypeExterior2,
                                                  redmaxTypeExterior3,
                                                  redmaxTypeInterior1,
                                                  redmaxTypeInterior2,
                                              ]
                                            : []
                                    }
                                />
                            </Col>
                            <div className={styles.underBody}>
                                {orderSelectList()}
                                <Divider className="my-2" />
                                <p style={{ fontSize: 16 }} className="mb-0 b-bd">
                                    결제 금액
                                </p>
                                {orderPrice()}
                                <div className="text-center mt-4" style={{ marginBottom: "100px" }}>
                                    <Button className="btn-confirm w-100" onClick={handleAddOrder}>
                                        결제 하기
                                    </Button>
                                </div>
                                <LayoutFooter orderType={true} />
                            </div>
                        </Row>
                    </div>
                ) : (
                    <>
                        <Row gutter={30} className="orderBoxWrap">
                            <Col className="orderImage" sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 14 }}>
                                <Gallery
                                    images={
                                        orderData?.carType?.typeName.includes("배이런(3인승)")
                                            ? [
                                                  bayrunTypeExterior1,
                                                  bayrunTypeExterior2,
                                                  bayrunTypeExterior3,
                                                  bayrun3TypeInterior1,
                                                  bayrun3TypeInterior2,
                                              ]
                                            : orderData?.carType?.typeName.includes("배이런(4인승)")
                                            ? [
                                                  bayrunTypeExterior1,
                                                  bayrunTypeExterior2,
                                                  bayrunTypeExterior3,
                                                  bayrun4TypeInterior1,
                                                  bayrun4TypeInterior2,
                                              ]
                                            : orderData?.carType?.typeName.includes("레드맥스")
                                            ? [
                                                  redmaxTypeExterior1,
                                                  redmaxTypeExterior2,
                                                  redmaxTypeExterior3,
                                                  redmaxTypeInterior1,
                                                  redmaxTypeInterior2,
                                              ]
                                            : []
                                    }
                                />
                            </Col>
                            <Col className="orderBox pe-4" sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 10 }}>
                                <div className={styles.rightBody}>
                                    {orderSelectList()}
                                    <Divider className="my-2" />
                                    <p style={{ fontSize: 16 }} className="mb-0 b-bd">
                                        결제 금액
                                    </p>
                                    {orderPrice()}
                                    <div className="text-center mt-4" style={{ marginBottom: "100px" }}>
                                        <Button className="btn-confirm" onClick={handleAddOrder}>
                                            결제 하기
                                        </Button>
                                    </div>
                                    <LayoutFooter orderType={true} />
                                </div>
                            </Col>
                        </Row>
                    </>
                )}
            </div>
        </div>
    );
}

export default Payment;
