import React from "react";
import { Col } from "antd";
import { Carousel } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import styles from "./Brochure.module.css";
import Gallery from "../../component/Gallery";

import mobileImage1 from "../../assets/images/brochure/redmax/brochure_redmax_mobile1.jpg";
import mobileImage2 from "../../assets/images/brochure/redmax/brochure_redmax_mobile2.jpg";
import mobileImage3 from "../../assets/images/brochure/redmax/brochure_redmax_mobile3.jpg";
import mobileImage4 from "../../assets/images/brochure/redmax/brochure_redmax_mobile4.jpg";

import browserImage1 from "../../assets/images/brochure/redmax/brochure_redmax_browser1.jpg";
import browserImage2 from "../../assets/images/brochure/redmax/brochure_redmax_browser2.jpg";

import galleryImage1 from "../../assets/images/brochure/redmax/gallery1.jpg";
import galleryImage2 from "../../assets/images/brochure/redmax/gallery2.jpg";
import galleryImage3 from "../../assets/images/brochure/redmax/gallery3.jpg";
import galleryImage4 from "../../assets/images/brochure/redmax/gallery4.jpg";
import galleryImage5 from "../../assets/images/brochure/redmax/gallery5.jpg";
import galleryImage7 from "../../assets/images/brochure/redmax/gallery7.jpg";
import galleryImage8 from "../../assets/images/brochure/redmax/gallery8.jpg";
import galleryImage9 from "../../assets/images/brochure/redmax/gallery9.jpg";
import galleryImage10 from "../../assets/images/brochure/redmax/gallery10.jpg";

import optionImage1 from "../../assets/images/brochure/redmax/option1.jpg";
import optionImage2 from "../../assets/images/brochure/redmax/option2.jpg";
import optionImage3 from "../../assets/images/brochure/redmax/option3.jpg";
import optionImage4 from "../../assets/images/brochure/redmax/option4.jpg";
import optionImage5 from "../../assets/images/brochure/redmax/option5.jpg";
import optionImage6 from "../../assets/images/brochure/redmax/option6.jpg";
import optionImage7 from "../../assets/images/brochure/redmax/option7.jpg";
import optionImage8 from "../../assets/images/brochure/redmax/option8.jpg";
import optionImage9 from "../../assets/images/brochure/redmax/option9.jpg";
import optionImage10 from "../../assets/images/brochure/redmax/option10.jpg";
import optionImage11 from "../../assets/images/brochure/redmax/option11.jpg";
import optionImage12 from "../../assets/images/brochure/redmax/option12.jpg";
import optionImage13 from "../../assets/images/brochure/redmax/option13.jpg";
import optionImage14 from "../../assets/images/brochure/redmax/option14.jpg";
import optionImage15 from "../../assets/images/brochure/redmax/option15.jpg";

function BrochureRedMax() {
    const getOptionImage = (array: Array<string>) => {
        return array.map((image, index) => {
            return (
                <Carousel.Item key={index}>
                    <img className="d-block w-100 img-fluid" alt="First slide" src={image} />
                </Carousel.Item>
            );
        });
    };
    return (
        <div style={{ minHeight: "calc(100vh - 70px)" }}>
            <MobileView className="mobile">
                <div className={styles.mobileLayout}>
                    <img className={styles.backgroundImage} src={mobileImage1} />
                    <div className={styles.galleryArea}>
                        <div className={styles.galleryWrapper}>
                            <Gallery
                                styles="brochure_gallery"
                                images={[
                                    galleryImage1,
                                    galleryImage2,
                                    galleryImage3,
                                    galleryImage4,
                                    galleryImage5,
                                    galleryImage7,
                                    galleryImage8,
                                    galleryImage9,
                                    galleryImage10,
                                ]}
                            />
                        </div>
                        <img className={styles.backgroundImage} src={mobileImage2} />
                    </div>
                    <div className={styles.carouselArea}>
                        <img className={styles.backgroundImage} src={mobileImage3} />
                        <div className={styles.carouselWrapper}>
                            <h3 className={styles.subTitle}>냉/난방</h3>
                            <Carousel variant="light">{getOptionImage([optionImage1, optionImage2])}</Carousel>
                        </div>
                        <div className={styles.carouselWrapper}>
                            <Carousel variant="light">
                                {getOptionImage([optionImage3, optionImage4, optionImage5, optionImage6, optionImage7])}
                            </Carousel>
                        </div>
                        <div className={styles.carouselWrapper}>
                            <Carousel variant="light">
                                {getOptionImage([
                                    optionImage8,
                                    optionImage9,
                                    optionImage10,
                                    optionImage11,
                                    optionImage12,
                                    optionImage13,
                                    optionImage14,
                                    optionImage15,
                                ])}
                            </Carousel>
                        </div>
                    </div>
                    <img className={styles.backgroundImage} src={mobileImage4} />
                </div>
            </MobileView>

            <BrowserView className="browser">
                <Col xs={{ span: 24 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 16, offset: 4 }} className={styles.browserLayout}>
                    <img className={styles.backgroundImage} src={browserImage1} />
                    <div className={styles.galleryArea}>
                        <div className={styles.galleryWrapper}>
                            <Gallery
                                styles="brochure_gallery browser"
                                images={[
                                    galleryImage1,
                                    galleryImage2,
                                    galleryImage3,
                                    galleryImage4,
                                    galleryImage5,
                                    galleryImage7,
                                    galleryImage8,
                                    galleryImage9,
                                    galleryImage10,
                                ]}
                            />
                        </div>
                    </div>
                    <img className={styles.backgroundImage} src={browserImage2} />
                </Col>
            </BrowserView>
        </div>
    );
}

export default BrochureRedMax;
