import React, { useEffect, useState } from "react";
import { Col, Button, Spin, Row } from "antd";
import { FilePdfFilled } from "@ant-design/icons";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";
import { useAlert } from "../../provider/AlertProvider";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";

import Banner from "../../assets/images/home/ruta40/banner_customer.jpg";

declare const window: typeof globalThis & {
    Android: any;
    webkit: any;
};

function Manual() {
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingID, setLoadingID] = useState<Array<number>>([]);
    const [manuals, setManuals] = useState<Array<TypeDTO.RentManualDto>>([]);
    const [rentVideoManuals, setRentVideoManuals] = useState<Array<TypeDTO.RentVideoManualDto>>([]);
    const alert = useAlert();

    useEffect(() => {
        requestGetManuals();
        requestGetRentVideoManuals();
    }, []);

    const requestAxiosGetManuals = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_MANUALS_URL);
        return response.data;
    };

    const {
        loading: loadingGetManuals,
        error: errorGetManuals,
        data: resultGetManuals,
        execute: requestGetManuals,
    } = useAsyncAxios(requestAxiosGetManuals);

    useEffect(() => {
        if (!resultGetManuals) return;
        console.log("resultGetManuals", resultGetManuals);
        setManuals(resultGetManuals.rentManuals);
    }, [resultGetManuals]);

    useEffect(() => {
        if (!errorGetManuals) return;
        console.log("errorGetManuals", errorGetManuals);
    }, [errorGetManuals]);

    const requestAxiosGetRentVideoManuals = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_MANUAL_VIDEOS_URL);
        return response.data;
    };

    const {
        loading: loadingGetRentVideoManuals,
        error: errorGetRentVideoManuals,
        data: resultGetRentVideoManuals,
        execute: requestGetRentVideoManuals,
    } = useAsyncAxios(requestAxiosGetRentVideoManuals);

    useEffect(() => {
        if (!resultGetRentVideoManuals) return;
        console.log("resultGetRentVideoManuals", resultGetRentVideoManuals);
        setRentVideoManuals(resultGetRentVideoManuals.rentVideoManuals);
    }, [resultGetRentVideoManuals]);

    useEffect(() => {
        if (!errorGetRentVideoManuals) return;
        console.log("errorGetRentVideoManuals", errorGetRentVideoManuals);
    }, [errorGetManuals]);

    const downloadManual = async (manualId: number, manualFileName: string) => {
        if (window.webkit?.messageHandlers?.getBase64FromBlobData) {
            const message = {
                url: Request.RENT_MANUAL_DOWNLOAD_URL + manualId,
                fileName: manualFileName,
            };
            window.webkit.messageHandlers.getBase64FromBlobData.postMessage(message);
        } else {
            setLoading(true);
            setLoadingID([...loadingID, manualId]);

            await utilAxiosWithAppToken()
                .get(Request.RENT_MANUAL_DOWNLOAD_URL + manualId, { responseType: "blob" })
                .then((response) => {
                    setLoadingID(loadingID.filter((element) => element !== manualId));
                    if (window.Android) {
                        const reader = new FileReader();
                        reader.readAsDataURL(response.data);
                        reader.onloadend = function () {
                            window.Android.getBase64FromBlobData(manualFileName, reader.result);
                        };
                    } else {
                        const file = new Blob([response.data], { type: "application/pdf;" });
                        const element = document.createElement("a");
                        element.href = URL.createObjectURL(file);
                        element.download = manualFileName;
                        element.click();
                    }
                })
                .catch((error) => {
                    console.log("downloadManual error", error);
                    alert.setAlert("", "파일 다운로드 실패", "파일 다운로드에 실패했습니다.");
                });
        }
    };

    const displayManuals = () => {
        if (manuals.length === 0) return null;

        return (
            <div className={`mb-5 p-0`}>
                <div className="text-center mb-5 mt-3">
                    <h3>매뉴얼 다운로드</h3>
                </div>
                <div className="p-0 mb-5">
                    <div className="row mb-4">
                        <div className="col-lg-12 col-md-12">
                            <table className="table design-table">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center" }}>버전</th>
                                        <th style={{ textAlign: "center" }}>자료명</th>
                                        <th style={{ textAlign: "center" }}>PDF 파일</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {manuals.map((manual) => {
                                        return (
                                            <tr key={manual.rentManualId}>
                                                <td style={{ textAlign: "center" }}>{manual.rentManualVersion}</td>
                                                <td style={{ textAlign: "center" }}>{manual.rentManualName}</td>
                                                <td style={{ textAlign: "center" }}>
                                                    {loading && loadingID.includes(manual.rentManualId) ? (
                                                        <Spin className="loading" size="small" style={{ width: 63.8 }} />
                                                    ) : (
                                                        <Button
                                                            icon={<FilePdfFilled />}
                                                            size="small"
                                                            onClick={() => downloadManual(manual.rentManualId, manual.rentManualFileName)}
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const displayVideoManuals = () => {
        if (!rentVideoManuals) return null;

        return (
            <div className={`mb-5 p-0`}>
                <div className="text-center mb-5 mt-3">
                    <h3>동영상 매뉴얼</h3>
                </div>
                <div className="model-tabs model-body" style={{ backgroundColor: "white" }}>
                    <Row className={`row mb-5`}>
                        {rentVideoManuals.map((rentVideoManual, index) => {
                            return (
                                <Col key={rentVideoManual.rentVideoManualId} sm={{ span: 24 }} lg={{ span: 8 }} className="p-3 pt-0">
                                    <div className="media-card ">
                                        <img src={rentVideoManual.imageUrl} alt="" className="media-card-img" />
                                        <h6 className="mb-2">
                                            <a className="text-dark" href={rentVideoManual.rentVideoManualUri}>
                                                {rentVideoManual.rentVideoManualName}
                                            </a>
                                        </h6>
                                        <div className="media-button-wrapper">
                                            <a
                                                href={rentVideoManual.rentVideoManualUri}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="btn btn-primary media-button"
                                                style={{ WebkitLineClamp: 1 }}
                                            >
                                                보러가기
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
            </div>
        );
    };

    return (
        <>
            <div className="header-container model">
                <div
                    className="container fadeIn text-center mb-5"
                    style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)),url(${Banner})` }}
                >
                    <h5 className="text-center text-light px-3 mb-4">매뉴얼</h5>
                    <h1 className="text-light">MANUAL</h1>
                </div>
            </div>
            <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    {displayManuals()}
                    {displayVideoManuals()}
                </Col>
            </div>
        </>
    );
}

export default Manual;
