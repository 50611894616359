import IconRight from "../../assets/images/home/rent/icon_right.svg";
import * as TypeDTO from "../../commons/typeDTO";

function RentVideoManualCard({ rentVideoManual, className }: { rentVideoManual: TypeDTO.RentVideoManualDto; className?: string }) {
    return (
        <div className={`media-card review-card rent-video-manual-item ${className ?? ""}`} style={{ padding: 0, overflow: "hidden" }}>
            <a href={rentVideoManual.rentVideoManualUri} target="_blank" rel="noreferrer">
                <div className="thumbnail">
                    <img src={rentVideoManual.imageUrl} />
                </div>
                <div className="summary text-dark">
                    <span style={{ fontWeight: "bold", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                        {rentVideoManual.rentVideoManualName}
                    </span>
                    <img src={IconRight} />
                </div>
            </a>
        </div>
    );
}

export default RentVideoManualCard;
