import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button, Checkbox, Modal, Divider } from "antd";
import { useAuthState } from "../../provider/AuthProvider";
import { useAsyncAxios, utilAxiosWithAppToken, utilAxiosWithAuth } from "../../utils/customAxios";
import { BrowserView, MobileView } from "react-device-detect";
import type { CheckboxValueType } from "antd/es/checkbox/Group";

import * as Request from "../../commons/request";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as TypeDTO from "../../commons/typeDTO";
import * as Common from "../../commons/common";

import Info from "../../component/rent/Info";
import LayoutFooter from "../../component/LayoutFooter";
import MobileDrawer from "../../component/rent/MobileDrawer";
import Loading from "../common/Loading";
import RefundInfo from "../../component/rent/RefundInfo";
import ReservationInfo from "../../component/rent/ReservationInfo";
import Title from "../../component/rent/Title";
import ImageSlider from "../../component/ImageSlider";
import ReservationDetail from "../../component/rent/detail/ReservationDetail";
import styles from "./Reservation.module.css";
import PenaltyInfo from "../../component/rent/PenaltyInfo";
import PaymentSelector from "../../component/PaymentSelector";
import moment from "moment";

declare const window: typeof globalThis & {
    IMP: any;
    Android: any;
    webkit: any;
};

type DrawerType = {
    title: string;
    content: React.ReactNode;
};

function Reservation() {
    const userDetails = useAuthState();
    const navigate = useNavigate();
    const CheckboxGroup = Checkbox.Group;

    const sessionData = sessionStorage.getItem(Common.SESSION_RESERVATION_INFO);
    const values = sessionData !== null ? JSON.parse(sessionData) : null;
    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);
    const [checkAll, setCheckAll] = useState(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [content, setcontent] = useState<DrawerType>();
    const [paymentType, setPaymentType] = useState<string>("card"); // card(신용카드), trans(실시간계좌이체), vbank(가상계좌)
    const [rentPeakSeasons, setRentPeakSeasons] = useState<Array<TypeDTO.RentPeakSeasonDto>>();

    useEffect(() => {
        requestGetRentPeakSeasons();
    }, []);

    const title = {
        title: {
            content: values.rentCar?.title,
            style: { fontSize: "20px" },
        },
        tags: values.rentCar?.rentRestrictions,
    };

    const PCtitle = {
        disabledImage: true,
        title: {
            content: values.rentCar?.title,
        },
        description: {
            content: values.rentCar?.description,
        },
        tags: values.rentCar?.rentRestrictions,
    };

    const plainOptions = ["취소/환불 규정에 동의합니다.", "대여조건 규정에 동의합니다.", "위약금 규정에 동의합니다."];

    const showPaymentWindow = (payment: TypeDTO.RentPaymentDto) => {
        if (payment.amount > 0) {
            const { IMP } = window;
            IMP.request_pay(
                {
                    pg: Utils.getDanalCPID(paymentType),
                    pay_method: paymentType,
                    merchant_uid: payment?.paymentImp?.merchantUid,
                    name: payment?.rent?.rentCar?.title,
                    amount: payment?.amount,
                    buyer_email: payment?.rent?.user?.email,
                    buyer_name: payment?.rent?.user?.name,
                    buyer_tel: payment?.rent?.user?.phone,
                    buyer_addr: payment.rent?.user?.address + " " + payment.rent?.user?.addressDetails,
                    m_redirect_url: window.location.origin + location.pathname,
                    escrow: true,
                    biz_num: "3628101755",
                },
                (response: any) => {
                    if (response.imp_uid === null) {
                        failedPopupPayment(response.error_msg + "\n고객센터에 문의해 주세요.");
                    } else {
                        const paymentCompleteData: TypeDTO.ImpPaymentCompleteDto = {
                            impUid: response.imp_uid,
                            merchantUid: response.merchant_uid,
                            success: response.success,
                            errorMsg: response.error_msg,
                        };

                        handlePaymentComplete(paymentCompleteData);
                    }
                }
            );
        } else {
            handlePaymentComplete({
                merchantUid: payment?.paymentImp?.merchantUid,
                impUid: "",
                success: true,
            });
        }
    };

    //registerRent
    const requestAxiosRegisterRent = async () => {
        const diffDays = moment(values.endDate).diff(values.startDate, "days");
        const response = await utilAxiosWithAuth().post(Request.RENT_ADD_URL, {
            rentCar: values.rentCar,
            user: userDetails?.user,
            startDate: values.startDate,
            endDate: values.endDate,
            rentPaidItems: values.rentPaidItems,
            rentInsurance: values.insurance,
            rentExtraTime: values.extraTime,
            price: values.totalPrice,
            rentPayment: {
                amount: values.totalPrice,
            },
            userCoupon: values.coupon,
            userMultiCoupon: values.multiCoupon,
            rentPayments: [
                {
                    rentPaymentType: Common.RentPaymentType.RENT,
                    paymentMethod: Common.PaymentMethod.ONLINE,
                    amount: values.totalPrice,
                },
                {
                    rentPaymentType: Common.RentPaymentType.INSURANCE,
                    rentPaymentItem: {
                        rentPaymentItemId: Common.INSURANCE_START_RENT_PAYMENT_ITEM_ID + diffDays - 1,
                    },
                    paymentMethod: Common.PaymentMethod.OFFLINE_BANK,
                    amount: Common.INSURANCE_PER_DAY * diffDays,
                    basicPrice: Common.INSURANCE_PER_DAY * diffDays,
                    adjustedPrice: 0,
                },
            ],
        });
        return response.data;
    };

    const {
        loading: loadingRegisterRent,
        error: errorRegisterRent,
        data: resultRegisterRent,
        execute: requestRegisterRent,
    } = useAsyncAxios(requestAxiosRegisterRent, false, true);

    useEffect(() => {
        if (!resultRegisterRent) return;
        console.log("resultRegisterRent", resultRegisterRent);

        requestPreparePayment(
            resultRegisterRent.rent.rentPayments.find((payment: TypeDTO.RentPaymentDto) => payment.rentPaymentType === Common.RentPaymentType.RENT)
        );
    }, [resultRegisterRent]);

    useEffect(() => {
        if (!errorRegisterRent) return;
        console.log("errorRegisterRentCar", errorRegisterRent);

        if (errorRegisterRent.response?.data?.message === "Rent date already exists") {
            popupReservationError("이미 다른 회원이 결제 진행중입니다.");
        } else if (errorRegisterRent.response?.data?.message === "Rent date already paid") {
            popupReservationError("이미 다른 회원이 결제 완료한 일정입니다.");
        } else {
            popupReservationError(errorRegisterRent.response?.data?.message);
        }
    }, [errorRegisterRent]);

    //preparePayment
    const requestAxiosPreparePayment = async (payment: TypeDTO.RentPaymentDto) => {
        const response = await utilAxiosWithAuth().post(Request.RENT_PAYMENT_PREPARE_URL + payment.rentPaymentId);
        return response.data;
    };

    const {
        loading: loadingPreparePayment,
        error: errorPreparePayment,
        data: resultPreparePayment,
        execute: requestPreparePayment,
    } = useAsyncAxios(requestAxiosPreparePayment);

    useEffect(() => {
        if (!resultPreparePayment) return;
        console.log("resultPreparePayment", resultPreparePayment);

        showPaymentWindow(resultPreparePayment.rentPayment);
    }, [resultPreparePayment]);

    useEffect(() => {
        if (!errorPreparePayment) return;
        console.log("errorPreparePaymentCar", errorPreparePayment);
    }, [errorPreparePayment]);

    //paymentComplete
    const requestAxiosPaymentComplete = async (value: TypeDTO.ImpPaymentCompleteDto) => {
        const response = await utilAxiosWithAuth().post(Request.RENT_PAYMENT_COMPLETE_URL, value);
        return response.data;
    };

    const {
        loading: loadingPaymentComplete,
        error: errorPaymentComplete,
        data: resultPaymentComplete,
        execute: requestPaymentComplete,
    } = useAsyncAxios(requestAxiosPaymentComplete);

    useEffect(() => {
        if (!resultPaymentComplete) return;
        console.log("resultPaymentComplete", resultPaymentComplete);

        if (resultPaymentComplete.rentPayment.paidDate) {
            navigate(Common.PAGE_RENT_RESULT + "/" + resultPaymentComplete.rentPayment.rent.rentId);
        } else if (resultPaymentComplete.rentPayment.failedDate) {
            requestCancelRent(resultPaymentComplete.rentPayment.rent.rentId);
            popupPaymentFailed();
        }
    }, [resultPaymentComplete]);

    useEffect(() => {
        if (!errorPaymentComplete) return;
        console.log("errorPaymentCarComplete", errorPaymentComplete);
    }, [errorPaymentComplete]);

    //rentCancel
    const requestAxiosCancelRent = async (rentId: number) => {
        const response = await utilAxiosWithAuth().post(Request.RENT_URL + rentId + Request.RENT_CANCEL_URL);
        return response.data;
    };

    const { loading: loadingCancelRent, error: errorCancelRent, data: resultCancelRent, execute: requestCancelRent } = useAsyncAxios(requestAxiosCancelRent);

    useEffect(() => {
        if (!resultCancelRent) return;
        console.log("resultCancelRent", resultCancelRent);
    }, [resultCancelRent]);

    useEffect(() => {
        if (!errorCancelRent) return;
        console.log("errorRentCarComplete", errorCancelRent);
    }, [errorCancelRent]);

    //rentPeakSeason
    const requestAxiosGetRentPeakSeasons = async () => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_PEAK_SEASONS_URL);
        return response.data;
    };

    const {
        loading: loadingGetRentPeakSeasons,
        error: errorGetRentPeakSeasons,
        data: resultGetRentPeakSeasons,
        execute: requestGetRentPeakSeasons,
    } = useAsyncAxios(requestAxiosGetRentPeakSeasons);

    useEffect(() => {
        if (!resultGetRentPeakSeasons) return;
        console.log("resultGetRentPeakSeasons", resultGetRentPeakSeasons);

        setRentPeakSeasons(resultGetRentPeakSeasons.rentPeakSeasons);
    }, [resultGetRentPeakSeasons]);

    useEffect(() => {
        if (!errorGetRentPeakSeasons) return;

        console.log("errorGetRentPeakSeasons", errorGetRentPeakSeasons);
    }, [errorGetRentPeakSeasons]);

    const failedPopupPayment = (message: string) => {
        Modal.error({
            title: "결제 실패",
            content: message,
            okText: String.confirm,
            onOk() {},
            centered: true,
        });
    };

    const popupPaymentFailed = () => {
        Modal.info({
            title: "결제 취소",
            content: "결제가 취소되었습니다.",
            cancelText: String.confirm,
            onCancel() {},
            centered: true,
        });
    };

    const showBasicpopup = () => {
        Modal.info({
            title: content?.title,
            className: styles.customModal,
            bodyStyle: { maxHeight: "500px", overflowY: "scroll", top: "0" },
            content: <div className="mt-4">{content?.content}</div>,
            onCancel() {},
            centered: true,
        });
    };

    const popupReservationError = (msg: string) => {
        Modal.error({
            title: "예약 알림",
            content: msg,
            cancelText: String.confirm,
            onCancel() {},
            centered: true,
        });
    };

    const onClick = () => {
        requestRegisterRent();
    };

    const handlePaymentComplete = (paymentCompleteData: TypeDTO.ImpPaymentCompleteDto) => {
        requestPaymentComplete(paymentCompleteData);
    };

    const onChange = (list: CheckboxValueType[]) => {
        setCheckedList(list);
        setCheckAll(list.length === plainOptions.length);
    };

    const onChangePayment = (payment: string) => {
        setPaymentType(payment);
    };

    useEffect(() => {
        content && (window.Android || window.webkit ? setIsOpen(true) : showBasicpopup());
    }, [content]);

    return (
        <>
            <MobileView className="reservation mobile space-page">
                {loadingPaymentComplete ? (
                    <Loading />
                ) : (
                    <>
                        <div className="container mt-4 mb-4">
                            <div className="row justify-content-center">
                                <div className="fadeIn text-center">
                                    <h2 className="fs-title">결제 하기</h2>
                                </div>
                            </div>
                        </div>
                        <h5 className="mb-2 fadeIn">예약 상품</h5>
                        <Title searchType={true} info={title} />
                        <Divider className="custom-divider" />
                        <ReservationInfo textType={true} value={values} rentPeakSeasons={rentPeakSeasons} />
                        <Divider className="mt-3 mb-3" />
                        <h5 className="mb-2 fadeIn">대여 금액</h5>
                        <ReservationDetail value={values} rentPeakSeasons={rentPeakSeasons} />
                        <CheckboxGroup className="w-100" value={checkedList} onChange={onChange}>
                            <>
                                <Divider className="custom-divider" />
                                <h5 style={{ marginBottom: 12 }}>예약 취소 및 환불규정</h5>
                                <RefundInfo />
                                <Checkbox className="w-100 custom-checkbox agree b-md text-bold" style={{ marginLeft: "8px" }} value={plainOptions[0]}>
                                    <span style={{ fontSize: 16 }}>{plainOptions[0]}</span>
                                    <span className="text-danger text-bold">*</span>
                                </Checkbox>
                                <Divider className="custom-divider" />
                            </>
                            <>
                                <h5>대여 조건</h5>
                                <Info showCondition={{ show: true }} />
                                <Checkbox className="mt-2 w-100 custom-checkbox agree b-md text-bold" value={plainOptions[1]}>
                                    <span style={{ fontSize: 16 }}>{plainOptions[1]}</span>
                                    <span className="text-danger text-bold">*</span>
                                </Checkbox>
                                <Divider className="custom-divider" />
                            </>
                            <>
                                <h5>위약금 규정</h5>
                                <PenaltyInfo showEtc={{ show: true }} />
                                <Checkbox className="mt-2 w-100 custom-checkbox agree b-md text-bold" value={plainOptions[2]}>
                                    <span style={{ fontSize: 16 }}>{plainOptions[2]}</span>
                                    <span className="text-danger text-bold">*</span>
                                </Checkbox>
                                <Divider className="custom-divider" />
                            </>
                        </CheckboxGroup>
                        <h5>기타</h5>
                        <p className="pb-2">
                            ※ 자연재해(폭설/폭우/태풍 등), 일일 최고기온이 영하일 경우 배터리 효율 및 안전상의 이유로
                            <span className="text-danger"> 캠핑카 이용</span>이 <span className="text-danger">불가</span>할 수 있습니다. 이 경우 예약하신 비용은
                            전액 환불처리 해드립니다.
                        </p>
                        <Divider className="custom-divider" />
                        <h5 style={{ marginBottom: 12 }}>결제 수단</h5>
                        <PaymentSelector onChangePayment={onChangePayment} />
                        <Button className="btn-dark mt-3 mb-5 w-100" type="primary" disabled={!checkAll} onClick={onClick}>
                            {String.pay}
                        </Button>
                        <LayoutFooter type="rent" orderType={true} />
                        <MobileDrawer
                            isOpen={isOpen}
                            title={content?.title || ""}
                            content={<div style={{ padding: "0 1.5rem" }}>{content?.content || ""}</div>}
                            onClose={() => setIsOpen(false)}
                        />
                    </>
                )}
            </MobileView>
            <BrowserView className={`browser`}>
                {loadingPaymentComplete ? (
                    <Loading />
                ) : (
                    <Col sm={{ span: 24 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 16, offset: 4 }} className="p-4">
                        <Row gutter={30} style={{ paddingTop: 15 }}>
                            <Col span={12}>
                                <ImageSlider images={[Utils.getCarImage(values?.rentCar?.car?.carType?.typeName)]} />
                            </Col>
                            <Col span={12}>
                                <Title info={PCtitle} />
                                <Divider style={{ border: "1px solid rgba(0, 0, 0, 0.06)" }} />
                                <h6 className="mb-2 mt-3">{String.reservationInfo}</h6>
                                <ReservationInfo textType={true} value={values} rentPeakSeasons={rentPeakSeasons} />
                                <Divider />
                                <h6 className="mb-2 mt-3">{String.rentalPrice}</h6>
                                <ReservationDetail value={values} rentPeakSeasons={rentPeakSeasons} />
                                <CheckboxGroup className="w-100" value={checkedList} onChange={onChange}>
                                    <>
                                        <Divider className="custom-divider" />
                                        <h6 className="mb-2">예약 취소 및 환불규정</h6>
                                        <RefundInfo />
                                        <Checkbox className="w-100 custom-checkbox agree b-md text-bold" style={{ marginLeft: "8px" }} value={plainOptions[0]}>
                                            {plainOptions[0]}
                                            <span className="text-danger text-bold">*</span>
                                        </Checkbox>
                                        <Divider />
                                    </>
                                    <>
                                        <h6 className="mb-2 mt-3">대여 조건</h6>
                                        <Info showCondition={{ show: true }} />
                                        <Checkbox className="mt-3 w-100 custom-checkbox agree b-md text-bold" value={plainOptions[1]}>
                                            {plainOptions[1]}
                                            <span className="text-danger text-bold">*</span>
                                        </Checkbox>
                                        <Divider />
                                    </>
                                    <>
                                        <h6 className="mb-2 mt-3">위약금 규정</h6>
                                        <PenaltyInfo showEtc={{ show: true }} />
                                        <Checkbox className="mt-3 w-100 custom-checkbox agree b-md text-bold" value={plainOptions[2]}>
                                            {plainOptions[2]}
                                            <span className="text-danger text-bold">*</span>
                                        </Checkbox>
                                        <Divider />
                                    </>
                                </CheckboxGroup>
                                <h6 className="mb-2">기타</h6>
                                <p className="pb-2">
                                    ※ 자연재해(폭설/폭우/태풍 등), 일일 최고기온이 영하일 경우 배터리 효율 및 안전상의 이유로
                                    <span className="text-danger"> 캠핑카 이용</span>이 <span className="text-danger">불가</span>할 수 있습니다. 이 경우
                                    예약하신 비용은 전액 환불처리 해드립니다.
                                </p>
                                <Divider />
                                <h6 style={{ marginBottom: 12 }}>결제 수단</h6>
                                <PaymentSelector onChangePayment={onChangePayment} />
                                <Row className="mt-3 mb-5" style={{ width: "80%", margin: "auto" }} gutter={10}>
                                    <Col span={12}>
                                        <Button className="btn-danger w-100" onClick={() => navigate(-1)}>
                                            {String.cancel}
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button className="btn-dark w-100" type="primary" disabled={!checkAll} onClick={onClick}>
                                            {String.pay}
                                        </Button>
                                    </Col>
                                </Row>
                                <LayoutFooter type="rent" orderType={true} />
                            </Col>
                        </Row>
                    </Col>
                )}
            </BrowserView>
        </>
    );
}

export default Reservation;
