import { Link } from "react-router-dom";

import ReviewThumbnail from "./ReviewThumbnail";

import * as TypeDTO from "../../commons/typeDTO";
import * as Common from "../../commons/common";
import ReviewBadge from "./ReviewBadge";
import ReviewUser from "./ReviewUser";

function ReviewCard({ boardArticle, small, className }: { boardArticle: TypeDTO.BoardArticleDto; small?: boolean; className?: string }) {
    return (
        <div className={`media-card review-card ${className ?? ""}`} style={{ padding: 0, overflow: "hidden" }}>
            {boardArticle.url1 ? (
                <a href={boardArticle.url1} target="_blank" rel="noreferrer">
                    <CardContent boardArticle={boardArticle} small={small} />
                </a>
            ) : (
                <Link
                    to={
                        (boardArticle.board.boardId === 1 ? Common.PAGE_RENT_BEST_REVIEW : Common.PAGE_RENT_USER_REVIEW) +
                        "/" +
                        boardArticle.boardArticleId
                    }
                >
                    <CardContent boardArticle={boardArticle} small={small} />
                </Link>
            )}
        </div>
    );
}

function CardContent({ boardArticle, small }: { boardArticle: TypeDTO.BoardArticleDto; small?: boolean }) {
    return (
        <>
            <div className="thumbnail">
                <ReviewThumbnail boardArticle={boardArticle} />
            </div>
            <div className="summary">
                {small ? (
                    <>
                        <div className="content">{boardArticle.title}</div>
                        <div className="info">
                            <div className="info-item">
                                <ReviewBadge boardArticle={boardArticle} />
                            </div>
                            <div className="info-item">
                                <ReviewUser boardArticle={boardArticle} />
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="info">
                            <div className="info-item">
                                <ReviewBadge boardArticle={boardArticle} />
                            </div>
                            <div className="info-item">
                                <ReviewUser boardArticle={boardArticle} />
                            </div>
                        </div>
                        <div className="content">{boardArticle.title}</div>
                    </>
                )}
            </div>
        </>
    );
}

export default ReviewCard;
