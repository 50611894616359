import React, { useCallback, useEffect, useState } from "react";
import { Col, Tabs, Modal } from "antd";
import { useAsyncAxios, utilAxiosWithAuth } from "../../utils/customAxios";
import type { TabsProps } from "antd";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as Common from "../../commons/common";
import * as Utils from "../../utils/utils";

import MyOrderCard from "../../component/ruta40/MyOrderCard";
import NoData from "../../component/NoData";

declare const window: typeof globalThis & {
    IMP: any;
};

function MyOrder() {
    const [myAllOrders, setMyAllOrders] = useState<Array<TypeDTO.OrderDto>>();
    const [myCurrentOrders, setMyCurrentOrders] = useState<Array<TypeDTO.OrderDto>>();
    const [myOldOrders, setMyOldOrders] = useState<Array<TypeDTO.OrderDto>>();

    const requestAxiosGetMyOrder = async (model: number) => {
        const response = await utilAxiosWithAuth().get(Request.ORDER_MYORDER_URL);
        return response.data;
    };

    const {
        loading: loadingGetMyOrder,
        error: errorGetMyOrder,
        data: resultGetMyOrder,
        execute: requestGetMyOrder,
    } = useAsyncAxios(requestAxiosGetMyOrder);

    useEffect(() => {
        if (!resultGetMyOrder) return;
        console.log("resultGetMyOrder", resultGetMyOrder);

        setMyAllOrders(resultGetMyOrder.orders);
    }, [resultGetMyOrder]);

    useEffect(() => {
        if (!errorGetMyOrder) return;
    }, [errorGetMyOrder]);

    useEffect(() => {
        if (!myAllOrders) return;

        setMyCurrentOrders(myAllOrders.filter((order) => order.canceledDate === null));
        setMyOldOrders(myAllOrders.filter((order) => order.canceledDate !== null));
    }, [myAllOrders]);

    const requestAxiosCancelOrder = async (orderId: number) => {
        const response = await utilAxiosWithAuth().post(Request.ORDER_CANCEL_URL + "/" + orderId);
        return response.data;
    };

    const {
        loading: loadingCancelOrder,
        error: errorCancelOrder,
        data: resultCancelOrder,
        execute: requestCancelOrder,
    } = useAsyncAxios(requestAxiosCancelOrder);

    useEffect(() => {
        if (resultCancelOrder === null) return;
        console.log("resulCancelOrder", resultCancelOrder);
        requestGetMyOrder();
    }, [resultCancelOrder]);

    useEffect(() => {
        if (errorCancelOrder === null) return;

        console.log("errorCancelOrder", errorCancelOrder);
    }, [errorCancelOrder]);

    useEffect(() => {
        requestGetMyOrder();
    }, []);

    const requestAxiosCancelPayment = async (payment: TypeDTO.PaymentDto) => {
        const response = await utilAxiosWithAuth().post(Request.PAYMENT_CANCEL_URL + "/" + payment.paymentId);
        return response.data;
    };

    const {
        loading: loadingCancelPayment,
        error: errorCancelPayment,
        data: resultCancelPayment,
        execute: requestCancelPayment,
    } = useAsyncAxios(requestAxiosCancelPayment);

    useEffect(() => {
        if (!resultCancelPayment) return;
        console.log("resultCancelPayment", resultCancelPayment);
        if (resultCancelPayment.payment.canceledDate) {
            successPopupCancelPayment();
            requestCancelOrder(resultCancelPayment.payment.order.orderId);
        } else {
            errorPopupCancelPayment();
        }
    }, [resultCancelPayment]);

    useEffect(() => {
        if (!errorCancelPayment) return;

        console.log("errorCancelPayment", errorCancelPayment);
    }, [errorCancelPayment]);
    ///////////////////////////////////////////////////////////////////////////////////////////////

    const popupPaymentSuccess = () => {
        Modal.info({
            title: "결제 완료",
            content: "결제가 완료되었습니다.",
            cancelText: "닫기",
            onCancel() {},
            centered: true,
        });
    };

    const popupPaymentFailed = () => {
        Modal.info({
            title: "결제 취소",
            content: "결제가 취소되었습니다.",
            cancelText: "닫기",
            onCancel() {},
            centered: true,
        });
    };

    const successPopupCancelPayment = () => {
        Modal.info({
            title: "취소 완료",
            content: "주문이 취소되었습니다.",
            okText: "확인",
            onOk() {},
            centered: true,
        });
    };

    const errorPopupCancelPayment = () => {
        Modal.error({
            title: "결제 취소",
            content: "결제가 취소되지 않았습니다.\n다시 시도해 보시고 지속적으로 발생 할 경우\n고객센터에 문의해 주세요.",
            okText: "확인",
            onOk() {},
            centered: true,
        });
    };

    const isPossibleCancel = (order: TypeDTO.OrderDto) => {
        const allPayments = order.payments;
        if (allPayments === undefined) return true;

        const depositPayment = Utils.getPaymentByPaymentType(Common.PaymentType.DEPOSIT, allPayments);
        if (depositPayment !== null && depositPayment.paidDate && depositPayment.paymentMethod !== "ONLINE") {
            return false;
        }

        if (Utils.isPaidByPaymentType(Common.PaymentType.DOWN_PAYMENT, allPayments) === true) {
            return false;
        }

        return true;
    };

    const checkRefundPayment = (order: TypeDTO.OrderDto) => {
        let payments: Array<TypeDTO.PaymentDto> = [];
        if (order.payments !== undefined)
            payments = order.payments.filter((payment) => payment.paidDate && payment.canceledDate === null && payment.failedDate === null);
        return payments;
    };

    const cancelOrder = (order: TypeDTO.OrderDto) => {
        const possibleCancel = isPossibleCancel(order);
        if (possibleCancel) {
            const refundPayments = checkRefundPayment(order);
            if (refundPayments.length > 0) {
                // TODO: refund
                requestCancelPayment(refundPayments[0]);
            } else {
                requestCancelOrder(order.orderId);
            }
        } else {
            failedPopupOrderCancel();
        }
    };

    const failedPopupOrderCancel = () => {
        Modal.error({
            title: "주문취소 실패",
            content: "이미 결제된 금액을 고객센터에 문의하여 환불 후 주문취소 해 주세요.",
            okText: "확인",
            onOk() {},
            centered: true,
        });
    };

    const DisplayOrders = useCallback(() => {
        const activePanel: number[] = [];

        if (myCurrentOrders && myCurrentOrders.length > 0) {
            myCurrentOrders[0].orderId && activePanel.push(myCurrentOrders[0].orderId);
        }

        return (
            <>
                {myCurrentOrders && myCurrentOrders.length > 0 ? <h6 className="mt-3 mb-4">총 주문 내역 : {myCurrentOrders.length} 건</h6> : null}

                {myCurrentOrders &&
                    myCurrentOrders.map((order) => {
                        return (
                            <MyOrderCard
                                key={order.orderId}
                                order={order}
                                activePanel={activePanel}
                                onCancelOrder={cancelOrder}
                                onCancelPayment={(payment) => requestCancelPayment(payment)}
                            />
                        );
                    })}
                {!myCurrentOrders || myCurrentOrders.length === 0 ? <NoData msg="주문 내역이 없습니다" /> : null}
            </>
        );
    }, [myCurrentOrders]);

    const DisplayOldOrders = useCallback(() => {
        return (
            <>
                {myOldOrders && myOldOrders.length > 0 ? <h6 className="mt-3 mb-4">총 취소 내역 : {myOldOrders.length} 건</h6> : null}

                {myOldOrders &&
                    myOldOrders.map((order) => {
                        return (
                            <MyOrderCard
                                key={order.orderId}
                                order={order}
                                onCancelOrder={cancelOrder}
                                onCancelPayment={(payment) => requestCancelPayment(payment)}
                            />
                        );
                    })}
                {!myOldOrders || myOldOrders.length === 0 ? <NoData msg="주문 내역이 없습니다" /> : null}
            </>
        );
    }, [myOldOrders]);

    const items: TabsProps["items"] = [
        {
            key: "1",
            label: "주문 내역",
            children: <DisplayOrders />,
        },
        {
            key: "2",
            label: "취소 내역",
            children: (
                <>
                    <DisplayOldOrders />
                </>
            ),
        },
    ];

    return (
        <>
            <div className="header-container">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="fadeIn text-center">
                            <h2 className="fs-title">주문 내역</h2>
                        </div>
                    </div>
                </div>
            </div>
            <Col
                xs={{ span: 24 }}
                sm={{ span: 22, offset: 1 }}
                lg={{ span: 18, offset: 3 }}
                xl={{ span: 16, offset: 4 }}
                xxl={{ span: 14, offset: 5 }}
                className="mb-4 p-4"
            >
                <Tabs className="order-tabs" defaultActiveKey="1" items={items} />
            </Col>
        </>
    );
}

export default MyOrder;
