import * as TypeDTO from "../../commons/typeDTO";

import IconUser from "../../assets/images/home/rent/icon_user.svg";

function ReviewUser({ boardArticle }: { boardArticle: TypeDTO.BoardArticleDto }) {
    return (
        <>
            <span>{boardArticle.extraInfo3?.replace(/(?<=.{1})./gi, "*").substring(0, 8)}님</span>
        </>
    );
}

export default ReviewUser;
