import { LockOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React, { useState } from "react";

import * as String from "../commons/string";

function InputPassword({
    value,
    type,
    disabled,
    onChange,
    ...props
}: {
    value?: string;
    type?: string;
    disabled?: boolean;
    onChange?: (value: string) => void;
}) {
    const [isCapsLock, setIsCapsLock] = useState(false);

    function onKeyDownHandler(e: any) {
        const checkCapsLock = e.getModifierState("CapsLock");
        setIsCapsLock(checkCapsLock);
    }

    return (
        <>
            <Input.Password
                className="login-input"
                disabled={disabled}
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder={type === "confirm" ? "비밀번호 확인" : String.password}
                maxLength={20}
                onChange={(e) => {
                    if (onChange !== undefined) onChange(e.target.value);
                }}
                onClick={onKeyDownHandler}
                onKeyDown={onKeyDownHandler}
                {...props}
            />
            {isCapsLock && (
                <span className="capsLock" style={{ color: "#2871E6" }}>
                    Caps Lock이 켜져 있습니다.
                </span>
            )}
        </>
    );
}

export default InputPassword;
