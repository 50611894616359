import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";
import { Row, Col, Button, Modal } from "antd";
import { NumericFormat } from "react-number-format";
import { useMediaQuery } from "react-responsive";

import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as Common from "../../commons/common";

import OrderSelect from "../../component/OrderSelect";
import AgreementSubsidy from "../../component/AgreementSubsidy";
import GrantGuidance from "../../component/GrantGuidance";
import Gallery from "../../component/Gallery";
import styles from "./Order.module.css";
import LayoutFooter from "../../component/LayoutFooter";

import bayrunTypeExterior1 from "../../assets/images/model/bayrun/BayrunType-exterior-1.jpg";
import bayrunTypeExterior2 from "../../assets/images/model/bayrun/BayrunType-exterior-2.jpg";
import bayrunTypeExterior3 from "../../assets/images/model/bayrun/BayrunType-exterior-3.jpg";

import bayrun3TypeInterior1 from "../../assets/images/model/bayrun/BayrunType-interior-1.jpg";
import bayrun3TypeInterior2 from "../../assets/images/model/bayrun/BayrunType-interior-3.jpg";

import bayrun4TypeInterior1 from "../../assets/images/model/bayrun/BayrunType-interior-9.jpg";
import bayrun4TypeInterior2 from "../../assets/images/model/bayrun/BayrunType-interior-10.jpg";

import redmaxTypeExterior1 from "../../assets/images/model/redmax/Redmax-exterior-1.jpg";
import redmaxTypeExterior2 from "../../assets/images/model/redmax/Redmax-exterior-2.jpg";
import redmaxTypeExterior3 from "../../assets/images/model/redmax/Redmax-exterior-3.jpg";
import redmaxTypeInterior1 from "../../assets/images/model/redmax/Redmax-interior-1.jpg";
import redmaxTypeInterior2 from "../../assets/images/model/redmax/Redmax-interior-2.jpg";

declare const window: typeof globalThis & {
    IMP: any;
};

function Order() {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const isMobile = useMediaQuery({ maxWidth: "765px" });

    const [carTypes, setCarTypes] = useState<Array<TypeDTO.CarTypeDto>>();
    const [orderData, setOrderData] = useState<TypeDTO.OrderDto | undefined>();
    const [selectedCarType, setSelectedCarType] = useState<TypeDTO.CarTypeDto>();

    const [modelId, setModelId] = useState<number>();
    const [typeId, setTypeId] = useState<number>();
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [agreementSubsidy, setAgreementSubsidy] = useState(false);

    useEffect(() => {
        setModelId(Number(params.modelId));
    }, [params]);

    useEffect(() => {
        const state = location.state;
        if (state) {
            setTypeId(state);
        }
    }, [location.state]);

    useEffect(() => {
        if (modelId) {
            requestGetCarType(modelId);
        }
    }, [modelId]);

    const requestAxiosGetCarType = async (modelId: number) => {
        const response = await utilAxiosWithAppToken().get(Request.CAR_TYPE_LIST_URL, {
            params: {
                modelId: modelId,
            },
        });
        return response.data;
    };

    const {
        loading: loadingCarType,
        error: errorGetCarType,
        data: resultGetCarType,
        execute: requestGetCarType,
    } = useAsyncAxios(requestAxiosGetCarType);

    useEffect(() => {
        if (!resultGetCarType) return;
        console.log("resultGetCarType", resultGetCarType);

        if (resultGetCarType.carTypes) {
            setCarTypes(resultGetCarType.carTypes.filter((type: TypeDTO.CarTypeDto) => type.unavailable !== true));
        }
    }, [resultGetCarType]);

    useEffect(() => {
        if (!errorGetCarType) return;
        console.log("errorCarType", errorGetCarType);
    }, [errorGetCarType]);

    const onChangeOrderSelect = (orderData: TypeDTO.OrderDto) => {
        if (orderData.carType) {
            setSelectedCarType(orderData.carType);
        }
        setOrderData(orderData);

        setTotalPrice(
            (orderData?.totalPrice || 0) -
                (orderData?.localSubsidy ? (orderData?.localSubsidy?.price || 0) + (orderData?.nationalSubsidy?.price || 0) : 0)
        );
    };

    const handleNextStep = () => {
        if (orderData?.localSubsidy) {
            sessionStorage.setItem(Common.SESSION_ORDER, JSON.stringify(orderData));
            navigate(Common.PAGE_PAYMENT);
        } else {
            errorNoSiGunGuSubsiby();
        }
    };

    const errorNoSiGunGuSubsiby = () => {
        Modal.info({
            title: "전기차 보조금 신청 지역",
            content: "전기차 보조금 신청 지역을 설정하지 않았습니다.",
            okText: "확인",
            onOk() {},
            centered: true,
        });
    };

    return (
        <div className={styles.body}>
            {isMobile ? (
                <>
                    <div className={styles.mobileScreen}>
                        <Row gutter={[30, 30]}>
                            <Col className="orderImage" span={24}>
                                <Gallery
                                    images={
                                        selectedCarType?.typeName.includes("배이런(3인승)")
                                            ? [
                                                  bayrunTypeExterior1,
                                                  bayrunTypeExterior2,
                                                  bayrunTypeExterior3,
                                                  bayrun3TypeInterior1,
                                                  bayrun3TypeInterior2,
                                              ]
                                            : selectedCarType?.typeName.includes("배이런(4인승)")
                                            ? [
                                                  bayrunTypeExterior1,
                                                  bayrunTypeExterior2,
                                                  bayrunTypeExterior3,
                                                  bayrun4TypeInterior1,
                                                  bayrun4TypeInterior2,
                                              ]
                                            : selectedCarType?.typeName.includes("레드맥스")
                                            ? [
                                                  redmaxTypeExterior1,
                                                  redmaxTypeExterior2,
                                                  redmaxTypeExterior3,
                                                  redmaxTypeInterior1,
                                                  redmaxTypeInterior2,
                                              ]
                                            : []
                                    }
                                />
                                <div className={styles.underBody}>
                                    <OrderSelect carTypes={carTypes} carTypeId={typeId} onChangeOrder={onChangeOrderSelect} />
                                    <GrantGuidance />
                                    <AgreementSubsidy agreement={agreementSubsidy} onChangeAgreement={(checked) => setAgreementSubsidy(checked)} />
                                    <div className="text-center mt-4" style={{ marginBottom: "100px" }}>
                                        <Button className="btn-confirm w-100" disabled={!agreementSubsidy} onClick={handleNextStep}>
                                            다음
                                        </Button>
                                    </div>
                                    <LayoutFooter orderType={true} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    {/* <Row className={styles.cardMobile} justify="space-between">
                        <Col>예상 주문 금액</Col>
                        <Col className={styles.textPrice}>
                            <NumericFormat value={totalPrice} thousandSeparator={true} prefix={"₩"} displayType="text" />
                        </Col>
                    </Row> */}
                </>
            ) : (
                <>
                    <Row gutter={[30, 30]} className="orderBoxWrap">
                        <Col className="orderImage" sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 14 }}>
                            <Gallery
                                images={
                                    selectedCarType?.typeName.includes("배이런(3인승)")
                                        ? [bayrunTypeExterior1, bayrunTypeExterior2, bayrunTypeExterior3, bayrun3TypeInterior1, bayrun3TypeInterior2]
                                        : selectedCarType?.typeName.includes("배이런(4인승)")
                                        ? [bayrunTypeExterior1, bayrunTypeExterior2, bayrunTypeExterior3, bayrun4TypeInterior1, bayrun4TypeInterior2]
                                        : selectedCarType?.typeName.includes("레드맥스")
                                        ? [redmaxTypeExterior1, redmaxTypeExterior2, redmaxTypeExterior3, redmaxTypeInterior1, redmaxTypeInterior2]
                                        : []
                                }
                            />
                            {/* <Row className={styles.card} justify="space-between">
                                <Col>예상 주문 금액</Col>
                                <Col className={styles.textPrice}>
                                    <NumericFormat value={totalPrice} thousandSeparator={true} prefix={"₩"} displayType="text" />
                                </Col>
                            </Row> */}
                        </Col>
                        <Col className="orderBox pe-4" sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 10 }}>
                            <div className={styles.rightBody}>
                                <OrderSelect carTypes={carTypes} carTypeId={typeId} onChangeOrder={onChangeOrderSelect} />
                                <GrantGuidance />
                                <AgreementSubsidy agreement={agreementSubsidy} onChangeAgreement={(checked) => setAgreementSubsidy(checked)} />
                                <div className="text-center mt-4" style={{ marginBottom: "100px" }}>
                                    <Button className="btn-confirm" disabled={!agreementSubsidy} onClick={handleNextStep}>
                                        다음
                                    </Button>
                                </div>
                                <LayoutFooter orderType={true} />
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
}

export default Order;
