/* 캠핑카 */
import bayrunBath from "../assets/images/home/ruta40/option/option_bayrun_bath.jpg";
import bayrunRentBath from "../assets/images/home/ruta40/option/option_bayrun_rent_bath.jpg";
import bayrunMainBed from "../assets/images/home/ruta40/option/option_bayrun_mainBed.jpg";
import bayrun4MainBed from "../assets/images/home/ruta40/option/option_bayrun4_mainBed.jpg";
import bayrun3SubBed from "../assets/images/home/ruta40/option/option_bayrun3_subBed.jpg";
import bayrun4SubBed from "../assets/images/home/ruta40/option/option_bayrun4_subBed.jpg";
import bayrun4PurchaseSubBed from "../assets/images/home/ruta40/option/option_bayrun4_purchase_subBed.jpg";
import bayrunIot from "../assets/images/home/ruta40/option/option_bayrun_iot.jpg";
import bayrunMaxpan from "../assets/images/home/ruta40/option/option_bayrun_maxpan.jpg";
import bayrunController from "../assets/images/home/ruta40/option/option_bayrun_controller.jpg";
import bayrunSeat from "../assets/images/home/ruta40/option/option_bayrun_seat.jpg";
import bayrunTable from "../assets/images/home/ruta40/option/option_bayrun_table.jpg";
import bayrun4Table from "../assets/images/home/ruta40/option/option_bayrun4_table.jpg";
import bayrunV2L from "../assets/images/home/ruta40/option/option_bayrun_v2l.jpg";
import bayrunWindow from "../assets/images/home/ruta40/option/option_bayrun_window.jpg";
import bayrunAwning from "../assets/images/home/ruta40/option/option_bayrun_awning.jpg";
import bayrunAirConditioning from "../assets/images/home/ruta40/option/option_bayrun_air_conditining.jpg";
import bayrunAirConditioningDefault from "../assets/images/home/ruta40/option/option_bayrun_air_conditining_default.jpg";
import bayrun4AirConditioner from "../assets/images/home/ruta40/option/option_bayrun4_air_conditioner.jpg";

import bayrun3Microwave from "../assets/images/home/ruta40/option/option_bayrun3_microwave.jpg";
import bayrun3Refrigerator from "../assets/images/home/ruta40/option/option_bayrun3_refrigerator.jpg";
import bayrun3Sinkball from "../assets/images/home/ruta40/option/option_bayrun3_sinkball.jpg";
import bayrun3Monitor from "../assets/images/home/ruta40/option/option_bayrun3_monitor.jpg";

import bayrun4Freezer from "../assets/images/home/ruta40/option/option_bayrun4_freezer.jpg";
import bayrun4purchaseMicrowave from "../assets/images/home/ruta40/option/option_bayrun4_purchase_microwave.jpg";
import bayrun4Refrigerator from "../assets/images/home/ruta40/option/option_bayrun4_refrigerator.jpg";
import bayrun4Sinkball from "../assets/images/home/ruta40/option/option_bayrun4_sinkball.jpg";
import bayrun4purchaseSinkball from "../assets/images/home/ruta40/option/option_bayrun4_purchase_sinkball.jpg";
import bayrun4Controller from "../assets/images/home/ruta40/option/option_bayrun4_controller.jpg";
import bayrun4Monitor from "../assets/images/home/ruta40/option/option_bayrun4_monitor.jpg";

import sTypeBed from "../assets/images/home/ruta40/option/option_sType_sofaBed.jpg";
import sTypeTable from "../assets/images/home/ruta40/option/option_sType_table.jpg";
import sTypeSinkball from "../assets/images/home/ruta40/option/option_sType_sinkball.jpg";
import sTypeBath from "../assets/images/home/ruta40/option/option_sType_bath.jpg";

import redmaxSeat from "../assets/images/home/ruta40/option/option_redmax_seat.jpg";
import redmaxMainBed from "../assets/images/home/ruta40/option/option_redmax_mainbed.jpg";
import redmaxTable from "../assets/images/home/ruta40/option/option_redmax_table.jpg";
import redmaxBedTable from "../assets/images/home/ruta40/option/option_redmax_bedtable.jpg";
import redmaxWindow from "../assets/images/home/ruta40/option/option_redmax_window.jpg";

import redmaxController from "../assets/images/home/ruta40/option/option_redmax_controller.jpg";
import redmaxAirConditioningDefault from "../assets/images/home/ruta40/option/option_redmax_air_conditining_default.jpg";

import redmaxMicrowave from "../assets/images/home/ruta40/option/option_redmax_microwave.jpg";
import redmaxSinkball from "../assets/images/home/ruta40/option/option_redmax_sinkball.jpg";
import redmaxBath from "../assets/images/home/ruta40/option/option_redmax_bath.jpg";
import redmaxMonitor from "../assets/images/home/ruta40/option/option_redmax_monitor.jpg";
import redmaxRefrigerator from "../assets/images/home/ruta40/option/option_redmax_refrigerator.jpg";
import redmaxMaxpan from "../assets/images/home/ruta40/option/option_redmax_maxpan.jpg";

export const bayrunElectricity = [
    {
        position: [{ x: "83%", y: "62%" }],
        content: {
            tooltip: "V2L 시스템",
            title: "V2L (Vehicle to Load) 시스템 적용",
            description: ["차량 배터리 106kW (캠핑카 인산철배터리 기준 : 8,833A) + 5.6kW 인버터"],
        },
        image: bayrunV2L,
    },
];

export const bayrun3AirConditioning = [
    {
        position: undefined,
        content: { title: "단열", description: ["액상단열제 Isollat 시공"] },
        image: undefined,
    },
    {
        position: [{ x: "44%", y: "58%" }],
        content: {
            tooltip: "4way 시스템 난방",
            title: "4way 시스템 난방",
            description: ["에바스패커 D5 무시동 히터, ", "Dual 핀코일 (전면/후면 개별 난방 - 온풍 6POINT), ", "온수 + 바닥난방 (화장실 + 거실)"],
        },
        image: bayrunController,
    },
    {
        position: [{ x: "62%", y: "39%" }],
        content: {
            tooltip: "천장형 냉난방",
            title: "천장형 냉난방",
            description: ["아이즈원 루프 에어컨/히터"],
        },
        image: bayrunAirConditioning,
    },
    {
        position: [{ x: "22%", y: "42%" }],
        content: { tooltip: "차량 순정 에어컨/히터", title: "차량 순정 에어컨/히터", description: ["-"] },
        image: bayrunAirConditioningDefault,
    },
];

export const bayrun4AirConditioning = [
    {
        position: undefined,
        content: { title: "단열", description: ["액상단열제 Isollat 시공"] },
        image: undefined,
    },

    {
        position: [{ x: "44%", y: "58%" }],
        content: {
            tooltip: "4way 시스템 난방",
            title: "4way 시스템 난방",
            description: ["에바스패커 D5 무시동 히터, ", "Dual 핀코일 (전면/후면 개별 난방 - 온풍 6POINT), ", "온수 + 바닥난방 (화장실 + 거실)"],
        },
        image: bayrun4Controller,
    },
    {
        position: [{ x: "66%", y: "39%" }],
        content: {
            tooltip: "천장형 냉난방",
            title: "천장형 냉난방",
            description: ["아이즈원 루프 에어컨/히터"],
        },
        image: bayrun4AirConditioner,
    },
    {
        position: [{ x: "22%", y: "42%" }],
        content: { tooltip: "차량 순정 에어컨/히터", title: "차량 순정 에어컨/히터", description: ["-"] },
        image: bayrunAirConditioningDefault,
    },
];

export const redmaxAirConditioning = [
    {
        position: undefined,
        content: { title: "단열", description: ["약 8cm의 5중 단열 시공"] },
        image: undefined,
    },

    {
        position: [{ x: "85%", y: "12%" }],
        content: {
            tooltip: "난방",
            title: "난방",
            description: ["MD홍 무시동 히터 5kW(공기 히터+바닥 난방, 디젤25L)"],
        },
        image: redmaxController,
    },
    {
        position: [{ x: "60%", y: "40%" }],
        content: { tooltip: "냉방", title: "냉방", description: ["3way 냉장(도메틱 천정형 에어컨+차량 전면/후면 순정 에어컨)"] },
        image: redmaxAirConditioningDefault,
    },
];

export const bayrun3Appliances = [
    {
        position: [{ x: "72%", y: "23%" }],
        content: { title: "전자렌지(무회전식)", description: ["20L"] },
        image: bayrun3Microwave,
    },
    {
        position: [{ x: "51%", y: "20%" }],
        content: { tooltip: "싱크볼", title: "다목적 대형 싱크볼", description: ["-"] },
        image: bayrun3Sinkball,
    },
    {
        position: [{ x: "66%", y: "60%" }],
        content: {
            tooltip: "샤워실",
            title: "샤워실",
            description: ["C400 고정식 변기, ", "샤워기, ", "화장실 뎀퍼 도어, ", "화장실 환기창"],
        },
        image: bayrunBath,
    },
    {
        position: [{ x: "84%", y: "66%" }],
        content: { tooltip: "TV", title: "삼성 스마트 TV", description: ['27"'] },
        image: bayrun3Monitor,
    },
    { content: { title: "청수통/오수통", description: ["100L/80L"] } },
    { content: { title: "청수/오수 자동/수동 드레인", description: ["-"] } },
    {
        position: [{ x: "62%", y: "20%" }],
        content: { tooltip: "냉장고", title: "플루캠프 65L DC냉장고", description: ["냉장고 후면 발열방지 벤트 설치"] },
        image: bayrun3Refrigerator,
    },
    { position: [{ x: "48%", y: "39%" }], content: { title: "맥스팬 환기 시스템", description: ["-"] }, image: bayrunMaxpan },
];

export const bayrun4Appliances = [
    {
        position: [{ x: "71.5%", y: "23%" }],
        content: { title: "전자렌지(무회전식)", description: ["20L"] },
        image: bayrun4purchaseMicrowave,
    },
    {
        position: [{ x: "63%", y: "21%" }],
        content: { tooltip: "싱크볼", title: "다목적 대형 싱크볼", description: ["-"] },
        image: bayrun4purchaseSinkball,
    },
    {
        position: [{ x: "66%", y: "60%" }],
        content: {
            tooltip: "샤워실",
            title: "샤워실",
            description: ["C400 고정식 변기, ", "샤워기, ", "화장실 뎀퍼 도어, ", "화장실 환기창"],
        },
        image: bayrunBath,
    },
    {
        position: [{ x: "78%", y: "55%" }],
        content: { tooltip: "TV", title: "삼성 스마트 TV", description: ['27"'] },
        image: bayrun4Monitor,
    },
    { position: undefined, content: { title: "청수통/오수통", description: ["100L/80L"] }, image: undefined },
    { content: { title: "청수/오수 자동/수동 드레인", description: ["-"] } },
    {
        position: [{ x: "79%", y: "39%" }],
        content: { tooltip: "냉장고", title: "플루캠프 65L DC냉장고", description: ["냉장고 후면 발열방지 벤트 설치"] },
        image: bayrun4Refrigerator,
    },
    { position: [{ x: "48%", y: "39%" }], content: { title: "맥스팬 환기 시스템", description: ["-"] }, image: bayrunMaxpan },
];

export const bayrun4RentAppliances = [
    {
        position: [{ x: "85%", y: "40%" }],
        content: {
            tooltip: "메인침대",
            title: "메인침대",
            description: ["1200X1760"],
        },
        image: bayrunMainBed,
    },
    {
        position: [{ x: "49%", y: "43%" }],
        content: {
            tooltip: "변환침대",
            title: "변환침대 ",
            description: ["1150X1700"],
        },
        image: bayrun4SubBed,
    },
    {
        position: [{ x: "63%", y: "21%" }],
        content: { tooltip: "싱크볼", title: "다목적 대형 싱크볼", description: ["펌핑형 세제 용기 & 컵 전용 수전"] },
        image: bayrun4Sinkball,
    },
    {
        position: [{ x: "66%", y: "60%" }],
        content: { tooltip: "샤워실", title: "샤워실", description: ["샤워기(변기 없음)"] },
        image: bayrunRentBath,
    },
    {
        position: undefined,
        content: { title: "에바스패커 D5 무시동 히터", description: ["바닥난방, 공기난방, 온수"] },
        image: undefined,
    },
    {
        position: undefined,
        content: { title: "TV", description: ["삼성 스마트 모니터 27인치"] },
        image: undefined,
    },
    {
        position: undefined,
        content: { title: "전자렌지", description: ["20L"] },
        image: undefined,
    },
    {
        position: undefined,
        content: { title: "냉장고", description: ["110L"] },
        image: undefined,
    },
    {
        position: undefined,
        content: { title: "냉동고", description: ["40L"] },
        image: undefined,
    },
    {
        position: undefined,
        content: { title: "천장형 에어컨", description: ["냉/난방 겸용"] },
        image: undefined,
    },
    {
        position: undefined,
        content: { title: "환기팬", description: ["맥스팬 환기 시스템"] },
        image: undefined,
    },
    {
        position: undefined,
        content: { title: "청수통/오수통(자동 퇴수)", description: ["120L/80L"] },
        image: undefined,
    },
    {
        position: [{ x: "70%", y: "5%" }],
        content: { tooltip: "어닝", title: "3.5미터 루프어닝", description: ["수동"] },
        image: bayrunAwning,
    },
];

export const STypeAppliances = [
    {
        position: [{ x: "84%", y: "40%" }],
        content: { tooltip: "침대", title: "테이블 변환 침대", description: ["1700X1900"] },
        image: sTypeBed,
    },
    {
        position: [{ x: "70%", y: "40%" }],
        content: { tooltip: "테이블", title: "테이블", description: ["-"] },
        image: sTypeTable,
    },
    {
        position: [{ x: "44%", y: "30%" }],
        content: { tooltip: "싱크볼", title: "싱크볼", description: ["-"] },
        image: sTypeSinkball,
    },
    {
        position: [{ x: "44%", y: "55%" }],
        content: {
            tooltip: "샤워기",
            title: "샤워기",
            description: ["-"],
        },
        image: sTypeBath,
    },
    {
        position: undefined,
        content: { title: "3.5미터 루프어닝", description: ["-"] },
        image: undefined,
    },
    {
        position: undefined,
        content: { title: "무시동 히터", description: ["D2 무시동 히터"] },
        image: undefined,
    },
    {
        position: undefined,
        content: { title: "TV", description: ["삼성 스마트 TV 27인치"] },
        image: undefined,
    },
    {
        position: undefined,
        content: { title: "전자렌지", description: ["20L"] },
        image: undefined,
    },
    {
        position: undefined,
        content: { title: "냉장고", description: ["도메틱 110L"] },
        image: undefined,
    },
    {
        position: undefined,
        content: { title: "냉동고", description: ["40L"] },
        image: undefined,
    },
    {
        position: undefined,
        content: { title: "에어컨", description: ["아이즈원 루프 에어컨"] },
        image: undefined,
    },
    {
        position: undefined,
        content: { title: "환기팬", description: ["멕스팬 환기 시스템"] },
        image: undefined,
    },
    {
        position: undefined,
        content: { title: "청수통/오수통(자동 퇴수)", description: ["120L/80L"] },
        image: undefined,
    },
];

export const redmaxAppliances = [
    {
        position: [{ x: "45%", y: "68%" }],
        content: { title: "전자렌지(무회전식)", description: ["20L"] },
        image: redmaxMicrowave,
    },
    {
        position: [{ x: "57%", y: "21%" }],
        content: { tooltip: "싱크볼", title: "다목적 싱크볼", description: ["-"] },
        image: redmaxSinkball,
    },
    {
        position: [{ x: "56%", y: "60%" }],
        content: {
            tooltip: "샤워실",
            title: "샤워실",
            description: ["800x700mm, ", "샤워기, ", "템버도어, ", "LED 미니 맥스팬 장착"],
        },
        image: redmaxBath,
    },
    {
        position: [{ x: "65%", y: "60%" }],
        content: { tooltip: "TV", title: "삼성 스마트 TV", description: ['27"'] },
        image: redmaxMonitor,
    },
    { content: { title: "청수통/오수통", description: ["150L / 70L"] } },
    { content: { title: "청수 수동 드레인", description: ["-"] } },
    { content: { title: "오수 수동/자동 드레인", description: ["-"] } },
    {
        position: [{ x: "45%", y: "58%" }],
        content: { tooltip: "냉장고", title: "플루캠프 65L DC냉장고", description: ["-"] },
        image: redmaxRefrigerator,
    },
    { position: [{ x: "48%", y: "39%" }], content: { tooltip: "맥스팬", title: "맥스팬 환기 시스템", description: ["-"] }, image: redmaxMaxpan },
];

export const bayrun3Equipment = [
    {
        position: [{ x: "32%", y: "61%" }],
        content: { tooltip: "1열 시트", title: "1열 시트", description: ["스위블 적용"] },
        image: bayrunSeat,
    },
    {
        position: [{ x: "47%", y: "52%" }],
        content: {
            tooltip: "2열 시트(변환침대)",
            title: "2열 시트(변환침대)",
            description: ["1150X900, ", "침상 변환 시 에어매트 적용 - 전동 에어펌프 포함"],
        },
        image: bayrun3SubBed,
    },
    {
        position: [{ x: "80%", y: "40%" }],
        content: {
            tooltip: "메인침대",
            title: "메인침대",
            description: ["1250X1850"],
        },
        image: bayrunMainBed,
    },
    { position: [{ x: "43%", y: "66%" }], content: { tooltip: "식탁", title: "식탁", description: ["탈착식 변환 식탁"] }, image: bayrunTable },
    {
        position: [{ x: "78%", y: "5%" }],
        content: {
            tooltip: "창문",
            title: "캠핑카 전용 창문 X2",
            description: ["방충망/블라인드, ", "FRP 마감 시공"],
        },
        image: bayrunWindow,
    },
    {
        content: { title: "후면 방충망/블라인드", description: ["-"] },
    },
    {
        content: { title: "전면 유리창 가림막", description: ["탈착식"] },
    },
];

export const bayrun4Equipment = [
    {
        position: [{ x: "32%", y: "61%" }],
        content: { tooltip: "1열 시트", title: "1열 시트", description: ["스위블 적용"] },
        image: bayrunSeat,
    },
    {
        position: [{ x: "49%", y: "43%" }],
        content: {
            tooltip: "2열 시트(변환침대)",
            title: "2열 시트(변환침대)",
            description: ["1150X1700, ", "침상 변환 시 에어매트 적용 - 전동 에어펌프 포함"],
        },
        image: bayrun4PurchaseSubBed,
    },
    {
        position: [{ x: "85%", y: "40%" }],
        content: {
            tooltip: "메인침대",
            title: "메인침대",
            description: ["1250X1850"],
        },
        image: bayrun4MainBed,
    },
    { position: [{ x: "45%", y: "66%" }], content: { tooltip: "식탁", title: "식탁", description: ["탈착식 변환 식탁"] }, image: bayrun4Table },
    {
        position: [{ x: "78%", y: "5%" }],
        content: {
            tooltip: "창문",
            title: "캠핑카 전용 창문 X2",
            description: ["방충망/블라인드, ", "FRP 마감 시공"],
        },
        image: bayrunWindow,
    },
    {
        content: { title: "후면 방충망/블라인드", description: ["-"] },
    },
    {
        content: { title: "전면 유리창 가림막", description: ["탈착식"] },
    },
];

export const redmaxEquipment = [
    {
        position: [{ x: "32%", y: "61%" }],
        content: { tooltip: "운전석 시트", title: "운전석 시트", description: ["스위블 시트 적용"] },
        image: redmaxSeat,
    },
    {
        position: [{ x: "80%", y: "40%" }],
        content: {
            tooltip: "침상",
            title: "침상",
            description: ["1770X1830"],
        },
        image: redmaxMainBed,
    },
    {
        position: [{ x: "70%", y: "40%" }],
        content: { tooltip: "침상 고정 테이블", title: "침상 고정 테이블", description: ["-"] },
        image: redmaxBedTable,
    },
    { position: [{ x: "40%", y: "45%" }], content: { tooltip: "회전 테이블", title: "회전 테이블", description: ["-"] }, image: redmaxTable },
    {
        position: [{ x: "90%", y: "40%" }],
        content: {
            tooltip: "후면 방충망/블라인드",
            title: "후면 방충망/블라인드",
            description: ["-"],
        },
        image: redmaxWindow,
    },
    {
        content: { title: "후면 방충망/블라인드", description: ["-"] },
    },
    {
        content: { title: "전면 유리창 가림막", description: ["탈착식"] },
    },
];

export const bayrunSelectOption = [
    {
        content: { title: "어라운드뷰 + 올인원 AVN - 포함", description: ["-"] },
    },
    {
        content: { title: "룸미러 블랙박스 2ch - ₩300,000(부가세포함)", description: ["전/후방 녹화, ", "주행 중 상시 후방 영상 출력"] },
    },
    {
        content: { title: "운전석 시트 고급형 - ₩1,500,000(부가세포함)", description: ["스위블 + 온열"] },
    },
    {
        content: {
            title: "선팅",
            description: [
                "기본형(루마일반, 캠핑카 개조 전 시공) - ₩400,000(부가세포함), ",
                "고급형(루마버텍스 300, 필름  변경 가능) - ₩550,000(부가세포함), ",
                "최고급형(루마버텍스 500, 필름  변경 가능) - ₩750,000(부가세포함)",
            ],
        },
    },
    {
        position: [{ x: "26%", y: "24%" }],
        content: { tooltip: "냉동고", title: "냉동고 - ₩770,000(부가세포함)", description: ["냉동고 55L 양문형(출입구 쪽) + 설치 트레이(고정식)"] },
        image: bayrun4Freezer,
    },
    {
        content: { title: "변기 환기벤트(SOG) - ₩350,000(부가세포함)", description: ["-"] },
    },
    {
        position: [{ x: "70%", y: "5%" }],
        content: { tooltip: "어닝", title: "3.5m 루프어닝 - ₩1,800,000(부가세포함)", description: ["툴레(THULE)-수동"] },
        image: bayrunAwning,
    },
    {
        position: [{ x: "45%", y: "63%" }],
        content: {
            tooltip: "배터와이 시스템",
            title: "230A LTE IOT 배터와이 시스템 - ₩4,900,000(부가세포함)",
            description: ["풀터치 메인 컨트롤 + 원격제어"],
        },
        image: bayrunIot,
    },
];

export const redmaxSelectOption = [
    {
        content: { title: "어라운드뷰 + 올인원 AVN - 포함", description: ["-"] },
    },
    {
        content: { title: "룸미러 블랙박스 2ch - ₩300,000(부가세포함)", description: ["전/후방 녹화, ", "주행 중 상시 후방 영상 출력"] },
    },
    {
        content: { title: "운전석 시트 고급형 - ₩1,500,000(부가세포함)", description: ["스위블 + 온열"] },
    },
    {
        content: {
            title: "선팅",
            description: [
                "기본형(루마일반, 캠핑카 개조 전 시공) - ₩400,000(부가세포함), ",
                "고급형(루마버텍스 300, 필름  변경 가능) - ₩550,000(부가세포함), ",
                "최고급형(루마버텍스 500, 필름  변경 가능) - ₩750,000(부가세포함)",
            ],
        },
    },
    {
        position: [{ x: "26%", y: "24%" }],
        content: { tooltip: "냉동고", title: "냉동고 - ₩770,000(부가세포함)", description: ["냉동고 55L 양문형(출입구 쪽) + 설치 트레이(고정식)"] },
        image: bayrun4Freezer,
    },
    {
        content: { title: "변기 환기벤트(SOG) - ₩350,000(부가세포함)", description: ["-"] },
    },
    {
        position: [{ x: "70%", y: "5%" }],
        content: { tooltip: "어닝", title: "3.5m 루프어닝 - ₩1,800,000(부가세포함)", description: ["툴레(THULE)-수동"] },
        image: bayrunAwning,
    },
];
