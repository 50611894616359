import React, { useRef, useState, useEffect } from "react";
import { CalendarOutlined, LeftOutlined, RightOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Space, notification, Row, Col, Divider } from "antd";
import { useAsyncAxios, utilAxiosWithAppToken } from "../../utils/customAxios";
import { ko } from "date-fns/esm/locale";
import { isMobile } from "react-device-detect";
import { useMediaQuery } from "react-responsive";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as Request from "../../commons/request";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";

import "react-datepicker/dist/react-datepicker.css";
import styles from "./CustomDatePicker.module.css";
import DatePicker from "react-datepicker";
import moment from "moment";

const CustomRangePicker = ({
    className,
    value,
    minDate,
    maxDate,
    disabledRanges,
    disabledDates,
    rentPeriodLimits,
    rentPeakSeasons,
    onChange,
}: {
    className?: React.CSSProperties | string;
    value?: [Date | null, Date | null];
    minDate?: Date;
    maxDate?: Date;
    disabledRanges?: Array<TypeUtils.DateType>;
    disabledDates?: string[];
    rentPeriodLimits?: TypeDTO.RentPeriodLimitDto[];
    rentPeakSeasons?: TypeDTO.RentPeakSeasonDto[];
    onChange?: (startDate: Date | null, endDate: Date | null) => void;
}) => {
    const isMinWidth = useMediaQuery({ maxWidth: 360 });
    const calendar = useRef<any>(null);

    const [api, contextHolder] = notification.useNotification();
    const [filterRangeDates, setFilterRangeDates] = useState<Array<TypeUtils.DateType>>([]);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [open, setOpen] = useState<boolean>(false);
    const [peakSeasonMessage, setPeakSeasonMessage] = useState<string>();
    const [holidays, setHolidays] = useState<Array<TypeUtils.Holidays>>([]);

    useEffect(() => {
        Utils.onEvent(Common.EVENT_CLOSE_PICKER, closeDatePicker);
        requestGetHolidays(moment().year());
        return () => {
            Utils.offEvent(Common.EVENT_CLOSE_PICKER, closeDatePicker);
        };
    }, []);

    useEffect(() => {
        open ? Utils.openPickerStack() : Utils.closePickerStack();
    }, [open]);

    const openDatePicker = () => {
        calendar.current && calendar.current.setOpen(true);
    };

    const closeDatePicker = () => {
        calendar.current && calendar.current.setOpen(false);
    };

    useEffect(() => {
        setFilterRangeDates(
            disabledRanges?.filter((range) => {
                const isStartBeforeThisMonth = moment(range.startDate).isBefore(moment().startOf("month"));
                const isEndBeforeThisMonth = moment(range.endDate).isBefore(moment().startOf("month"));
                return !(isStartBeforeThisMonth && isEndBeforeThisMonth);
            }) || []
        );
    }, [disabledRanges]);

    // holidays
    const requestAxiosGetHolidays = async (year: string, month?: string) => {
        const response = await utilAxiosWithAppToken().get(Request.RENT_HOLIDAYS_URL, { params: { year: year, month: month } });
        return response.data;
    };

    const {
        loading: loadingGetHolidays,
        error: errorGetHolidays,
        data: resultGetHolidays,
        execute: requestGetHolidays,
    } = useAsyncAxios(requestAxiosGetHolidays);

    useEffect(() => {
        if (resultGetHolidays === null) return;

        console.log("resultGetHolidays", resultGetHolidays);
        setHolidays(resultGetHolidays.holidays);
    }, [resultGetHolidays]);

    useEffect(() => {
        if (errorGetHolidays === null) return;

        console.log("errorGetHolidays", errorGetHolidays);
    }, [errorGetHolidays]);

    const checkDate = (targetDate: Date, dayOfWeek: number) => {
        const endOfMonth = moment(targetDate).add(1, "months").endOf("month").clone().day(dayOfWeek);
        return moment(value?.[0]).isSameOrBefore(endOfMonth);
    };

    const disabledDate = (date: any) => {
        const current = moment(date);

        const isDisabledStartDate = filterRangeDates?.some((range) => {
            const isAfterStart = current.isSameOrAfter(moment(range.startDate).startOf("day"));
            const isBeforeEnd = current.isSameOrBefore(moment(range.endDate).subtract(1, "day").endOf("day"));
            return isAfterStart && isBeforeEnd;
        });

        const isDisabledEndDate = filterRangeDates?.some((range) => {
            const isAfterStart = current.isSameOrAfter(moment(range.startDate).add(1, "day").startOf("day"));
            const isAfterEnd = current.isSameOrBefore(moment(range.endDate).endOf("day"));
            return isAfterStart && isAfterEnd;
        });

        const isFourDate = () => {
            const isBeforeFourDays = current.isSameOrAfter(moment(value?.[0]).startOf("day"));
            const isAfterFourDays = current.isSameOrBefore(moment(value?.[0]).add(3, "day").endOf("day"));
            return isBeforeFourDays && isAfterFourDays;
        };

        const isBeforeMaxDate = () => {
            const checkOpen =
                Utils.getCurrentTime().isSame(Utils.getCurrentTime().clone().startOf("month"), "day") &&
                Utils.getCurrentTime().isBefore(Utils.getCurrentTime().clone().startOf("day").hour(9));

            if (value?.[0]) {
                if (
                    checkDate(value?.[0], 0) ||
                    checkDate(value?.[0], 1) ||
                    checkDate(value?.[0], 2) ||
                    checkDate(value?.[0], 3) ||
                    checkDate(value?.[0], 4)
                ) {
                    return current.isSameOrBefore(
                        moment()
                            .add(checkOpen ? 1 : 2, "months")
                            .date(1)
                    );
                } else if (checkDate(value?.[0], 5)) {
                    return current.isSameOrBefore(
                        moment()
                            .add(checkOpen ? 1 : 2, "months")
                            .date(3)
                    );
                }
            }

            if (checkOpen) {
                return current.isSameOrBefore(Utils.getCurrentTime().add(0, "months").endOf("month"));
            } else {
                return current.isSameOrBefore(Utils.getCurrentTime().add(1, "months").endOf("month"));
            }
        };

        const isDisabledDate = disabledDates?.some((date) => {
            return current.isSame(moment(date)) ? true : false;
        });

        if (value?.[0] && !value?.[1]) {
            if (disabledDates && disabledDates?.length > 0) {
                return isBeforeMaxDate() && isFourDate();
            } else {
                return isBeforeMaxDate() && isFourDate() && !isDisabledEndDate;
            }
        } else {
            if (disabledDates && disabledDates?.length > 0) {
                return isBeforeMaxDate() && !isDisabledDate;
            } else {
                return isBeforeMaxDate() && !isDisabledStartDate;
            }
        }
    };

    const errorSelectedDate = (description: string) => {
        onChange && onChange(null, null);
        openDatePicker();
        setErrorMessage(description);
    };

    return (
        <>
            <DatePicker
                ref={calendar}
                locale={ko}
                wrapperClassName={`${className} ${styles.wrapper}`}
                selectsRange={true}
                minDate={minDate ? (minDate.getDate() < new Date().getDate() ? new Date() : minDate) : new Date()}
                startDate={(value && value[0]) || null}
                endDate={(value && value[1]) || null}
                dayClassName={(d) => {
                    const today = new Date();
                    const date = moment(d).format(Common.FORMAT_DATE);

                    const isDisabled = disabledDates?.find((disabledDate) => {
                        if (disabledDate === date) {
                            return true;
                        } else {
                            return false;
                        }
                    });

                    const dayMoment = moment(d).startOf("day");
                    const peakSeason = rentPeakSeasons?.find((peakSeason) =>
                        dayMoment.isBetween(peakSeason.startDate, peakSeason.endDate, undefined, "[]")
                    );
                    let peakSeasonClass = "";
                    if (peakSeason) {
                        setPeakSeasonMessage(peakSeason.message);
                        peakSeasonClass = dayMoment.isSameOrAfter(today, "day") || !isDisabled ? styles.peakSeason : styles.disabledPeakSeason;
                    }

                    if (d.getDate() === today.getDate() && d.getMonth() === today.getMonth() && d.getFullYear() === today.getFullYear()) {
                        if (isDisabled) {
                            return `${styles.reservedToDay} ${peakSeasonClass}`;
                        }

                        return `${styles.today} ${styles.disabledToday} ${peakSeasonClass}`;
                    }

                    if (value && value[0]) {
                        if (d.getDate() === value[0]!.getDate() && d.getMonth() === value[0]!.getMonth()) {
                            return `${styles.selectedDay} ${peakSeasonClass}`;
                        }
                        const selectableDay = new Date(value[0]);
                        selectableDay.setDate(value[0].getDate() + 3);
                        if (value[0] < d && d <= selectableDay) {
                            return peakSeasonClass;
                        }
                    }

                    if (isDisabled) {
                        return `${styles.disabledDay} ${peakSeasonClass}`;
                    }

                    const isHoliday = holidays?.findIndex((item) => item.date === date) > -1;
                    return `${isHoliday ? styles.holiday : ""} ${peakSeasonClass}`;
                }}
                isClearable
                portalId={styles.datepickerWrapper}
                disabledKeyboardNavigation
                renderCustomHeader={({ date, prevMonthButtonDisabled, nextMonthButtonDisabled, decreaseMonth, increaseMonth }) => {
                    setPeakSeasonMessage(undefined);
                    return (
                        <div className={styles.header}>
                            <Row className={styles.selectedDate} justify={isMinWidth ? "space-between" : "space-around"} align="middle">
                                <Col>
                                    <span>{String.departureDate}</span>
                                    <Space size={4}>
                                        <p>{value?.[0]?.valueOf ? moment(value[0]).format(Common.FORMAT_SHORT_DATE_WITH_DAY) : String.selectDate}</p>
                                        {value?.[0]?.valueOf && (
                                            <CloseCircleOutlined
                                                onClick={() => {
                                                    onChange && onChange(null, null);
                                                }}
                                            />
                                        )}
                                    </Space>
                                </Col>
                                <Col>
                                    <span>{String.returnDate}</span>
                                    <Space size={4}>
                                        <p>{value?.[1]?.valueOf ? moment(value[1]).format(Common.FORMAT_SHORT_DATE_WITH_DAY) : String.selectDate}</p>
                                        {value?.[1]?.valueOf && (
                                            <CloseCircleOutlined
                                                onClick={() => {
                                                    onChange && onChange(null, null);
                                                }}
                                            />
                                        )}
                                    </Space>
                                </Col>
                            </Row>
                            <Divider style={{ marginTop: 10, marginBottom: 14 }} />
                            <Row className={styles.month} style={{ padding: isMobile ? "0 10%" : "0 15%" }} justify="space-between" align="middle">
                                <LeftOutlined
                                    onClick={() => decreaseMonth()}
                                    disabled={prevMonthButtonDisabled}
                                    style={{ visibility: prevMonthButtonDisabled ? "hidden" : "visible" }}
                                />
                                <Col>
                                    <span>
                                        {date.getFullYear()}
                                        {String.year}{" "}
                                    </span>
                                    <span>
                                        {date.getMonth() + 1}
                                        {String.month}
                                    </span>
                                </Col>
                                <RightOutlined onClick={() => increaseMonth()} disabled={nextMonthButtonDisabled} />
                            </Row>
                            {new Date(new Date().setMonth(new Date(new Date()).getMonth() + 1)) <
                                new Date(date.getFullYear(), date.getMonth(), 1, 9) && (
                                <p
                                    style={{
                                        fontSize: 14,
                                        color: "rgb(130 130 130)",
                                        fontWeight: "normal",
                                        display: "block",
                                        marginTop: isMobile ? 2 : 4,
                                        marginBottom: 0,
                                    }}
                                >
                                    {date.getMonth() === 0 ? 12 : date.getMonth()}월 1일 오전 9시 오픈
                                </p>
                            )}
                        </div>
                    );
                }}
                popperPlacement="auto"
                filterDate={disabledDate}
                shouldCloseOnSelect={false}
                onChange={(update) => {
                    const [startDate, endDate] = update;
                    const maxDuration = 3;
                    const duration = moment(endDate).diff(startDate, "days") + 1;
                    const hasOverlap = filterRangeDates?.some(
                        (range) =>
                            moment(startDate).isSameOrBefore(moment(range.endDate).subtract(1, "day").endOf("day")) &&
                            moment(endDate).isSameOrAfter(moment(range.startDate).add(1, "day").startOf("day"))
                    );
                    const periodLimited = rentPeriodLimits?.find(
                        (rentPeriodLimit) =>
                            moment(startDate).startOf("day").isBetween(moment(rentPeriodLimit.startDate), moment(rentPeriodLimit.endDate)) ||
                            moment(endDate).startOf("day").isBetween(moment(rentPeriodLimit.startDate), moment(rentPeriodLimit.endDate))
                    );
                    setErrorMessage(undefined);
                    if (periodLimited) {
                        errorSelectedDate(periodLimited.message);
                        return;
                    }
                    if (startDate?.getDay() === 6) {
                        errorSelectedDate("주말(금/토/일) 예약은 2박3일(주말 전체) 또는 3박4일(주말 전체+평일)로만 예약 가능합니다.");
                        return;
                    }
                    if (hasOverlap) {
                        errorSelectedDate("예약할 수 없는 날짜가 포함되어 있습니다.");
                    } else if (duration > maxDuration) {
                        errorSelectedDate("예약 가능한 기간은 최대 3박 4일까지입니다.");
                    } else {
                        onChange !== undefined && onChange(startDate, endDate);
                        if (startDate && endDate) {
                            const rangeDates: Date[] = [];
                            const currentDate = moment(startDate);
                            const endDateMoment = moment(endDate);

                            while (currentDate <= endDateMoment) {
                                rangeDates.push(currentDate.toDate());
                                currentDate.add(1, "day");
                            }

                            const weekends = rangeDates.filter((date) => [5, 6].includes(moment(date).day()));
                            if (weekends.length > 0 && weekends.length < 2) {
                                errorSelectedDate("주말(금/토/일) 예약은 2박3일(주말 전체) 또는 3박4일(주말 전체+평일)로만 예약 가능합니다.");
                                return;
                            } else {
                                closeDatePicker();
                            }
                        }
                    }
                }}
                onMonthChange={(date) => {
                    if (holidays.length === 0 || +holidays[0].date.substring(0, 4) !== date?.getFullYear()) {
                        requestGetHolidays(date.getFullYear());
                    }
                }}
                onCalendarOpen={() => setOpen(true)}
                onCalendarClose={() => {
                    value?.[1] === null && onChange && onChange(null, null);
                    setErrorMessage(undefined);
                    setOpen(false);
                }}
                customInput={
                    <div className={styles.innerInput}>
                        <CalendarOutlined className={styles.icon} />
                        <p className={styles.input}>
                            {value && (value[0] || value[1])
                                ? `${value[0] ? moment(value[0]).format(Common.FORMAT_DATE_WITH_DAY) : String.selectDate} ~ ${
                                      value[1] ? moment(value[1]).format(Common.FORMAT_DATE_WITH_DAY) : String.selectDate
                                  }`
                                : String.selectDateMsg}
                        </p>
                    </div>
                }
            >
                {contextHolder}
                <div
                    style={{ maxWidth: 380 }}
                    className={errorMessage ? "custom-bolder-box " + `${styles.errorMessage}` : "custom-bolder-box " + `${styles.message}`}
                >
                    {errorMessage ? (
                        errorMessage
                    ) : (
                        <ul className="m-0">
                            <li>최대 3박4일까지 예약 가능합니다.</li>
                            <li>주말(금/토/일) 예약은 2박3일(주말 전체) 또는 3박4일(주말 전체+평일)로만 예약 가능합니다.</li>
                            {peakSeasonMessage && (
                                <li className="text-danger" style={{ whiteSpace: "pre-wrap" }}>
                                    {peakSeasonMessage}
                                </li>
                            )}
                        </ul>
                    )}
                </div>
            </DatePicker>
        </>
    );
};

export default CustomRangePicker;
