import React from "react";
import { Col, Row } from "antd";
import { useMediaQuery } from "react-responsive";

import storyImage1 from "../../assets/images/story/ruta40_img_1.jpg";
import storyImage2 from "../../assets/images/story/ruta40_img_2.jpg";
import storyImage3 from "../../assets/images/story/ruta40_img_3.jpg";
import storyImage4 from "../../assets/images/story/ruta40_img_4.jpg";
import storyImage5 from "../../assets/images/story/ruta40_img_5.jpg";
import storyImage6 from "../../assets/images/story/ruta40_img_6.jpg";
import storyImage7 from "../../assets/images/story/ruta40_img_7.jpg";
import storyImage8 from "../../assets/images/story/ruta40_img_8.jpg";
import storyImage9 from "../../assets/images/story/ruta40_img_9.jpg";
import storyImage10 from "../../assets/images/story/ruta40_img_10.jpg";
import storyImage11 from "../../assets/images/story/ruta40_img_11.jpg";
import Banner from "../../assets/images/home/ruta40/banner_story.jpg";

function Story() {
    const isMobile = useMediaQuery({ maxWidth: 700 });

    return (
        <>
            <div className="header-container model">
                <div className="container" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)),url(${Banner})` }}>
                    <div className="row justify-content-center">
                        <div className="fadeIn text-center mb-5">
                            <h5 className="text-center text-light px-3 mb-4">Story : RUTA40</h5>
                            <h1 className="text-light">RUTA40</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`model-tabs title model-body story ${isMobile && "mobile-story"}`} style={{ backgroundColor: "white" }}>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    <div className="my-5">
                        <h3 className="pt-5 mb-5" style={{ textAlign: "center" }}>
                            끝없는 자유를 향한 여정, RUTA40
                        </h3>
                        <Row className={`row pb-4 ${!isMobile && "mt-3"}`}>
                            <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ minHeight: "400px" }}>
                                <div className="position-relative h-100">
                                    <img
                                        className="img-fluid position-absolute w-100 h-100"
                                        src={storyImage1}
                                        alt=""
                                        style={{ objectFit: "cover" }}
                                    />
                                </div>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 12 }}
                                className="mt-4"
                                style={{
                                    textAlign: isMobile ? "center" : "left",
                                }}
                            >
                                <h5 className="mb-4">세상에는 무수한 길이 있습니다.</h5>
                                <p className="mb-4">
                                    그러나 어떤 길은 단순한 이동을 넘어, <br />
                                    우리에게 <b>도전과 탐험, 그리고 새로운 가능성을 경험할 수 있는 특별한 순간</b>을 선사합니다.
                                </p>
                                <p className="mb-4">
                                    그중에서도 아르헨티나의 40번 국도(Ruta Nacional 40)는 <br />
                                    여행자들에게 단순한 길이 아닌
                                    <br /> <b>끝없는 자유를 향한 상징적인 여정</b>으로 자리 잡고 있습니다.
                                </p>
                                <p className="mb-4">
                                    이 도로는 <br />
                                    남미 대륙을 남북으로 5,000km 넘게 가로지르며, <br />
                                    험준한 산맥과 드넓은 평야,
                                    <br /> 황량한 사막과 끝없는 초원을 지나갑니다.
                                </p>
                                <p className="mb-4">
                                    여행자들은 이 길 위에서
                                    <br />{" "}
                                    <b>
                                        새로운 도전을 경험하고,
                                        <br /> 한계를 뛰어넘으며, <br />
                                        진정한 자유를 찾는 순간
                                    </b>
                                    을 맞이하게 됩니다.
                                </p>
                            </Col>
                        </Row>
                    </div>

                    <div className="pb-4 my-5" style={{ textAlign: "center" }}>
                        <h5 className="mb-5">RUTA40이라는 이름은 단순한 브랜드 네임이 아닙니다.</h5>
                        <p className="mb-4">
                            우리는 이 길이 가진 <b>탐험 정신과 자유로운 이동성의 가치</b>를 담아,
                            <br /> 새로운 시대에 맞는 캠핑카의 혁신적인 이동 방식을 제안하고자 했습니다.
                        </p>
                        <p className="mb-0">
                            전기차 기반의 캠핑카인 RUTA40은 단순한 교통수단을 넘어,
                            <br /> 자연과 조화를 이루며 <b>지속 가능하고 효율적인 이동</b>을 가능하게 합니다.
                        </p>
                        <Row className="row gy-4 mt-0 mb-4">
                            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                                <img className="img-fluid position-relative" src={storyImage2} alt="" style={{ objectFit: "cover" }} />
                            </Col>
                            <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                                <img className="img-fluid position-relative" src={storyImage3} alt="" style={{ objectFit: "cover" }} />
                            </Col>
                            <Col xs={{ span: 12 }} sm={{ span: 8 }}>
                                <img className="img-fluid position-relative" src={storyImage4} alt="" style={{ objectFit: "cover" }} />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                                <img className="img-fluid position-relative" src={storyImage5} alt="" style={{ objectFit: "cover" }} />
                            </Col>
                            <Col xs={{ span: 12 }} sm={{ span: 6 }}>
                                <img className="img-fluid position-relative" src={storyImage6} alt="" style={{ objectFit: "cover" }} />
                            </Col>
                            <Col xs={{ span: 12 }} sm={{ span: 6 }}>
                                <img className="img-fluid position-relative" src={storyImage7} alt="" style={{ objectFit: "cover" }} />
                            </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 6 }}>
                                <img className="img-fluid position-relative" src={storyImage8} alt="" style={{ objectFit: "cover" }} />
                            </Col>
                        </Row>
                        <p className="mb-0 pb-5">
                            우리는 이 캠핑카를 통해
                            <br />
                            <b>이동의 자유, 자연과의 공존, 그리고 여행이 주는 진정한 의미</b>를 함께 경험하고자 합니다.
                        </p>
                    </div>

                    <hr />

                    <div className="pt-4 pb-4 my-5" style={{ textAlign: "center" }}>
                        <h3 className="pt-5 mb-5" style={{ textAlign: "center" }}>
                            {`영화 <모터사이클 다이어리>, 그리고 RUTA40`}
                        </h3>
                        <div className="mb-4">
                            <img className="img-fluid position-relative" src={storyImage9} />
                            <span className="source-text text-center">
                                Walter Salles. (2004). [The Motorcycle Diaries]. 미국, 독일, 영국: UIP코리아
                            </span>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <p className="mb-5">
                                <b>{`<모터사이클 다이어리>, 체 게바라와 그의 친구가 함께 떠난 남미 횡단 여행의 이야기입니다.`}</b>
                            </p>
                            <Row className={`row ${!isMobile && "mt-3"}`}>
                                <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ minHeight: "400px" }}>
                                    <img className="img-fluid position-relative" src={storyImage10} alt="" style={{ objectFit: "cover" }} />
                                    <img className="img-fluid position-relative" src={storyImage11} alt="" style={{ objectFit: "cover" }} />
                                    <span className="source-text" style={{ width: "100%", textAlign: "center" }}>
                                        Walter Salles. (2004). [The Motorcycle Diaries]. 미국, 독일, 영국: UIP코리아
                                    </span>
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    sm={{ span: 12 }}
                                    className="mt-4"
                                    style={{
                                        textAlign: isMobile ? "center" : "left",
                                    }}
                                >
                                    <p className="mb-4">
                                        이 영화는 단순한 여행기가 아닙니다.
                                        <br />
                                        그들에게 이 여정은{" "}
                                        <b>
                                            삶을 변화시키는 경험이자, <br />
                                            세상을 새롭게 바라보는 계기
                                        </b>
                                        였습니다.
                                    </p>
                                    <p className="mb-4">
                                        길 위에서 만난 사람들, 예상치 못한 상황들, <br />
                                        그리고 여행 중 깨닫게 된 것들은 그들의 가치관을 바꾸고, <br />
                                        인생의 방향을 결정하는 계기가 되었습니다.
                                    </p>
                                    <p className="mb-4">이것이 우리가 추구하는 RUTA40의 정신입니다.</p>
                                    <p className="mb-4">
                                        우리의 캠핑카는 단순한 이동 수단이 아닙니다.
                                        <br />이 차를 타고 떠나는 모든 여행이{" "}
                                        <b>
                                            새로운 시각을 열어주고, <br />더 넓은 세상을 경험하는 순간
                                        </b>
                                        이 되기를 바랍니다.
                                    </p>
                                    <p className="mb-0 pb-5">우리는 RUTA40을 통해 여행자들에게 말하고 싶습니다.</p>
                                </Col>
                                <p className="mt-4 mb-5">
                                    <b>{`"이 길 위에서 당신의 인생을 바꿀 새로운 이야기가 시작될 것입니다."`}</b>
                                </p>
                            </Row>
                        </div>
                    </div>

                    <hr />

                    <div className="pt-4 my-5" style={{ textAlign: "center" }}>
                        <h3 className="pt-5 mb-5" style={{ textAlign: "center" }}>
                            RUTA40, 새로운 여행 방식을 제안하다
                        </h3>
                        <p className="mb-4">
                            우리는 기존의 내연기관 차량이 아닌, <b>전기차 기반</b>의 6m급 캠핑카를 선택했습니다.
                            <br />그 이유는 명확합니다.
                        </p>
                        <Row className="my-5">
                            <Col className="my-3" xs={{ span: 24 }} sm={{ span: 8 }} style={{ display: "flex", flexDirection: "column" }}>
                                <h4 className="mb-2" style={{ color: "#FFCC00" }}>
                                    1
                                </h4>
                                <p className="mb-2">
                                    <b>지속 가능성</b>
                                </p>
                                <p className="mb-2">미래를 위한 친환경 이동 방식</p>
                            </Col>
                            <Col className="my-3" xs={{ span: 24 }} sm={{ span: 8 }} style={{ display: "flex", flexDirection: "column" }}>
                                <h4 className="mb-2" style={{ color: "#FFCC00" }}>
                                    2
                                </h4>
                                <p className="mb-2">
                                    <b>에너지 독립성</b>
                                </p>
                                <p className="mb-2">106kW 대용량 배터리로 더 자유로운 여행</p>
                            </Col>
                            <Col className="my-3" xs={{ span: 24 }} sm={{ span: 8 }} style={{ display: "flex", flexDirection: "column" }}>
                                <h4 className="mb-2" style={{ color: "#FFCC00" }}>
                                    3
                                </h4>
                                <p className="mb-2">
                                    <b>조용한 여정</b>
                                </p>
                                <p className="mb-2">자연 속에서도 소음 없이 머물 수 있는 경험</p>
                            </Col>
                        </Row>
                        <p className="mb-4">
                            전통적인 여행 방식과 전기차 기술이 만났을 때,
                            <br />
                            그것은 <b>단순한 변화가 아닌 새로운 여행 방식의 탄생</b>이 됩니다.
                        </p>
                        <p className="mb-4">
                            우리는 떠나는 순간이 가장 설레는 감정이라는 것을 알고 있습니다.
                            <br />
                            우리는 여행이 <b>단순한 이동</b>이 아니라, <b>삶을 변화시키는 과정</b>이라는 것을 믿습니다.
                            <br />
                            그리고 우리는 이동의 방식이 바뀌면, 여행의 경험도 달라질 것이라는 확신을 갖고 있습니다.
                        </p>
                        <p className="mb-0 pb-5">
                            RUTA40은 <b>새로운 가능성을 향해 떠나고 싶은 모든 여행자들을 위한 브랜드</b>입니다.
                            <br />
                            당신이 가고 싶은 길이 있다면, 우리는 그 길을 더욱 의미 있게 만들어줄 것입니다.
                        </p>
                    </div>
                </Col>
            </div>
        </>
    );
}

export default Story;
