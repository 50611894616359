import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Space, Row, Col, Collapse, Modal } from "antd";

import * as Utils from "../../utils/utils";
import * as TypeDTO from "../../commons/typeDTO";
import * as String from "../../commons/string";
import * as Common from "../../commons/common";

import IconNoImage from "../../assets/images/home/ruta40/icon_no_image.svg";
import ImageBayrun from "../../assets/images/model/bayrun/BayrunType-exterior-2.jpg";
import ImageRedmax from "../../assets/images/model/redmax/Redmax-exterior-1.jpg";
import moment from "moment";

const MyOrderCard = ({
    order,
    activePanel,
    onCancelOrder,
    onCancelPayment,
}: {
    order: TypeDTO.OrderDto;
    activePanel?: number[];
    onCancelOrder: (order: TypeDTO.OrderDto) => void;
    onCancelPayment: (payment: TypeDTO.PaymentDto) => void;
}) => {
    const { Panel } = Collapse;
    const navigate = useNavigate();

    const convertCurrency = (price: number | undefined) => {
        if (!price) return "";
        return Utils.convertCurrencyKorean(price);
    };

    const getOrderTitle = (order: TypeDTO.OrderDto) => {
        return "[" + order.carType?.model.modelName + "] " + order.carType?.typeName;
    };

    const getCarPriceVAT = (order: TypeDTO.OrderDto) => {
        let carPrice = 0;
        let carPriceVAT = 0;
        if (order.carType) {
            carPrice += order.carType?.discountedPrice ? order.carType?.discountedPrice : order.carType.price;
            carPrice += order.carType?.discountedAdditionalPrice ? order.carType?.discountedAdditionalPrice : order.carType.additionalPrice;
        }
        if (order.color) {
            carPrice += 0;
        }

        carPriceVAT = (carPrice / 100) * 10;

        if (order.options) {
            order.options.forEach((option) => (carPrice += option.price));
        }

        return carPriceVAT;
    };

    const getSubsidy = (order: TypeDTO.OrderDto) => {
        let subsidyPrice = 0;
        if (order.nationalSubsidy !== undefined && order.nationalSubsidy !== null) {
            subsidyPrice = order.nationalSubsidy.price;
        }

        let subsidySiGunPrice = 0;
        if (order.localSubsidy !== undefined) {
            subsidySiGunPrice = order.localSubsidy.price;
        }

        return (subsidyPrice + subsidySiGunPrice) * -1;
    };

    const getOrderPrice = (order: TypeDTO.OrderDto) => {
        let totalPrice = 0;
        if (order.totalPrice !== undefined && order.totalPrice !== null) {
            totalPrice = order.totalPrice;
        }

        let subsidyPrice = 0;
        if (order.nationalSubsidy !== undefined && order.nationalSubsidy !== null) {
            subsidyPrice = order.nationalSubsidy.price;
        }

        let subsidySiGunPrice = 0;
        if (order.localSubsidy !== undefined) {
            subsidySiGunPrice = order.localSubsidy.price;
        }

        return totalPrice - (subsidyPrice + subsidySiGunPrice);
    };

    const displayPaymentStatus = (order: TypeDTO.OrderDto) => {
        if (order.payments && order.payments.length > 0) {
            return order.payments.map((payment, index) => {
                return (
                    <div key={payment.paymentId}>
                        <Row>
                            <Col span={15} style={{ textAlign: "left" }}>
                                <p className="b-sm" style={{ fontWeight: 700 }}>
                                    {Utils.convertPaymentType(payment.paymentType)}
                                </p>
                            </Col>

                            <Col span={9} style={{ textAlign: "right" }}>
                                {order.canceledDate ? (
                                    <p style={{ fontWeight: 700, color: "#e21d12" }}>취소 완료</p>
                                ) : (
                                    <p style={{ fontWeight: 700, color: "#006AF5" }}>결제 완료</p>
                                )}
                            </Col>
                        </Row>
                        <Row gutter={10}>
                            <Col xs={24} xxl={7}>
                                <Row>
                                    <Col span={9} style={{ textAlign: "left" }}>
                                        <span>결제 금액</span>
                                    </Col>
                                    <Col span={15} style={{ textAlign: "right" }}>
                                        <span>{convertCurrency(payment.amount)}</span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={0} xxl={1}>
                                <span style={{ color: "#1A38601A" }}>|</span>
                            </Col>
                            <Col xs={24} xxl={8}>
                                <Row>
                                    <Col span={9} style={{ textAlign: "left" }}>
                                        <span>결제 일시</span>
                                    </Col>
                                    <Col span={15} style={{ textAlign: "right" }}>
                                        <span>
                                            {payment.paidDate ? Utils.utcToLocalTime(payment.paidDate, Common.FORMAT_DATE_TIME) : String.dash}
                                        </span>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={0} xxl={1}>
                                <p style={{ color: "#1A38601A" }}>|</p>
                            </Col>
                            <Col xs={24} xxl={7}>
                                <Row>
                                    <Col span={9} style={{ textAlign: "left" }}>
                                        <span>결제 수단</span>
                                    </Col>
                                    <Col span={15} style={{ textAlign: "right" }}>
                                        <span>{payment.paidDate ? Utils.getPaymentType(payment) : String.dash}</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {order.canceledDate && (
                            <>
                                <hr />
                                <Row gutter={10}>
                                    <Col xs={24} xxl={7}>
                                        <Row>
                                            <Col span={9} style={{ textAlign: "left" }}>
                                                <span>취소 금액</span>
                                            </Col>
                                            <Col span={15} style={{ textAlign: "right" }}>
                                                <span>{convertCurrency(payment.paymentImp?.cancelAmount)}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={0} xxl={1}>
                                        <p style={{ color: "#1A38601A" }}>|</p>
                                    </Col>
                                    <Col xs={24} xxl={8}>
                                        <Row>
                                            <Col span={9} style={{ textAlign: "left" }}>
                                                <span>취소 일시</span>
                                            </Col>
                                            <Col span={15} style={{ textAlign: "right" }}>
                                                <span>
                                                    {order.canceledDate
                                                        ? Utils.utcToLocalTime(order.canceledDate, Common.FORMAT_DATE_TIME)
                                                        : String.dash}
                                                </span>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={0} xxl={1}>
                                        <span style={{ color: "#1A38601A" }}>|</span>
                                    </Col>
                                    <Col xs={24} xxl={7}>
                                        <Row>
                                            <Col span={9} style={{ textAlign: "left" }}>
                                                <span>취소 수단</span>
                                            </Col>
                                            <Col span={15} style={{ textAlign: "right" }}>
                                                <span>{payment.paidDate ? Utils.getPaymentType(payment) : String.dash}</span>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {order.payments && (index !== order.payments.length - 1 ? <hr /> : null)}
                    </div>
                );
            });
        }
        return null;
    };

    const confirmCancelOrder = () => {
        if (moment().isAfter(moment(order.createdDate).add(3, "month"))) {
            Modal.info({
                title: "취소 불가",
                content: (
                    <p style={{ textAlign: "center", margin: 0 }}>
                        주문일로부터 3개월이 초과되어
                        <br />
                        고객센터를 통한 환불만 가능합니다.
                        <br />
                        ☎070-4327-3730
                    </p>
                ),
                okText: String.confirm,
                centered: true,
            });
        } else {
            Modal.confirm({
                title: "주문 취소",
                content: <p style={{ textAlign: "center", margin: 0 }}>주문을 취소하시겠습니까?</p>,
                okText: String.confirm,
                onOk() {
                    onCancelOrder(order);
                },
                cancelText: String.cancel,
                onCancel() {},
                centered: true,
            });
        }
    };

    const displayInfo = (content: string, price?: string | number, isLarge?: boolean) => {
        return (
            <Row>
                <Col span={14} style={{ textAlign: "left" }}>
                    {isLarge ? (
                        <span className="b-lg" style={{ fontWeight: 700 }}>
                            {content}
                        </span>
                    ) : (
                        <span>{content}</span>
                    )}
                </Col>
                <Col span={10} style={{ textAlign: "right" }}>
                    {isLarge ? (
                        <span className="b-lg" style={{ fontWeight: 700 }}>
                            {price}
                        </span>
                    ) : (
                        <span>{price}</span>
                    )}
                </Col>
            </Row>
        );
    };

    return (
        <div className="order-card">
            <Collapse className="mb-3" expandIconPosition="end" defaultActiveKey={activePanel}>
                <Panel
                    key={Number(order.orderId)}
                    header={
                        <div>
                            <div className="mb-2">
                                {order.canceledDate ? (
                                    <>
                                        <span style={{ fontWeight: "700", color: "#ff0000" }}>{String.orderCancel}</span>
                                        <span style={{ margin: "0 10px" }}>|</span>
                                        <span style={{ color: "#192739F0" }}>
                                            {order.canceledDate && Utils.utcToLocalTime(order.canceledDate, Common.FORMAT_DATE_TIME)}
                                        </span>
                                    </>
                                ) : order.deliveryStatusDate ? (
                                    <>
                                        <span style={{ fontWeight: "700", color: "#006af5" }}>구매 완료</span>
                                        <span style={{ margin: "0 10px" }}>|</span>
                                        <span style={{ color: "#192739F0" }}>
                                            {order.createdDate && Utils.utcToLocalTime(order.createdDate, Common.FORMAT_DATE_TIME)}
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <span style={{ fontWeight: "700", color: "#158444" }}>{Utils.getOrderStatus(order.payments)}</span>
                                        <span style={{ margin: "0 10px" }}>|</span>
                                        <span style={{ color: "#192739F0" }}>
                                            {order.createdDate && Utils.utcToLocalTime(order.createdDate, Common.FORMAT_DATE_TIME)}
                                        </span>
                                    </>
                                )}
                            </div>
                            <Row gutter={[20, 20]}>
                                <Col>
                                    <img
                                        style={{ width: "200px", borderRadius: "8px" }}
                                        src={
                                            order.carType?.typeName.includes("배이런")
                                                ? ImageBayrun
                                                : order.carType?.typeName.includes("레드맥스")
                                                ? ImageRedmax
                                                : IconNoImage
                                        }
                                    />
                                </Col>
                                <Col>
                                    <h5>{getOrderTitle(order)}</h5>
                                    <Space size="middle">
                                        <Button
                                            className="rent-secondary-btn"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                navigate(
                                                    Common.PAGE_MODEL + "/" + order.carType?.model.category.categoryId + "/" + order.carType?.typeId
                                                );
                                            }}
                                        >
                                            {String.showGoods}
                                        </Button>
                                        {!order.canceledDate && !order.deliveryStatusDate ? (
                                            <Button
                                                className="rent-destructive-btn"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    confirmCancelOrder();
                                                }}
                                            >
                                                {String.orderCancel}
                                            </Button>
                                        ) : null}
                                    </Space>
                                </Col>
                            </Row>
                        </div>
                    }
                >
                    <Row gutter={[20, 20]}>
                        <Col xs={24} sm={24} md={12}>
                            <h6>{String.orderInfo}</h6>
                            <Space direction="vertical" size="small" style={{ width: "100%" }}>
                                <div className="box">
                                    <p>주문번호: {order.orderNo}</p>
                                </div>
                                <div className="box">
                                    <>
                                        {displayInfo(
                                            `${order.carType?.typeName} / ${order.color?.colorName}`,
                                            convertCurrency(order.carType?.discountedPrice ? order.carType?.discountedPrice : order.carType?.price)
                                        )}
                                    </>
                                    <>
                                        {order.carType?.additionalPrice && order.carType?.additionalPrice > 0
                                            ? displayInfo(
                                                  `${order.carType?.additionalCompany}`,
                                                  convertCurrency(
                                                      order.carType?.discountedAdditionalPrice
                                                          ? order.carType?.discountedAdditionalPrice
                                                          : order.carType?.additionalPrice
                                                  )
                                              )
                                            : null}
                                    </>
                                    <>
                                        {order.options && order.options.length > 0
                                            ? order.options.map((option) => {
                                                  if (option.price === 0 && option.optionType.optionTypeName === "선팅") return null;
                                                  return (
                                                      <div key={option.optionId}>{displayInfo(option.optionName, convertCurrency(option.price))}</div>
                                                  );
                                              })
                                            : null}
                                    </>
                                    <hr />
                                    {displayInfo("부가세(10%)", convertCurrency(getCarPriceVAT(order)))}
                                    <hr />
                                    {displayInfo("전기차 보조금", convertCurrency(getSubsidy(order)))}
                                    <hr />
                                    <div className="mt-2">{displayInfo("주문 금액", convertCurrency(getOrderPrice(order)), true)}</div>
                                    <p className="mt-2">※ 최종금액은 전기차 보조금 지원 금액에 따라 변동될 수 있습니다.</p>
                                </div>
                            </Space>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <h6>{String.paymentInfo}</h6>
                            <div className="box">{displayPaymentStatus(order)}</div>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </div>
    );
};

export default MyOrderCard;
