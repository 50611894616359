import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Badge, Button, Col, Row, Tooltip, Image } from "antd";
import { isMobile } from "react-device-detect";
import { ReactComponent as IconBtn } from "../../assets/images/home/ruta40/btn_car_option.svg";

import CarBayrun3 from "../../assets/images/home/ruta40/car_bayrun3.svg";
import CarBayrun4 from "../../assets/images/home/ruta40/car_bayrun4.svg";
import CarSType from "../../assets/images/home/ruta40/car_stype.svg";
import CarBayrun4Rent from "../../assets/images/home/ruta40/car_bayrun4_rent.svg";
import CarRedmax from "../../assets/images/home/ruta40/car_redmax.svg";

import styles from "../../pages/ruta40/Option.module.css";

type PositionType = {
    x?: string;
    y?: string;
};

type OptionType = {
    position?: Array<PositionType>;
    content: {
        tooltip?: string;
        title: string;
        description: string[];
    };
    image?: string;
};

function OptionCar({ carType, options, rent }: { carType: "bayrun3" | "bayrun4" | "sType" | "redmax"; options: Array<OptionType>; rent?: boolean }) {
    const isPhone = useMediaQuery({ maxWidth: 300 });
    const isPad = useMediaQuery({ maxWidth: 500 });

    const [currentIndex, setCurrentIndex] = useState<number>(() => {
        const firstOptionIndex = options.findIndex((option) => option.position);
        return firstOptionIndex === -1 ? 0 : firstOptionIndex;
    });

    const getCarImage = () => {
        switch (carType) {
            case "bayrun3":
                return CarBayrun3;
            case "bayrun4":
                return rent === true ? CarBayrun4Rent : CarBayrun4;
            case "sType":
                return CarSType;
            case "redmax":
                return CarRedmax;
            default:
                return;
        }
    };

    return (
        <Row gutter={[20, 20]} className={`option ${styles.option}`}>
            <Col md={24} lg={16}>
                <div style={{ position: "relative" }}>
                    <img src={getCarImage()} style={{ width: "100%" }} />
                    {options.map((option, index) => {
                        return (
                            <div key={index}>
                                {option.position &&
                                    option.position.map((position, i) => (
                                        <Tooltip
                                            key={index + "-" + i}
                                            placement="bottom"
                                            title={index + 1 + ". " + (option.content.tooltip ? option.content.tooltip : option.content.title)}
                                        >
                                            <Button
                                                type="text"
                                                style={{
                                                    top: isPhone ? `calc(${position.y} - 8%)` : isPad ? `calc(${position.y} - 2%)` : position.y,
                                                    left: isPhone ? `calc(${position.x} - 4%)` : isPad ? `calc(${position.x} - 2%)` : position.x,
                                                }}
                                                className={styles.btn}
                                                icon={<IconBtn />}
                                                onClick={() => setCurrentIndex(index)}
                                            />
                                        </Tooltip>
                                    ))}
                            </div>
                        );
                    })}
                </div>
            </Col>
            <Col md={24} lg={8} style={{ flex: "0 0 100%", maxHeight: isMobile ? 267 : "auto" }}>
                <Image src={options[currentIndex].image} className={styles.image} />
                <div className={styles.imageDescription}>
                    <p className="mb-1" style={{ fontWeight: 700 }}>
                        <Badge className="mb-1" size="small" count={currentIndex + 1} style={{ backgroundColor: "transparent" }} />
                        &nbsp;&nbsp;{options[currentIndex].content.title}
                    </p>
                </div>
            </Col>
        </Row>
    );
}

export default OptionCar;
