import * as React from "react";
import IconInfo from "../assets/images/home/icon_info.svg";

const NoData = ({ msg }: { msg: string }) => {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                minHeight: "calc(100vh - 22rem)",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div>
                <img src={IconInfo} className="mb-3" style={{ fontSize: 59, color: "#1C304A85" }} />
                <p className="b-rg b-lg" style={{ color: "#1C304A85" }}>
                    {msg}
                </p>
            </div>
        </div>
    );
};

export default NoData;
