import { Button, Carousel, Space } from "antd";
import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NoImage from "../assets/images/home/rent/icon_no_image.svg";
import playStoreLogo from "../assets/images/google-play-badge.svg";
import appStoreLogo from "../assets/images/app-store-badge.svg";
import { isAndroid, isIOS, isBrowser, isMobile } from "react-device-detect";
import * as Common from "../commons/common";
import * as Utils from "../utils/utils";
import styles from "../pages/ruta40/Home.module.css";
// declare const window: typeof globalThis & {
//     Android: any;
//     webkit: any;
// };

function ImageSlider({ style, images }: { style?: React.CSSProperties; images?: string[] }) {
    const navigate = useNavigate();
    const [marginLeft, setMarginLeft] = useState("");
    const [maxMarginLeft, setMaxMarginLeft] = useState("");

    useLayoutEffect(() => {
        if (images?.length == 2) {
            setMarginLeft("5.35%");
            setMaxMarginLeft("6.17%");
        } else if (images?.length == 4) {
            setMarginLeft("2.95%");
            setMaxMarginLeft("3.42%");
        } else if (images?.length == 5) {
            setMarginLeft("2.43%");
            setMaxMarginLeft("2.8%");
        } else {
            setMarginLeft("3.8%");
            setMaxMarginLeft("4.4%");
        }
    }, [images]);

    const isKakaoBanner = (image: string) => {
        if (image.includes("banner_main2") || image.includes("banner_main_max2") || image.includes("banner_main_mobile2")) return true;
        return false;
    };
    const isAppBanner = (image: string) => {
        if (image.includes("banner_main3") || image.includes("banner_main_max3") || image.includes("banner_main_mobile3")) return true;
        return false;
    };
    const isNoEntryBanner = (image: string) => {
        if (image.includes("banner_main4") || image.includes("banner_main_max4") || image.includes("banner_main_mobile4")) return true;
        return false;
    };
    const isOneWeekBanner = (image: string) => {
        if (image.includes("banner_main5") || image.includes("banner_main_max5") || image.includes("banner_main_mobile5")) return true;
        return false;
    };
    const isLocalPromotionBanner = (image: string) => {
        if (image.includes("banner_main6") || image.includes("banner_main_max6") || image.includes("banner_main_mobile6")) return true;
        return false;
    };

    return (
        <Carousel autoplaySpeed={4500} autoplay swipeToSlide draggable style={style}>
            {images?.length ? (
                images?.map((image: string) => (
                    <div key={image}>
                        {isBrowser && (
                            <Space
                                size={16}
                                style={{
                                    position: "absolute",
                                    bottom: "14%",
                                    marginLeft: image.includes("max") ? maxMarginLeft : marginLeft,
                                    display: "flex",
                                }}
                            >
                                {isAppBanner(image) && (
                                    <>
                                        <img
                                            onClick={() => {
                                                Utils.sendLinkToApp(Common.URL_IOS_APP_STORE);
                                            }}
                                            style={{ cursor: "pointer" }}
                                            height={image.includes("max") ? 60 : 40}
                                            src={appStoreLogo}
                                        />
                                        <img
                                            onClick={() => {
                                                Utils.sendLinkToApp(Common.URL_ANDROID_PLAY_STORE);
                                            }}
                                            style={{ cursor: "pointer" }}
                                            height={image.includes("max") ? 60 : 40}
                                            src={playStoreLogo}
                                        />
                                    </>
                                )}
                                {isKakaoBanner(image) && (
                                    <Button
                                        className={`${styles.imageSliderButton} ${styles.linkPopupButton}`}
                                        style={{
                                            fontSize: image.includes("max") ? 20 : 16,
                                        }}
                                        onClick={() => {
                                            Utils.sendLinkToApp(Common.URL_KAKAOTALK_CHANNEL_FRIEND);
                                        }}
                                    >
                                        카카오 채널 바로가기
                                    </Button>
                                )}
                                {isNoEntryBanner(image) && (
                                    <Button
                                        className={`${styles.imageSliderButton} ${styles.noEntryPopupButton}`}
                                        style={{
                                            fontSize: image.includes("max") ? 20 : 16,
                                            marginLeft: image.includes("max") ? "60%" : "25%",
                                        }}
                                        onClick={() => {
                                            navigate(Common.PAGE_NOTICE_DETAIL + "/35");
                                        }}
                                    >
                                        자세히 보기
                                    </Button>
                                )}
                                {isOneWeekBanner(image) && (
                                    <Button
                                        className={`${styles.imageSliderButton} ${styles.oneWeekPopupButton}`}
                                        style={{
                                            fontSize: image.includes("max") ? 20 : 16,
                                            marginLeft: image.includes("max") ? "60%" : "25%",
                                        }}
                                        onClick={() => {
                                            navigate(Common.PAGE_NOTICE_DETAIL + "/36");
                                        }}
                                    >
                                        자세히 보기
                                    </Button>
                                )}
                                {isLocalPromotionBanner(image) && (
                                    <Button
                                        className={`${styles.imageSliderButton} ${styles.localPromotionPopupButton}`}
                                        style={{
                                            fontSize: image.includes("max") ? 20 : 16,
                                            marginLeft: image.includes("max") ? "60%" : "25%",
                                        }}
                                        onClick={() => {
                                            navigate(Common.PAGE_NOTICE_DETAIL + "/25");
                                        }}
                                    >
                                        자세히 보기
                                    </Button>
                                )}
                            </Space>
                        )}
                        <img
                            key={image}
                            src={image}
                            style={{ width: "100%" }}
                            onClick={() => {
                                if (isKakaoBanner(image) && isMobile) {
                                    Utils.sendLinkToApp(Common.URL_KAKAOTALK_CHANNEL_FRIEND);
                                }
                                if (isAppBanner(image)) {
                                    if (isAndroid) {
                                        Utils.sendLinkToApp(Common.URL_ANDROID_PLAY_STORE);
                                    } else if (isIOS) {
                                        Utils.sendLinkToApp(Common.URL_IOS_APP_STORE);
                                    }
                                }
                                if (isNoEntryBanner(image) && isMobile) {
                                    navigate(Common.PAGE_NOTICE_DETAIL + "/35");
                                }
                                if (isOneWeekBanner(image) && isMobile) {
                                    navigate(Common.PAGE_NOTICE_DETAIL + "/36");
                                }
                                if (isLocalPromotionBanner(image) && isMobile) {
                                    navigate(Common.PAGE_NOTICE_DETAIL + "/25");
                                }
                            }}
                        />
                    </div>
                ))
            ) : (
                <img src={NoImage} />
            )}
        </Carousel>
    );
}

export default ImageSlider;
