import { Col, Space, Tabs } from "antd";
import type { TabsProps } from "antd";
import { BrowserView, MobileView } from "react-device-detect";

import redmaxTypeExterior1 from "../assets/images/model/redmax/Redmax-exterior-1.jpg";
import redmaxTypeExterior2 from "../assets/images/model/redmax/Redmax-exterior-2.jpg";
import redmaxTypeExterior3 from "../assets/images/model/redmax/Redmax-exterior-3.jpg";
import redmaxTypeExterior4 from "../assets/images/model/redmax/Redmax-exterior-4.jpg";
import redmaxTypeInterior1 from "../assets/images/model/redmax/Redmax-interior-1.jpg";
import redmaxTypeInterior2 from "../assets/images/model/redmax/Redmax-interior-2.jpg";
import redmaxTypeInterior3 from "../assets/images/model/redmax/Redmax-interior-3.jpg";
import redmaxTypeInterior4 from "../assets/images/model/redmax/Redmax-interior-4.jpg";
import redmaxTypeInterior5 from "../assets/images/model/redmax/Redmax-interior-5.jpg";
import redmaxTypeInterior6 from "../assets/images/model/redmax/Redmax-interior-6.jpg";
import redmaxTypeOption1 from "../assets/images/model/redmax/Redmax-option-1.jpg";
import redmaxTypeOption2 from "../assets/images/model/redmax/Redmax-option-2.jpg";
import redmaxTypeOption3 from "../assets/images/model/redmax/Redmax-option-3.jpg";
import ModelRuta40Service from "./ModelRuta40Service";
import ModelRuta40Specification from "./ModelRuta40Specification";
import Gallery from "./Gallery";
import WarrantyInfo from "./ruta40/WarrantyInfo";
import OptionTable from "./ruta40/OptionTable";
import OptionCar from "./ruta40/OptionCar";

import * as Options from "../commons/options";
import * as Common from "../commons/common";

export default function ModelRuta40Redmax() {
    const carType = "redmax";

    const items: TabsProps["items"] = [
        {
            key: "1",
            label: "전기 및 설비",
            children: (
                <Space style={{ width: "100%" }} size={16} direction="vertical">
                    <OptionCar carType={carType} options={[...Options.bayrunElectricity, ...Options.redmaxEquipment]} />
                    <OptionTable hasNo={true} options={[...Options.bayrunElectricity, ...Options.redmaxEquipment]} />
                </Space>
            ),
        },
        {
            key: "2",
            label: "냉/난방",
            children: (
                <Space style={{ width: "100%" }} size={16} direction="vertical">
                    <OptionCar carType={carType} options={Options.redmaxAirConditioning} />
                    <OptionTable hasNo={true} options={Options.redmaxAirConditioning} />
                </Space>
            ),
        },
        {
            key: "3",
            label: "가전",
            children: (
                <Space style={{ width: "100%" }} size={16} direction="vertical">
                    <OptionCar carType={carType} options={Options.redmaxAppliances} />
                    <OptionTable hasNo={true} options={Options.redmaxAppliances} />
                </Space>
            ),
        },
        {
            key: "4",
            label: "선택 옵션",
            children: (
                <Space style={{ width: "100%" }} size={16} direction="vertical">
                    <OptionCar carType={carType} options={Options.redmaxSelectOption} />
                    <OptionTable hasNo={true} options={Options.redmaxSelectOption} />
                </Space>
            ),
        },
    ];

    const displayPCContent = () => {
        return (
            <div>
                <div className="mb-5">
                    <div className="text-center mb-4">
                        <h3>디자인</h3>
                    </div>
                    <Gallery
                        showBullets={false}
                        styles="mt-3"
                        images={[
                            redmaxTypeInterior1,
                            redmaxTypeInterior2,
                            redmaxTypeInterior3,
                            redmaxTypeInterior4,
                            redmaxTypeInterior5,
                            redmaxTypeInterior6,
                            redmaxTypeOption1,
                            redmaxTypeOption2,
                            redmaxTypeOption3,
                            redmaxTypeExterior1,
                            redmaxTypeExterior2,
                            redmaxTypeExterior3,
                            redmaxTypeExterior4,
                        ]}
                    />
                </div>
                <div className="mb-5">
                    <div className="text-center mb-4">
                        <h3>옵션</h3>
                    </div>
                    <Tabs className="model-tabs tab-lg" defaultActiveKey="1" items={items} />
                    <p className="text-note">※단, 시공과정에서 제작사의 사정으로 동종의 유사제품으로 교체될 수 있습니다.</p>
                </div>
                <ModelRuta40Service />
                <div className="mb-5">
                    <div className="text-center mb-4">
                        <h3>제원 정보</h3>
                    </div>
                    <div className="col-lg-12 text-center optionBox">
                        <div className="col-md-12">
                            <ModelRuta40Specification />
                        </div>
                    </div>
                </div>
                <WarrantyInfo maker="㈜레드맥스" />
            </div>
        );
    };

    return (
        <>
            <MobileView>
                <Col xs={{ span: 22, offset: 1 }}>
                    <Col span={24} className="fadeInUp">
                        <div className="my-5">
                            <div className="text-center mb-4">
                                <h3>디자인</h3>
                            </div>
                            <div className="text-center">
                                <Gallery
                                    showBullets={false}
                                    styles="mt-3"
                                    images={[
                                        redmaxTypeInterior1,
                                        redmaxTypeInterior2,
                                        redmaxTypeInterior3,
                                        redmaxTypeInterior4,
                                        redmaxTypeInterior5,
                                        redmaxTypeInterior6,
                                        redmaxTypeOption1,
                                        redmaxTypeOption2,
                                        redmaxTypeOption3,
                                        redmaxTypeExterior1,
                                        redmaxTypeExterior2,
                                        redmaxTypeExterior3,
                                        redmaxTypeExterior4,
                                    ]}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col span={24} className="fadeInUp">
                        <div className="mb-4">
                            <div className="text-center mb-4">
                                <h3>옵션</h3>
                            </div>
                            <Tabs className="option-tabs" defaultActiveKey="1" items={items} />
                            <p className="text-note">※단, 시공과정에서 제작사의 사정으로 동종의 유사제품으로 교체될 수 있습니다.</p>
                        </div>
                    </Col>
                    <Col span={24} className="fadeInUp">
                        <ModelRuta40Service />
                    </Col>
                    <Col span={24} className="fadeInUp" style={{ marginTop: "70px" }}>
                        <div className="mb-4">
                            <div className="text-center mb-4">
                                <h3>제원 정보</h3>
                            </div>
                            <div className="col-lg-12 text-center optionBox">
                                <div className="col-md-12">
                                    <ModelRuta40Specification />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Col>
            </MobileView>
            <BrowserView>
                {location.pathname.includes(Common.PAGE_RENT_DETAIL) ? (
                    displayPCContent()
                ) : (
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 22, offset: 1 }}
                        lg={{ span: 18, offset: 3 }}
                        xl={{ span: 16, offset: 4 }}
                        xxl={{ span: 14, offset: 5 }}
                    >
                        <div className="p-4"> {displayPCContent()}</div>
                    </Col>
                )}
            </BrowserView>
        </>
    );
}
