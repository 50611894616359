import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Col, Row, Radio, Checkbox, List, Button, Input, Select, Space, Divider } from "antd";
import type { RadioChangeEvent } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import * as TypeDTO from "../commons/typeDTO";
import * as Request from "../commons/request";
import * as Common from "../commons/common";
import * as Options from "../commons/options";
import * as Utils from "../utils/utils";

import { utilAxiosWithAppToken, useAsyncAxios } from "../utils/customAxios";
import styles from "../pages/ruta40/Order.module.css";
import moment from "moment";
import DefaultModal from "./DefaultModal";
import ModelRuta40Specification from "./ModelRuta40Specification";
import OptionTable from "./ruta40/OptionTable";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useMediaQuery } from "react-responsive";

declare const window: typeof globalThis & {
    webkit: any;
};

type OptionOrderType = {
    label: JSX.Element;
    value: number;
    price: number;
    type?: string;
};

// type OptionTypeOrdersType = {
//     optionTypeId: number;
//     optionTypeName: string;
//     options: Array<TypeDTO.OptionDto>;
// };

function OrderSelect({
    carTypes,
    carTypeId,
    onChangeOrder,
    onChangeData,
}: {
    carTypes?: TypeDTO.CarTypeDto[];
    carTypeId?: number;
    onChangeOrder: (orderData: TypeDTO.OrderDto) => void;
    onChangeData?: boolean;
}) {
    const isMinMobile = useMediaQuery({ maxWidth: "300px" });
    const { Option } = Select;

    const [currentCarTypeId, setCurrentCarTypeId] = useState<number>();
    const [currentCarType, setCurrentCarType] = useState<TypeDTO.CarTypeDto>();

    const [currentColorId, setCurrentColorId] = useState<number>();
    const [currentColor, setCurrentColor] = useState<TypeDTO.ColorDto>();

    const [currentOptionIds, setCurrentOptionIds] = useState<Array<number>>([]);
    const [currentOptions, setCurrentOptions] = useState<Array<TypeDTO.OptionDto>>();

    const [orderCarTypes, setOrderCarTypes] = useState<Array<OptionOrderType>>();
    const [orderColors, setOrderColors] = useState<Array<OptionOrderType>>();
    const [orderOptions, setOrderOptions] = useState<Array<OptionOrderType>>();

    const [optionsList, setOptionsList] = useState<Array<TypeDTO.OptionDto>>([]);
    const [optionTypesList, setOptionTypesList] = useState<Array<TypeDTO.OptionTypeDto>>([]);
    const [selectedRadioOption, setSelectedRadioOption] = useState<Array<number>>();
    const [orderConveniences, setOrderConveniences] = useState<Array<OptionOrderType>>();

    const [currentConveniencesIds, setCurrentConveniencesIds] = useState<Array<number>>();
    const [currentConveniences, setCurrentConveniences] = useState<Array<TypeDTO.ConvenienceDto>>();
    const [convenienceList, setConvenienceList] = useState<Array<TypeDTO.ConvenienceDto>>();

    const [currentNationalSubsidy, setCurrentNationalSubsidy] = useState<TypeDTO.NationalSubsidyDto>();
    const [currentSiDoId, setCurrentSiDoId] = useState<number>();
    // const [currentSiDo, setCurrentSiDo] = useState<TypeDTO.SiDoDto>();
    const [currentSiGunGuId, setCurrentSiGunGuId] = useState<number>();
    const [currentLocalSubsidy, setCurrentLocalSubsidy] = useState<TypeDTO.LocalSubsidyDto>();

    const [siDoList, setSiDoList] = useState<Array<TypeDTO.SiDoDto>>();
    const [siGunGuList, setSiGunGuList] = useState<Array<TypeDTO.SiGunGuDto>>();
    const [orderSelectResult, setOrderSelectResult] = useState<boolean>();
    const [subsidySiGunPrice, setSubsidySiGunPrice] = useState<number>(0);

    const [showTypeDetailPopup, setShowTypeDetailPopup] = useState<boolean>(false);
    const [showFeaturesDetailPopup, setShowFeaturesDetailPopup] = useState<boolean>(false);
    const [showOptionDetailPopup, setShowOptionDetailPopup] = useState<boolean>(false);
    const [showSubsibyDetailPopup, setShowSubsibyDetailPopup] = useState<boolean>(false);

    useEffect(() => {
        requestGetConvenience();
        requestGetSubsidy();
    }, []);

    useEffect(() => {
        if (carTypes !== undefined && carTypes.length > 0 && carTypes[0].typeId !== undefined) {
            if (carTypeId !== undefined) setCurrentCarTypeId(carTypeId);
            else setCurrentCarTypeId(carTypes[0].typeId);

            setOrderCarTypes(
                carTypes?.map((object) => {
                    const name = object?.model?.modelName + " " + object.typeName;
                    return makeOptionData(object.typeId, name, object.price);
                })
            );
        }
    }, [carTypes, carTypeId]);

    useEffect(() => {
        if (carTypes !== undefined && carTypes.length > 0 && carTypes[0].typeId !== undefined) {
            setOptionTypesList([]);
            requestGetOptionTypes(currentCarTypeId);
            setCurrentCarType(carTypes.filter((carType) => carType.typeId === currentCarTypeId)[0]);
        }
    }, [currentCarTypeId]);

    useEffect(() => {
        if (currentCarType !== undefined) {
            setCurrentColorId(currentCarType.colors[0].colorId);

            setOrderColors(
                currentCarType.colors.map((color) => {
                    return makeOptionData(color.colorId, color.colorName, 0);
                })
            );

            // const carOption = currentCarType.options.map((option) => {
            //     return makeOptionData(option.optionId, option.optionName, option.price, option.optionType.optionTypeName);
            // });

            // setOrderOptions(carOption);
            // convertOptionIds([]);

            // const radioOptions = currentCarType?.options?.filter((option) => option.radioGroupId !== null);
            // const defaultRadioOptionId: Array<number> = [];
            // if (radioOptions !== undefined && radioOptions.length > 0) defaultRadioOptionId.push(radioOptions[0].optionId);
            // if (selectedRadioOption !== undefined) {
            //     setSelectedRadioOption(selectedRadioOption);
            // } else {
            //     setSelectedRadioOption(defaultRadioOptionId);
            // }
        }
    }, [currentCarType]);

    useEffect(() => {
        if (currentCarType !== undefined) {
            setCurrentColor(currentCarType.colors.filter((color) => color.colorId === currentColorId)[0]);
        }
    }, [currentColorId]);

    useEffect(() => {
        // console.log("currentOptionIds", currentOptionIds);
        if (optionsList.length > 0) {
            const selectOptions = optionsList.filter((option) => currentOptionIds?.includes(option.optionId));

            setCurrentOptions(selectOptions);
        }
    }, [currentOptionIds]);

    useEffect(() => {
        if (currentConveniencesIds === undefined || currentConveniencesIds.length === 0) {
            setCurrentConveniences(undefined);
        } else if (convenienceList !== undefined) {
            const selectConveniences = convenienceList.filter((convenience) => currentConveniencesIds?.includes(convenience.convenienceId));

            setCurrentConveniences(selectConveniences);
        }
    }, [currentConveniencesIds]);

    useEffect(() => {
        if (currentSiDoId === undefined || siDoList === undefined) {
            // setCurrentSiDo(undefined);
            setSiGunGuList(undefined);
        } else {
            const siDo = siDoList?.filter((siDo) => siDo.siDoId === currentSiDoId)[0];
            if (siDo !== undefined) {
                // setCurrentSiDo(siDo);
                setSiGunGuList(siDo.siGunGus);
            }
        }
    }, [currentSiDoId]);

    useEffect(() => {
        if (currentSiGunGuId === undefined) {
            setCurrentLocalSubsidy(undefined);
        } else {
            const siGunGu = siGunGuList?.filter((siGunGu) => siGunGu.siGunGuId === currentSiGunGuId)[0];
            if (siGunGu !== undefined) {
                setCurrentLocalSubsidy(siGunGu.localSubsidy);
            }
        }
    }, [currentSiGunGuId]);

    useEffect(() => {
        if (currentCarType !== undefined && currentColor !== undefined) {
            const orderData: TypeDTO.OrderDto = {};
            orderData.carType = currentCarType;
            orderData.color = currentColor;
            if (currentOptions !== undefined && currentOptions.length > 0) {
                orderData.options = currentOptions;
            }

            // if (currentConveniences !== undefined && currentConveniences.length > 0) orderData.conveniences = currentConveniences;

            if (currentNationalSubsidy !== undefined && currentNationalSubsidy !== null) orderData.nationalSubsidy = currentNationalSubsidy;
            if (currentLocalSubsidy !== undefined) orderData.localSubsidy = currentLocalSubsidy;

            const { carPrice, carPriceVAT, subsidyPrice, subsidySiGunPrice } = Utils.getPriceFromOrderData(orderData);

            orderData.totalPrice = carPrice + carPriceVAT /*+ conveniencesPrice*/;
            orderData.payments = [{ paymentType: "DEPOSIT", amount: Common.DEPOSIT, paymentMethod: "ONLINE" }];

            console.log("orderData", orderData);

            onChangeOrder(orderData);
        }
    }, [currentCarType, currentColor, currentOptions, /*currentConveniences,*/ currentNationalSubsidy, currentLocalSubsidy]);

    const requestAxiosGetConvenience = async (modelId: number) => {
        const response = await utilAxiosWithAppToken().get(Request.CONVENIENCE_LIST_URL);
        return response.data;
    };

    const {
        loading: loadingGetConvenience,
        error: errorGetConvenience,
        data: resultGetConvenience,
        execute: requestGetConvenience,
    } = useAsyncAxios(requestAxiosGetConvenience);

    useEffect(() => {
        if (resultGetConvenience === null) return;

        setConvenienceList(resultGetConvenience.conveniences);
    }, [resultGetConvenience]);

    useEffect(() => {
        if (errorGetConvenience === null) return;
    }, [errorGetConvenience]);

    const requestAxiosGetSubsidy = async () => {
        const response = await utilAxiosWithAppToken().get(Request.SUBSIDIES_URL);
        return response.data;
    };

    const {
        loading: loadingGetSubsidy,
        error: errorGetSubsidy,
        data: resultGetSubsidy,
        execute: requestGetSubsidy,
    } = useAsyncAxios(requestAxiosGetSubsidy);

    useEffect(() => {
        if (resultGetSubsidy === null) return;

        setCurrentNationalSubsidy(resultGetSubsidy.subsidy.nationalSubsidy);
        setSiDoList(resultGetSubsidy.subsidy.siDos);
    }, [resultGetSubsidy]);

    useEffect(() => {
        if (errorGetSubsidy === null) return;
    }, [errorGetSubsidy]);

    const requestAxiosGetOptionTypes = async (carTypeId: string) => {
        const response = await utilAxiosWithAppToken().get(Request.OPTION_TYPE_LIST_URL + "?carTypeId=" + carTypeId);
        return response.data;
    };

    const {
        loading: loadingGetOptionTypes,
        error: errorGetOptionTypes,
        data: resultGetOptionTypes,
        execute: requestGetOptionTypes,
    } = useAsyncAxios(requestAxiosGetOptionTypes);

    useEffect(() => {
        if (resultGetOptionTypes === null) return;
        console.log("resultGetOptionTypes", resultGetOptionTypes);

        setOptionTypesList(resultGetOptionTypes.optionTypes);
    }, [resultGetOptionTypes]);

    useEffect(() => {
        if (errorGetOptionTypes === null) return;
    }, [errorGetOptionTypes]);

    useEffect(() => {
        const obj: {
            [key: string]: Array<TypeDTO.OptionDto>;
        } = {};

        const optionList: TypeDTO.OptionDto[] = [];
        optionTypesList.forEach((optionType) => {
            optionType.options.forEach((option) => {
                if (option.radioGroupId !== null) {
                    if (!obj[option.radioGroupId]) obj[option.radioGroupId] = [];
                    obj[option.radioGroupId].push(option);
                }

                optionList.push(option);
            });
        });

        setOptionsList(optionList);

        const defaultOptionIds: number[] = [];
        const radioGroupIds = Object.keys(obj);
        radioGroupIds.forEach((radioGroupId) => {
            const radioGroupOptionList = obj[radioGroupId];
            if (radioGroupOptionList.length > 0) {
                const defaultOptionId = radioGroupOptionList[0].optionId;
                defaultOptionIds.push(defaultOptionId);
            }
        });
        setCurrentOptionIds(defaultOptionIds);
    }, [optionTypesList]);

    const displaySubsibyDate = () => {
        if (currentNationalSubsidy !== undefined) {
            const subsibyYear = moment(currentNationalSubsidy.referenceDate).format("YYYY");
            const date = "※ 전기차 보조금 : " + subsibyYear + "년 기준";

            return (
                <>
                    <p style={{ color: "#E21D12" }}>
                        {date}
                        <br />※ 지자체 보조금이 0원 일 경우에는 각 지자체로 직접 연락하여 확인해 주세요.
                        <br />※ 보조금 문의 시 차량 모델은 비바모빌리티 VBUS60으로 확인해 주세요.
                    </p>
                </>
            );
        }

        return null;
    };

    useEffect(() => {
        if (convenienceList !== undefined) {
            let convenienceIds: Array<number> = [];
            const carConveniences = convenienceList.map((convenience) => {
                convenienceIds = [...convenienceIds, convenience.convenienceId];
                return makeOptionData(convenience.convenienceId, convenience.convenienceName, convenience.price);
            });

            setOrderConveniences(carConveniences);
            setCurrentConveniencesIds(convenienceIds);
        }
    }, [convenienceList]);

    useEffect(() => {
        if (siGunGuList !== undefined && siGunGuList.length > 0) {
            setCurrentSiGunGuId(siGunGuList[0].siGunGuId);
        } else {
            setCurrentSiGunGuId(undefined);
        }
    }, [siGunGuList]);

    const makeOptionData = (id: number, name: string, price: number, type?: string) => {
        return {
            label: makeNameNPriceItem(name, price),
            value: id,
            price: price,
            type: type,
        };
    };

    const makeNameNPriceItem = (name: string, price: number, isList?: boolean) => {
        return (
            <Row>
                <Col xs={15} lg={16} style={{ textAlign: "left", lineHeight: "normal" }}>
                    <p className="b-md mb-0">{isList ? "+ " + name : name}</p>
                </Col>
                <Col xs={9} lg={8} style={{ textAlign: "right" }}>
                    <p className="b-md mb-0">
                        {price === 0 ? "포함" : <NumericFormat value={price} thousandSeparator={true} prefix={"₩"} displayType="text" />}
                    </p>
                </Col>
            </Row>
        );
    };

    const handleSubsibyDetailView = () => {
        Utils.sendLinkToApp("https://www.ev.or.kr/portal/localInfo");
    };

    const onChangeCarType = ({ target }: RadioChangeEvent) => {
        setCurrentCarTypeId(target.value);
    };

    const onChangeColor = ({ target }: RadioChangeEvent) => {
        setCurrentColorId(target.value);
    };

    const onChangeOption = (checked: boolean, id: number) => {
        const optionId = id;
        if (checked) {
            setCurrentOptionIds([...currentOptionIds, optionId]);
        } else {
            const temp = [...currentOptionIds];
            const index = temp.indexOf(optionId);
            if (index != -1) {
                temp.splice(index, 1);
            }
            setCurrentOptionIds(temp);
        }
    };

    const onChangeRadioOption = (value: number, optionTypeId: number) => {
        const options = optionTypesList.filter((optionType) => optionType.optionTypeId === optionTypeId)[0].options;

        const radioOption = options.filter((option) => option.optionId === value)[0];
        const otherRadioOptions = options.filter(
            (option) => option.radioGroupId === radioOption.radioGroupId && option.optionId !== radioOption.optionId
        );

        const tempCurrentOption = [...currentOptionIds];
        tempCurrentOption.push(radioOption.optionId);
        otherRadioOptions.forEach((radioOption) => {
            const index = tempCurrentOption.indexOf(radioOption.optionId);
            if (index != -1) {
                tempCurrentOption.splice(index, 1);
            }
        });
        setCurrentOptionIds(tempCurrentOption);
    };

    // useEffect(() => {
    //     const radioOptions = currentCarType?.options?.filter((option) => option.radioGroupId !== null);
    //     const defaultRadioOptionId: Array<number> = [];
    //     if (radioOptions !== undefined && radioOptions.length > 0) defaultRadioOptionId.push(radioOptions[0].optionId);
    //     if (selectedRadioOption !== undefined) {
    //         convertOptionIds(selectedRadioOption);
    //     } else {
    //         convertOptionIds(defaultRadioOptionId);
    //     }
    // }, [selectedRadioOption]);

    // const convertOptionIds = (optionIds: number[]) => {
    //     const checkboxOptions = currentCarType?.options?.filter((option) => option.radioGroupId === null);

    //     const selectedCheckboxOptions = checkboxOptions?.filter((option) => optionIds.includes(option.optionId));

    //     if (selectedRadioOption !== undefined && selectedCheckboxOptions !== undefined) {
    //         const newOptionIds = selectedRadioOption;
    //         selectedCheckboxOptions.map((checkboxOption) => newOptionIds.push(checkboxOption.optionId));
    //         console.log("newOptionIds: ", newOptionIds);
    //         setCurrentOptionIds(newOptionIds);
    //     }
    // };

    const onChangeSiDo = (value: number | undefined) => {
        if (value === undefined) setCurrentSiGunGuId(undefined);
        setCurrentSiDoId(value);
    };

    const displaySubsiby = (subsidyPrice: number, subsidySiGunPrice: number) => {
        if (currentLocalSubsidy === undefined) {
            return (
                <Space className="w-100" direction="vertical">
                    {/* <div>
                        <Row>
                            <Col span={12} style={{ textAlign: "left" }}>
                                <p>국고</p>
                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                                <p>-</p>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={12} style={{ textAlign: "left" }}>
                                <p>지자체</p>
                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                                <p>-</p>
                            </Col>
                        </Row>
                    </div> */}
                    <Row>
                        <Col span={12} style={{ textAlign: "left" }}>
                            <p className="b-bd">보조금</p>
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                            <p>-</p>
                        </Col>
                    </Row>
                </Space>
            );
        }

        return (
            <Space className="w-100" direction="vertical">
                {/* <div>
                    {subsidyPrice > 0 && (
                        <Row>
                            <Col span={12} style={{ textAlign: "left" }}>
                                <p>국고</p>
                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                                <p>
                                    <NumericFormat value={subsidyPrice * -1} thousandSeparator={true} prefix={"₩"} displayType="text" />
                                </p>
                            </Col>
                        </Row>
                    )}
                    {subsidySiGunPrice > 0 && (
                        <Row>
                            <Col span={12} style={{ textAlign: "left" }}>
                                <p>지자체</p>
                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                                <p>
                                    <NumericFormat value={subsidySiGunPrice * -1} thousandSeparator={true} prefix={"₩"} displayType="text" />
                                </p>
                            </Col>
                        </Row>
                    )}
                </div> */}
                {subsidyPrice > 0 && (
                    <Row>
                        <Col span={12} style={{ textAlign: "left" }}>
                            <p className="b-bd">보조금</p>
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                            <p>
                                <NumericFormat
                                    value={(subsidyPrice + subsidySiGunPrice) * -1}
                                    thousandSeparator={true}
                                    prefix={"₩"}
                                    displayType="text"
                                />
                            </p>
                        </Col>
                    </Row>
                )}
            </Space>
        );
    };

    const orderSelectList = () => {
        const orderData: TypeDTO.OrderDto = {};
        orderData.carType = currentCarType;
        orderData.color = currentColor;
        if (currentOptions !== undefined && currentOptions.length > 0) {
            orderData.options = currentOptions;
        }

        // if (currentConveniences !== undefined && currentConveniences.length > 0) orderData.conveniences = currentConveniences;

        if (currentNationalSubsidy !== undefined && currentNationalSubsidy !== null) orderData.nationalSubsidy = currentNationalSubsidy;
        if (currentLocalSubsidy !== undefined) orderData.localSubsidy = currentLocalSubsidy;

        const { carPrice, carPriceVAT, subsidyPrice, subsidySiGunPrice } = Utils.getPriceFromOrderData(orderData);

        return (
            <Space size={8} direction="vertical" className={`${styles.priceBody} w-100`}>
                <h5 style={{ margin: 0 }}>차량 구성 및 가격</h5>
                <Space size={8} direction="vertical" className="w-100">
                    <div>
                        <p className="b-bd">차량가</p>
                        <List className="list-border-none">
                            {currentCarType && (
                                <>
                                    <List.Item style={{ border: "none" }}>
                                        <Row align="bottom">
                                            <Col span={14} style={{ textAlign: "left" }}>
                                                <p className="b-md">{currentCarType.model.modelName + " " + currentCarType.typeName}</p>
                                            </Col>
                                            <Col span={10} style={{ textAlign: "right" }}>
                                                {/* {currentCarType.discountedPrice ? (
                                                    <p
                                                        className="mr-2 b-md"
                                                        style={{
                                                            fontSize: 12,
                                                            color: "#1C304A85",
                                                            textDecoration: "line-through",
                                                            position: "absolute",
                                                            bottom: 20,
                                                            right: 0,
                                                        }}
                                                    >
                                                        <NumericFormat
                                                            value={currentCarType.price}
                                                            thousandSeparator={true}
                                                            prefix={"₩"}
                                                            displayType="text"
                                                        />
                                                    </p>
                                                ) : (
                                                    <NumericFormat
                                                        value={currentCarType.price}
                                                        thousandSeparator={true}
                                                        prefix={"₩"}
                                                        displayType="text"
                                                    />
                                                )}
                                                {currentCarType.discountedPrice && (
                                                    <p style={{ color: "#E21D12", textAlign: "right" }} className="b-md">
                                                        &nbsp;
                                                        <NumericFormat
                                                            value={currentCarType.discountedPrice}
                                                            thousandSeparator={true}
                                                            prefix={"₩"}
                                                            displayType="text"
                                                        />
                                                        {!isMinMobile && "(특별할인가)"}
                                                    </p>
                                                )} */}
                                                <p style={{ /*color: "#E21D12",*/ textAlign: "right" }} className="b-md">
                                                    &nbsp;
                                                    <NumericFormat
                                                        value={currentCarType.price}
                                                        thousandSeparator={true}
                                                        prefix={"₩"}
                                                        displayType="text"
                                                    />
                                                    {/* {!isMinMobile && "(특별할인가)"} */}
                                                </p>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                </>
                            )}
                            {currentColor && <List.Item>{makeNameNPriceItem(currentColor.colorName, 0)}</List.Item>}
                        </List>
                    </div>

                    {currentCarType && currentCarType.additionalPrice > 0 && (
                        <div>
                            <p className="b-bd">캠핑카 특장</p>
                            <List className="list-border-none">
                                <List.Item style={{ border: "none" }}>
                                    <Row align="bottom">
                                        <Col span={14} style={{ textAlign: "left" }}>
                                            <p className="b-md">{currentCarType.additionalCompany}</p>
                                        </Col>
                                        <Col span={10} style={{ textAlign: "right" }}>
                                            {/* {currentCarType.discountedAdditionalPrice ? (
                                                <p
                                                    className="mr-2 b-md"
                                                    style={{
                                                        fontSize: 12,
                                                        color: "#1C304A85",
                                                        textDecoration: "line-through",
                                                        position: "absolute",
                                                        bottom: 20,
                                                        right: 0,
                                                    }}
                                                >
                                                    <NumericFormat
                                                        value={currentCarType.additionalPrice}
                                                        thousandSeparator={true}
                                                        prefix={"₩"}
                                                        displayType="text"
                                                    />
                                                </p>
                                            ) : (
                                                <NumericFormat
                                                    value={currentCarType.additionalPrice}
                                                    thousandSeparator={true}
                                                    prefix={"₩"}
                                                    displayType="text"
                                                />
                                            )}
                                            {currentCarType.discountedAdditionalPrice && (
                                                <p style={{ color: "#E21D12", textAlign: "right" }} className="b-md">
                                                    &nbsp;
                                                    <NumericFormat
                                                        value={currentCarType.discountedAdditionalPrice}
                                                        thousandSeparator={true}
                                                        prefix={"₩"}
                                                        displayType="text"
                                                    />
                                                    {!isMinMobile && "(특별할인가)"}
                                                </p>
                                            )} */}
                                            <p style={{ /* color: "#E21D12", */ textAlign: "right" }} className="b-md">
                                                &nbsp;
                                                <NumericFormat
                                                    value={currentCarType.additionalPrice}
                                                    thousandSeparator={true}
                                                    prefix={"₩"}
                                                    displayType="text"
                                                />
                                                {/* {!isMinMobile && "(특별할인가)"} */}
                                            </p>
                                        </Col>
                                    </Row>
                                </List.Item>
                            </List>
                        </div>
                    )}
                    {currentOptions && currentOptions.length > 0 && optionTypesList.length > 0 && (
                        <div>
                            <p className="b-bd">선택 옵션</p>
                            <List className="list-border-none">
                                {currentOptions.map((option) => {
                                    const optionType = optionTypesList.filter(
                                        (optionType) => optionType.options?.filter((tempOption) => tempOption.optionId === option.optionId).length > 0
                                    )[0];

                                    if (option.price === 0 && optionType?.optionTypeName === "선팅") return null;
                                    return <List.Item key={option.optionId}>{makeNameNPriceItem(option.optionName, option.price, true)}</List.Item>;
                                })}
                            </List>
                        </div>
                    )}
                    <Row>
                        <Col span={12}>
                            <p>부가세(10%)</p>
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                            <NumericFormat value={carPriceVAT} thousandSeparator={true} prefix={"₩"} displayType="text" />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <p className="b-bd">소계</p>
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                            <p>
                                <NumericFormat value={carPrice + carPriceVAT} thousandSeparator={true} prefix={"₩"} displayType="text" />
                            </p>
                        </Col>
                    </Row>
                </Space>
                <Divider className="my-0" />
                {/* 편의사항 숨김 */}
                {/* <h6 className="mt-3">편의사항</h6>
                <List className="list-border-none" bordered>
                    {currentConveniences &&
                        currentConveniences.map((convenience) => {
                            return (
                                <List.Item key={convenience.convenienceId}>
                                    {makeNameNPriceItem(convenience.convenienceName, convenience.price)}
                                </List.Item>
                            );
                        })}
                </List>
                 <>
                    <Row className="mt-1">
                        <Col span={12} style={{ textAlign: "left" }}>
                            <h6>소계</h6>
                        </Col>
                        <Col span={12} style={{ textAlign: "right" }}>
                            <h6>
                                <NumericFormat value={conveniencesPrice} thousandSeparator={true} prefix={"₩"} displayType="text" />
                            </h6>
                        </Col>
                    </Row>
                </> */}
                <div>
                    {/* <p className="b-bd">보조금</p> */}
                    {displaySubsiby(subsidyPrice, subsidySiGunPrice)}
                </div>
                <Divider className="my-0" />
                <Row align="middle">
                    <Col span={12} style={{ textAlign: "left" }}>
                        <p className="b-bd ">예상 주문 금액</p>
                    </Col>
                    <Col span={12} style={{ textAlign: "right" }}>
                        <h5 className={`${styles.textPrice} mb-0`}>
                            <NumericFormat
                                value={carPrice + carPriceVAT + (currentLocalSubsidy !== undefined ? (subsidyPrice + subsidySiGunPrice) * -1 : 0)}
                                thousandSeparator={true}
                                prefix={"₩"}
                                displayType="text"
                            />
                        </h5>
                    </Col>
                </Row>
                <p style={{ color: "#E21D12" }}>※ 개소세 및 구조변경 비용 별도</p>
            </Space>
        );
    };

    type groupedType = { groupId: number; options: Array<TypeDTO.OptionDto> };

    const groupedOption = (optionTypeId: number, options: Array<TypeDTO.OptionDto>) => {
        const groupedByGroupId: groupedType[] = [];
        const nullTypeOptions: TypeDTO.OptionDto[] = [];

        options.forEach((option) => {
            if (option.radioGroupId === null) {
                nullTypeOptions.push(option);
            } else {
                const existingGroup = groupedByGroupId.find((group) => group.groupId === option.radioGroupId);

                if (existingGroup) {
                    existingGroup.options.push(option);
                } else {
                    groupedByGroupId.push({ groupId: option.radioGroupId, options: [option] });
                }
            }
        });

        return (
            <>
                {groupedByGroupId.map((group) => (
                    <Radio.Group
                        key={group.groupId}
                        defaultValue={group.options[0].optionId}
                        onChange={(e) => onChangeRadioOption(e.target.value, optionTypeId)}
                        className="orderButtons"
                        size="large"
                        optionType="button"
                    >
                        {group.options.map((option) => (
                            <Radio key={option.optionId} style={{ marginBottom: 8 }} value={option.optionId}>
                                {makeNameNPriceItem(option.optionName, option.price)}
                            </Radio>
                        ))}
                    </Radio.Group>
                ))}

                {nullTypeOptions.map((option) => {
                    return (
                        <Checkbox
                            className="orderCheckbox"
                            key={option.optionId}
                            style={{ marginBottom: 8, marginLeft: 0 }}
                            onChange={(e) => onChangeOption(e.target.checked, option.optionId)}
                        >
                            {makeNameNPriceItem(option.optionName, option.price)}
                        </Checkbox>
                    );
                })}
            </>
        );
    };

    return (
        <div className={styles.body}>
            <div className="mb-5">
                <div className="text-center">
                    <h5 className="section-title bg-white text-center text-dark px-3 mb-4">{currentCarType?.model?.category?.categoryName}</h5>
                    <h1 className="mb-5 fs-title">{currentCarType?.model.modelName}</h1>
                </div>
                <Space size={16} style={{ width: "100%" }} direction="vertical">
                    <div>
                        <Row>
                            <Col span={12} style={{ textAlign: "left" }}>
                                <h5 style={{ margin: 0 }}>타입</h5>
                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                                <a className={`${styles.textPrice} ${styles.textDetail}`} onClick={() => setShowTypeDetailPopup(true)}>
                                    상세 보기
                                </a>
                            </Col>
                        </Row>
                        <hr />
                        <Radio.Group
                            className="orderButtons"
                            options={orderCarTypes}
                            onChange={onChangeCarType}
                            value={currentCarTypeId}
                            size="large"
                            optionType="button"
                        />
                    </div>
                    <div>
                        <Row className="mt-3">
                            <Col span={24} style={{ textAlign: "left" }}>
                                <h5 style={{ margin: 0 }}>색상</h5>
                            </Col>
                        </Row>
                        <hr />
                        <Radio.Group
                            className="orderButtons"
                            options={orderColors}
                            onChange={onChangeColor}
                            value={currentColorId}
                            size="large"
                            optionType="button"
                        />
                    </div>
                    {currentCarType && currentCarType.additionalPrice > 0 && (
                        <div>
                            <Row className="mt-3">
                                <Col span={12} style={{ textAlign: "left" }}>
                                    <h5 style={{ margin: 0 }}>캠핑카 특장</h5>
                                </Col>
                                <Col span={12} style={{ textAlign: "right" }} className={styles.textPrice}>
                                    <a className={`${styles.textPrice} ${styles.textDetail}`} onClick={() => setShowOptionDetailPopup(true)}>
                                        상세 보기
                                    </a>
                                </Col>
                            </Row>
                            <hr />
                            <div className="orderButtons">
                                <Checkbox checked={true}>
                                    {currentCarType && makeNameNPriceItem(currentCarType.additionalCompany, currentCarType.additionalPrice)}
                                </Checkbox>
                            </div>
                        </div>
                    )}

                    <div>
                        <Row className="mt-3">
                            <Col span={12} style={{ textAlign: "left" }}>
                                <h5 style={{ margin: 0 }}>선택 옵션</h5>
                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                                <a className={`${styles.textPrice} ${styles.textDetail}`} onClick={() => setShowFeaturesDetailPopup(true)}>
                                    상세 보기
                                </a>
                            </Col>
                        </Row>
                        <hr />
                        {optionTypesList?.map((type, index) => {
                            return (
                                <div style={{ paddingBottom: index === optionTypesList.length - 1 ? 0 : 8 }} key={type.optionTypeId}>
                                    <p style={{ fontSize: 16, fontWeight: "bold", marginBottom: 4 }}>{type.optionTypeName}</p>
                                    {groupedOption(type.optionTypeId, type.options)}
                                </div>
                            );
                        })}
                    </div>
                    {/* 편의사항 숨김 */}
                    {/* {orderConveniences && orderConveniences.length > 0 ? (
                    <>
                        <Row className="mt-5">
                            <Col span={12} style={{ textAlign: "left" }}>
                                <h5>편의사항</h5>
                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                                <a className={`${styles.textPrice} ${styles.textDetail}`}  onClick={() => handleOptionConvenienceView()}>
                                    상세 보기
                                </a>
                            </Col>
                        </Row>{" "}
                        <Checkbox.Group
                            className="orderButtons"
                            options={orderConveniences}
                            onChange={onChangeConvenience}
                            value={currentConveniencesIds}
                        />
                    </>
                ) : null} */}

                    {siDoList && siDoList.length > 0 ? (
                        <>
                            <div>
                                <Row className="mt-3">
                                    <Col span={15} style={{ textAlign: "left" }}>
                                        <h5 style={{ margin: 0 }}>전기차 보조금 신청 지역</h5>
                                    </Col>
                                    <Col span={9} style={{ textAlign: "right" }}>
                                        <a className={`${styles.textPrice} ${styles.textDetail}`} onClick={() => setShowSubsibyDetailPopup(true)}>
                                            전기차 보조금 현황
                                        </a>
                                    </Col>
                                </Row>
                                <hr />
                                <Select className="orderButtons select" allowClear placeholder="광역시/도" onChange={(value) => onChangeSiDo(value)}>
                                    {siDoList.map((siDo: TypeDTO.SiDoDto) => (
                                        <Option key={siDo.siDoId} value={siDo.siDoId}>
                                            {siDo.siDoName}
                                        </Option>
                                    ))}
                                </Select>
                                {siGunGuList && siGunGuList.length > 0 ? (
                                    <Select
                                        className="orderButtons select mt-2"
                                        placeholder="시/군/구"
                                        value={currentSiGunGuId}
                                        onChange={(value) => setCurrentSiGunGuId(value)}
                                        optionFilterProp="children"
                                    >
                                        {siGunGuList.map((siGunGu: TypeDTO.SiGunGuDto) => (
                                            <Option key={siGunGu.siGunGuId} value={siGunGu.siGunGuId}>
                                                {siGunGu.siGunGuName}
                                            </Option>
                                        ))}
                                    </Select>
                                ) : null}
                            </div>
                            {/* {displaySubsibyDate()} */}
                        </>
                    ) : null}
                </Space>
            </div>
            <div className="mb-5">{orderSelectList()}</div>
            <DefaultModal
                isOpen={showTypeDetailPopup}
                title="차량 제원 정보"
                content={<ModelRuta40Specification />}
                onChange={() => setShowTypeDetailPopup(false)}
                padding="0"
            />
            <DefaultModal
                isOpen={showOptionDetailPopup}
                title="기본 옵션 정보"
                content={
                    <OptionTable
                        options={
                            currentCarType?.typeName.includes("배이런(3인승)")
                                ? Options.bayrun3Appliances
                                : currentCarType?.typeName.includes("배이런(4인승)")
                                ? Options.bayrun4Appliances
                                : currentCarType?.typeName.includes("레드맥스")
                                ? Options.redmaxAppliances
                                : []
                        }
                    />
                } //TODO
                onChange={() => setShowOptionDetailPopup(false)}
                padding="0"
            />
            <DefaultModal
                isOpen={showFeaturesDetailPopup}
                title="선택 옵션 정보"
                content={
                    <OptionTable
                        options={
                            currentCarType?.typeName.includes("배이런")
                                ? Options.bayrunSelectOption
                                : currentCarType?.typeName.includes("레드맥스")
                                ? Options.redmaxSelectOption
                                : []
                        }
                    />
                }
                onChange={() => setShowFeaturesDetailPopup(false)}
                padding="0"
            />
            <DefaultModal
                isOpen={showSubsibyDetailPopup}
                title="전기차 보조금 현황"
                content={
                    <Space className="p-3" direction="vertical">
                        <span>현재 판매중인 차량은 24년 수입차량으로 보조금 6,000만원 적용된 차량입니다.</span>
                    </Space>
                }
                onChange={() => setShowSubsibyDetailPopup(false)}
                padding="0"
            />
        </div>
    );
}

export default OrderSelect;
