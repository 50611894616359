import React from "react";
import { Col, Row, Space } from "antd";

import Banner from "../../assets/images/home/ruta40/banner_travelGuide.jpg";
import iconMap from "../../assets/images/home/rent/icon_map_blue.png";
import iconFacility from "../../assets/images/home/rent/icon_home_blue.png";
import iconDuration from "../../assets/images/home/rent/icon_clock_blue.png";
import iconOption from "../../assets/images/home/rent/icon_check_blue.png";
import RentPlaceMap from "../../component/rent/RentPlaceMap";

export const allPlaces = [
    {
        rentRegionDescription: "나정고운모래해변",
        address: "경북 경주시 감포읍 나정리 633-4",
        latitude: 35.78332658128988,
        longitude: 129.49060862249343,
        description: {
            facility: "공중화장실, 개수대 등 편의시설",
            duration: 35,
            option: "청수 보급 가능(유료)",
            warning: "오토캠핑장 예약 필수",
        },
        marker: [{ facilityType: "toilet", facilityLatitude: 35.78231479859171, facilityLongitude: 129.49073737290334 }],
        region: [
            { lat: 35.782252268755826, lng: 129.49027654388595 },
            { lat: 35.7824296372766, lng: 129.4909565749352 },
            { lat: 35.78323865553764, lng: 129.4908297907792 },
            { lat: 35.78446485522986, lng: 129.49100628884125 },
            { lat: 35.78453609276817, lng: 129.4903948161004 },
            { lat: 35.78290955157028, lng: 129.4901892472445 },
        ],
    },
    {
        rentRegionDescription: "나아해변",
        address: "경북 경주시 양남면 나아리 976",
        latitude: 35.701963688875814,
        longitude: 129.47260945887692,
        description: {
            facility: "식수대, 공중화장실",
            duration: 45,
        },
        marker: [
            { facilityType: "toilet", facilityLatitude: 35.70106601601453, facilityLongitude: 129.47288549357805 },
            { facilityType: "toilet", facilityLatitude: 35.70247806011945, facilityLongitude: 129.47158423855012 },
        ],
        region: [
            { lat: 35.70125079393476, lng: 129.47277245702472 },
            { lat: 35.701270014418526, lng: 129.47293321944778 },
            { lat: 35.70276988156425, lng: 129.47251009522768 },
            { lat: 35.702703667466146, lng: 129.47200544251308 },
            { lat: 35.7024163644712, lng: 129.47239423413976 },
        ],
    },
    {
        rentRegionDescription: "오류고아라해변",
        address: "경북 경주시 감포읍 오류리 277-6",
        latitude: 35.82215177289595,
        longitude: 129.5087256100641,
        description: {
            facility: "공중화장실, 전기차 충전소",
            duration: 40,
        },
        marker: [
            { facilityType: "chargingStation", facilityLatitude: 35.82188390570568, facilityLongitude: 129.5086093143952 },
            { facilityType: "toilet", facilityLatitude: 35.82265974549292, facilityLongitude: 129.50856183285168 },
        ],
        region: [
            { lat: 35.822558912884766, lng: 129.5085365325475 },
            { lat: 35.82170434623778, lng: 129.50868663155106 },
            { lat: 35.82174855737287, lng: 129.50894246613996 },
            { lat: 35.822608780665966, lng: 129.5087372337414 },
        ],
    },
    {
        rentRegionDescription: "포항송도해수욕장",
        address: "경북 포항시 남구 희망대로 1142",
        latitude: 36.030852161442226,
        longitude: 129.38273558562318,
        description: {
            facility: "공중화장실",
            duration: 50,
        },
        marker: [{ facilityType: "toilet", facilityLatitude: 36.03137179220828, facilityLongitude: 129.38265695905238 }],
        region: [
            { lat: 36.03121884902037, lng: 129.38309881689813 },
            { lat: 36.031174691625004, lng: 129.38328050947013 },
            { lat: 36.031026448490735, lng: 129.38325940287643 },
            { lat: 36.030905455824765, lng: 129.383000633469 },
            { lat: 36.030862968222145, lng: 129.38287179175697 },
            { lat: 36.03034273787118, lng: 129.38207411374754 },
            { lat: 36.030610793705215, lng: 129.38196017408282 },
            { lat: 36.030772293764, lng: 129.38244755292237 },
        ],
    },
    {
        rentRegionDescription: "칠포해수욕장",
        address: "경북 포항시 북구 흥해읍 칠포리 197-32",
        latitude: 36.13176346970533,
        longitude: 129.39935332638296,
        description: {
            facility: "공중화장실",
            duration: 50,
            warning: "모래사장 주의",
        },
        marker: [
            { facilityType: "toilet", facilityLatitude: 36.131658750134726, facilityLongitude: 129.39963335126012 },
            { facilityType: "toilet", facilityLatitude: 36.132982606207335, facilityLongitude: 129.3974134778505 },
        ],
        region: [
            { lat: 36.13286607925306, lng: 129.398170734818 },
            { lat: 36.1300236430313, lng: 129.40131056322986 },
            { lat: 36.13003665455881, lng: 129.40111104805533 },
            { lat: 36.13279121104984, lng: 129.39808515607683 },
        ],
    },
    {
        rentRegionDescription: "봉길대왕암해변",
        address: "경북 경주시 문무대왕면 봉길해안길 47",
        latitude: 35.74095163198689,
        longitude: 129.48325318941005,
        description: {
            facility: "공중화장실, 샤워장",
            duration: 45,
        },
        marker: [
            { facilityType: "toilet", facilityLatitude: 35.739744661807016, facilityLongitude: 129.483453292338 },
            { facilityType: "toilet", facilityLatitude: 35.74169892950299, facilityLongitude: 129.48306090243184 },
        ],
        region: [
            { lat: 35.74158990926447, lng: 129.48310448465992 },
            { lat: 35.74154055420901, lng: 129.48331293260043 },
            { lat: 35.74031130153727, lng: 129.483501291649 },
            { lat: 35.74028548933199, lng: 129.4832242025017 },
            { lat: 35.74111764286258, lng: 129.48306770772882 },
        ],
    },
    {
        rentRegionDescription: "전촌솔밭해변",
        address: "경북 경주시 동해안로 2062-14",
        latitude: 35.79017492618732,
        longitude: 129.49241758956032,
        description: {
            duration: 35,
            warning: "모래사장 주의",
        },
        marker: [],
        region: [
            { lat: 35.79068777497696, lng: 129.4925607853271 },
            { lat: 35.790577678617744, lng: 129.49276469084896 },
            { lat: 35.78976522321531, lng: 129.49229697033616 },
            { lat: 35.78983705128987, lng: 129.4920918706762 },
        ],
    },
    {
        rentRegionDescription: "바다회식당",
        address: "경북 경주시 감포읍 동해안로 2002-3",
        latitude: 35.7860992,
        longitude: 129.490904,
        description: {
            duration: 40,
            option: "청수 보급 가능(무료)",
        },
        marker: [],
    },
    {
        rentRegionDescription: "지중해(오션뷰 브런치 카페)",
        address: "경북 경주시 감포읍 동해안로 1836-6",
        latitude: 35.77192223194027,
        longitude: 129.49603772446667,
        description: {
            facility: "완속 충전기",
            duration: 40,
            warning: "레스토랑 이용시 주차, 화장실 이용 가능(영업시간 외 이용 불가)",
        },
        marker: [],
    },
];

function TravelSpot() {
    return (
        <div style={{ minHeight: "calc(100vh - 70px)" }}>
            <div className="header-container model">
                <div className="container" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2),rgba(0, 0, 0, 0.2)),url(${Banner})` }}>
                    <div className="row justify-content-center">
                        <div className="fadeIn text-center mb-5">
                            <h5 className="text-center text-light px-3 mb-4">추천 여행지</h5>
                            <h1 className="text-light">TRAVEL SPOT</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="model-tabs title model-body" style={{ backgroundColor: "white" }}>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 22, offset: 1 }}
                    lg={{ span: 18, offset: 3 }}
                    xl={{ span: 16, offset: 4 }}
                    xxl={{ span: 14, offset: 5 }}
                    className="fadeInUp p-4"
                >
                    <Row gutter={32}>
                        {allPlaces.map((place, index) => {
                            return (
                                <Col sm={{ span: 24 }} lg={{ span: 8 }} key={index} className={`mb-5 w-100`}>
                                    <div className="media-card" style={{ padding: 12 }}>
                                        <RentPlaceMap isGuide={true} rentRegion={place} />
                                        <h6 className="mt-3 mb-2">
                                            <a className="text-dark">{place?.rentRegionDescription}</a>
                                        </h6>
                                        <Space direction="vertical" size={4}>
                                            {place?.description?.facility && (
                                                <Space size={8} align="baseline">
                                                    <img width={14} src={iconFacility} />
                                                    <p className="news-text">주변시설 : {place.description.facility} 보유</p>
                                                </Space>
                                            )}
                                            {place?.description?.duration && (
                                                <Space size={8} align="baseline">
                                                    <img width={14} src={iconDuration} />
                                                    <p className="news-text">소요시간 : 차고지에서 약 {place.description.duration}분 소요</p>
                                                </Space>
                                            )}
                                            {place?.description?.option && (
                                                <Space size={8} align="baseline">
                                                    <img width={14} src={iconOption} />
                                                    <p className="news-text">기타 : {place.description.option}</p>
                                                </Space>
                                            )}
                                            {place?.description?.warning && <p className="news-text text-note">※{place.description.warning}</p>}
                                        </Space>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </Col>
            </div>
        </div>
    );
}

export default TravelSpot;
