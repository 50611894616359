import * as Utils from "../utils/utils";
import * as Common from "../commons/common";

const getAuthUrl = () => {
    const deploy = Utils.checkDeploy();

    if (deploy === Common.Deploy.DEV_INTERNAL) {
        return process.env.REACT_APP_DEV_INTERNAL_AUTH_URI;
    } else if (deploy === Common.Deploy.DEV_EXTERNAL) {
        return process.env.REACT_APP_DEV_EXTERNAL_AUTH_URI;
    } else if (deploy === Common.Deploy.PROD) {
        return process.env.REACT_APP_PROD_AUTH_URI;
    } else return process.env.REACT_APP_DEV_EXTERNAL_AUTH_URI;
};

const getApiUrl = () => {
    const deploy = Utils.checkDeploy();

    if (Utils.checkDomain() === Common.Domain.RUTA40) {
        if (deploy === Common.Deploy.DEV_INTERNAL) {
            return process.env.REACT_APP_DEV_INTERNAL_API_URI;
        } else if (deploy === Common.Deploy.DEV_EXTERNAL) {
            return process.env.REACT_APP_DEV_EXTERNAL_API_URI;
        } else if (deploy === Common.Deploy.PROD) {
            return process.env.REACT_APP_PROD_API_URI;
        } else return process.env.REACT_APP_DEV_EXTERNAL_API_URI;
    } else {
        //rent
        if (deploy === Common.Deploy.DEV_INTERNAL) {
            return process.env.REACT_APP_DEV_INTERNAL_RENT_URI;
        } else if (deploy === Common.Deploy.DEV_EXTERNAL) {
            return process.env.REACT_APP_DEV_EXTERNAL_RENT_URI;
        } else if (deploy === Common.Deploy.PROD) {
            return process.env.REACT_APP_PROD_RENT_URI;
        } else return process.env.REACT_APP_DEV_EXTERNAL_RENT_URI;
    }
};

const getAppUrl = () => {
    const deploy = Utils.checkDeploy();

    if (deploy === Common.Deploy.DEV_INTERNAL) {
        return process.env.REACT_APP_DEV_INTERNAL_APP_URI;
    } else if (deploy === Common.Deploy.DEV_EXTERNAL) {
        return process.env.REACT_APP_DEV_EXTERNAL_APP_URI;
    } else if (deploy === Common.Deploy.PROD) {
        return process.env.REACT_APP_PROD_APP_URI;
    } else return process.env.REACT_APP_DEV_EXTERNAL_APP_URI;
};

const DEFAULT_AUTH_URL = getAuthUrl() + "v1/";
const DEFAULT_API_URL = getApiUrl() + "v1/";
const DEFAULT_APP_URL = getAppUrl() + "v1/";

/*auth*/

// 1. 인증
export const LOGIN_URL = DEFAULT_AUTH_URL + "login";
export const SIGNUP_URL = DEFAULT_AUTH_URL + "signup";
export const TIME_URL = DEFAULT_AUTH_URL + "time";

// 2. 회원 관리
export const FIND_EMAIL_URL = DEFAULT_AUTH_URL + "find_email";
export const MEMBER_INFO_URL = DEFAULT_AUTH_URL + "member";
export const MEMBER_WITHDRAW_URL = DEFAULT_AUTH_URL + "member/withdraw";
export const RESET_PASSWORD_URL = DEFAULT_AUTH_URL + "reset_password";

// 7. 운전 면허 관리
export const USER_URL = DEFAULT_AUTH_URL + "user/";
export const DRIVER_LICENSE_ADD_URL = USER_URL + "driver-license";
export const DRIVER_LICENSE_URL = DRIVER_LICENSE_ADD_URL + "/";
export const IMAGE_URL = "/image";

// 8. 이벤트/상품 관리
export const EVENT_ADD_URL = DEFAULT_AUTH_URL + "event";
export const EVENT_URL = EVENT_ADD_URL + "/";
export const EVENTS_URL = DEFAULT_AUTH_URL + "events";

// 9. 이벤트 진행
export const EVENT_JOIN_URL = "/user/join";
export const EVENT_RECEIVED_PRIZE_URL = "/user/receive";
export const EVENT_USERS_URL = "/users";
export const EVENT_USER_CHECK_URL = "/user/check";

export const BOARD_URL = DEFAULT_AUTH_URL + "board";

/*order*/

// API 1. 차량 분류 관리
export const CAR_CATEGORY_URL = DEFAULT_API_URL + "car/category";
export const CAR_CATEGORY_LIST_URL = DEFAULT_API_URL + "car/categories";

// API 2. 차량 모델 관리
export const CAR_MODEL_URL = DEFAULT_API_URL + "car/model";
export const CAR_MODEL_LIST_URL = DEFAULT_API_URL + "car/models";

// API 3. 차량 타입 관리
export const CAR_TYPE_URL = DEFAULT_API_URL + "car/type";
export const CAR_TYPE_LIST_URL = DEFAULT_API_URL + "car/types";

// API 4. 주문 색상 관리
export const COLOR_URL = DEFAULT_API_URL + "order/color";
export const COLOR_LIST_URL = DEFAULT_API_URL + "order/colors";

// API 5. 주문 옵션 관리
export const OPTION_URL = DEFAULT_API_URL + "order/option";
export const OPTION_LIST_URL = DEFAULT_API_URL + "order/options";

export const OPTION_TYPE_LIST_URL = OPTION_URL + "/types";

// API 6. 주문 관리
export const ORDER_URL = DEFAULT_API_URL + "order";
export const ORDER_CANCEL_URL = DEFAULT_API_URL + "order/cancel";
export const ORDER_LIST_URL = DEFAULT_API_URL + "orders";
export const ORDER_MYORDER_URL = DEFAULT_API_URL + "orders/mine";
export const ORDER_PAYMENT_URL = DEFAULT_API_URL + "order/payment";

export const PAYMENT_PREPARE_URL = DEFAULT_API_URL + "payment/prepare";
export const PAYMENT_COMPLETE_URL = DEFAULT_API_URL + "payment/complete";
export const PAYMENT_CANCEL_URL = DEFAULT_API_URL + "payment/cancel";

export const CONVENIENCE_URL = DEFAULT_API_URL + "convenience";
export const CONVENIENCE_LIST_URL = DEFAULT_API_URL + "conveniences";

// API 7. 방문자 관리
export const VISITOR_URL = DEFAULT_API_URL + "visitor";

// API 8. 광역시/도 관리
export const SIDOS_URL = DEFAULT_API_URL + "siDos";

export const SUBSIDIES_URL = DEFAULT_API_URL + "subsidies";

export const MY_COUPON_URL = DEFAULT_AUTH_URL + "user/coupons/mine";

export const MEDIAS_URL = DEFAULT_API_URL + "medias";

export const MANUALS_URL = DEFAULT_API_URL + "manuals";
export const DOWNLOAD_MANUAL_URL = DEFAULT_API_URL + "manual/download";

export const CATALOGS_URL = DEFAULT_API_URL + "catalogs";
export const DOWNLOAD_CATALOG_URL = DEFAULT_API_URL + "catalog/download";

export const FAQS_URL = DEFAULT_API_URL + "faqs";
export const INFORMATION_TITLES_URL = DEFAULT_API_URL + "information/titles";
export const INFORMATION_SUBTITLES_URL = DEFAULT_API_URL + "information/subtitles";

// API 9. 공지사항 관리
export const NOTICE_ADD_URL = DEFAULT_API_URL + "notice";
export const NOTICE_URL = NOTICE_ADD_URL + "/";
export const DOWNLOAD_NOTICE_URL = NOTICE_URL + "download/";
export const NOTICES_URL = NOTICE_ADD_URL + "s";

/*rent*/
const DEFAULT_RENT_API_URL = getApiUrl() + "v1/rent/";
const RENT_API_URL =
    (Utils.checkDeploy() === Common.Deploy.DEV_INTERNAL
        ? process.env.REACT_APP_DEV_INTERNAL_RENT_URI
        : Utils.checkDeploy() === Common.Deploy.DEV_EXTERNAL
        ? process.env.REACT_APP_DEV_EXTERNAL_RENT_URI
        : Utils.checkDeploy() === Common.Deploy.PROD
        ? process.env.REACT_APP_PROD_RENT_URI
        : process.env.REACT_APP_DEV_EXTERNAL_RENT_URI) + "v1/rent/";

//1. 렌트 지역 관리
export const RENT_REGION_ADD_URL = DEFAULT_RENT_API_URL + "region";
export const RENT_REGION_URL = RENT_REGION_ADD_URL + "/";
export const RENT_REGIONS_URL = DEFAULT_RENT_API_URL + "regions";
export const RENT_REGIONS_CAR_URL = RENT_REGIONS_URL + "/car";

//렌트 차량 관리
export const RENT_CAR_ADD_URL = DEFAULT_RENT_API_URL + "car";
export const RENT_CAR_URL = RENT_CAR_ADD_URL + "/";
export const RENT_CARS_URL = DEFAULT_RENT_API_URL + "cars";

//3. 렌트 제약사항 관리
export const RENT_RESTRICTION_ADD_URL = DEFAULT_RENT_API_URL + "restriction";
export const RENT_RESTRICTION_URL = RENT_RESTRICTION_ADD_URL + "/";
export const RENT_RESTRICTIONS_URL = DEFAULT_RENT_API_URL + "restrictions";

//4. 렌트 옵션 관리
export const RENT_OPTION_ADD_URL = DEFAULT_RENT_API_URL + "option";
export const RENT_OPTION_URL = RENT_OPTION_ADD_URL + "/";
export const RENT_OPTIONS_URL = DEFAULT_RENT_API_URL + "options";

//5. 렌트 관리
export const RENT_ADD_URL = getApiUrl() + "v1/rent";
export const RENT_URL = DEFAULT_RENT_API_URL;
export const RENTS_URL = getApiUrl() + "v1/rents";
export const RENT_CANCEL_URL = "/cancel";
export const RENT_MINE_URL = RENTS_URL + "/mine";

//6. 렌트 추가시간 관리
export const RENT_EXTRA_TIME_ADD_URL = DEFAULT_RENT_API_URL + "extraTime";
export const RENT_EXTRA_TIME_URL = RENT_EXTRA_TIME_ADD_URL + "/";
export const RENT_EXTRA_TIMES_URL = DEFAULT_RENT_API_URL + "extraTimes";

//7. 유료 대여 품목 관리
export const RENT_PAID_ITEM_ADD_URL = DEFAULT_RENT_API_URL + "paid-item";
export const RENT_PAID_ITEM_URL = RENT_PAID_ITEM_ADD_URL + "/";
export const RENT_PAID_ITEMS_URL = DEFAULT_RENT_API_URL + "paid-items";

//8. 렌트 보험 관리
export const RENT_INSURANCE_ADD_URL = DEFAULT_RENT_API_URL + "insurance";
export const RENT_INSURANCE_URL = RENT_INSURANCE_ADD_URL + "/";
export const RENT_INSURANCES_URL = DEFAULT_RENT_API_URL + "insurances";

//9. 렌트 결제 관리
export const RENT_PAYMENT_ADD_URL = DEFAULT_RENT_API_URL + "payment";
export const RENT_PAYMENT_URL = RENT_PAYMENT_ADD_URL + "/";
export const RENT_PAYMENT_CANCEL_URL = RENT_PAYMENT_URL + "cancel/";
export const RENT_PAYMENT_COMPLETE_URL = RENT_PAYMENT_URL + "complete";
export const RENT_PAYMENT_PREPARE_URL = RENT_PAYMENT_URL + "prepare/";

//10. 렌트 FAQ 관리
export const RENT_FAQ_ADD_URL = DEFAULT_RENT_API_URL + "faq";
export const RENT_FAQ_URL = RENT_FAQ_ADD_URL + "/";
export const RENT_FAQS_URL = DEFAULT_RENT_API_URL + "faqs";

//11. 렌트 주요 안내 주제 관리
export const RENT_INFO_URL = DEFAULT_RENT_API_URL + "info/";
export const RENT_INFO_TITLE_ADD_URL = RENT_INFO_URL + "title";
export const RENT_INFO_TITLE_URL = RENT_INFO_TITLE_ADD_URL + "/";
export const RENT_INFO_TITLES_URL = RENT_INFO_URL + "titles";

//12. 렌트 주요 안내 부제 관리
export const RENT_INFO_SUBTITLE_ADD_URL = RENT_INFO_URL + "subtitle";
export const RENT_INFO_SUBTITLE_URL = RENT_INFO_SUBTITLE_ADD_URL + "/";
export const RENT_INFO_SUBTITLES_URL = RENT_INFO_URL + "subtitles";

export const RENT_IMAGE_ADD_START_URL = "/start/images";
export const RENT_IMAGE_ADD_END_URL = "/end/images";
export const RENT_IMAGE_URL = RENT_URL + "image/";

export const RENT_VISITOR_URL = DEFAULT_RENT_API_URL + "visitor";

//15. 렌트 매뉴얼 관리
export const RENT_MANUAL_ADD_URL = DEFAULT_RENT_API_URL + "manual";
export const RENT_MANUAL_URL = RENT_MANUAL_ADD_URL + "/";
export const RENT_MANUAL_DOWNLOAD_URL = RENT_MANUAL_URL + "download/";
export const RENT_MANUALS_URL = RENT_MANUAL_ADD_URL + "s";

//16. 렌트 동영상 매뉴얼 관리
export const RENT_MANUAL_VIDEO_ADD_URL = DEFAULT_RENT_API_URL + "manual/video";
export const RENT_MANUAL_VIDEO_URL = RENT_MANUAL_VIDEO_ADD_URL + "/";
export const RENT_MANUAL_VIDEOS_URL = RENT_MANUAL_VIDEO_ADD_URL + "s";

//21. 렌트 공지사항 관리
export const RENT_NOTICE_ADD_URL = DEFAULT_RENT_API_URL + "notice";
export const RENT_NOTICE_URL = RENT_NOTICE_ADD_URL + "/";
export const RENT_NOTICE_DOWNLOAD_URL = RENT_NOTICE_URL + "download/";
export const RENT_NOTICES_URL = RENT_NOTICE_ADD_URL + "s";

//25. 렌트 기간 제한 관리
export const RENT_PERIOD_LIMIT_URL = DEFAULT_RENT_API_URL + "period-limit";
export const RENT_PERIOD_LIMITS_URL = RENT_PERIOD_LIMIT_URL + "s";

//26. B2B 관리
export const RENT_B2B_ADD_URL = RENT_API_URL + "b2b";
export const RENT_B2B_URL = RENT_B2B_ADD_URL + "/";
export const RENT_B2B_LIST_URL = RENT_API_URL + "s";

//27. 성수기 관리
export const RENT_PEAK_SEASON_URL = DEFAULT_RENT_API_URL + "peak-season";
export const RENT_PEAK_SEASONS_URL = RENT_PEAK_SEASON_URL + "s";

//99. 공휴일 정보
export const RENT_HOLIDAYS_URL = DEFAULT_API_URL + "holidays";

// ===========================================================

export const CAR_OWNERS = DEFAULT_APP_URL + "car/owner";
export const OWNERS_MINE_URL = DEFAULT_APP_URL + "car/owners/mine";
export const CAR_SHARER_URL = DEFAULT_APP_URL + "car/sharer";
export const SHARER_MINE_URL = DEFAULT_APP_URL + "car/sharers/mine";

export const DOOR_CAR_URL = DEFAULT_APP_URL + "car/";
export const DOOR_CAR_CLOSE_URL = "/door/close";
export const DOOR_CAR_OPEN_URL = "/door/open";
export const SWING_DOOR_CAR_CLOSE_URL = "/door/swing/close";
export const SWING_DOOR_CAR_OPEN_URL = "/door/swing/open";
export const DOOR_STATUS_URL = DEFAULT_APP_URL + "cars/door/status";

export const FIND_EV_STATION_URL = DEFAULT_APP_URL + "openapi/evcs";
